<template>
    <button :class="[
        'ufo-fab',
        !!size ? `ufo-fab--${size}` : null,
        {'ufo-fab--alert' : alert},
        {'ufo-fab--has-label' : hasTextLabel},
        {'selected': selected},
        variant,
        customClass
        ]"
            @click="handleClick"
            @mouseenter="handleMouseEnter()"
            @mouseleave="handleMouseLeave()"
            :disabled="disabled"
    >
        <Icon v-if="!!icon" alt="FAB icon" :icon-name="icon"/>
        <slot></slot>
        <!-- Slots for review mode, not used in the editor -->
        <slot name="reaction"></slot>
        <slot name="comment"></slot>
        <span v-if="!!tooltip" :class="['tooltip', tooltipPosition, {'spacing': customClass === 'spacing'}]">
            {{ tooltip }}
        </span>
        <div v-if="hasFixedTooltipSlot" class="fixed-tooltip">
            <slot name="fixedTooltip"></slot>
        </div>
    </button>
</template>

<script>
    import Icon from "../../common/Icon.vue";

    export default {
        name: 'fab',
        components: {Icon},
        inheritAttrs: false,
        props: {
            icon: {
                type: String,
                default: null
            },
            variant: {
                type: String,
                default: 'regular',     // regular or subtle
            },
            tooltip: {
                type: String,
                default: null
            },
            tooltipPosition: {
                type: String,
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: null
            },
            selected: {
                type: Boolean,
                default: false
            },
            alert: {
                type: Boolean,
                default: false
            },
            customClass: {
                type: String,
                default: null
            }
        },
        computed: {
            hasTextLabel () {
                return !!this.$slots.default
            },
            hasFixedTooltipSlot() {
                return !!this.$slots.fixedTooltip
            },

        },
        methods: {
            handleClick(event) {
                this.$emit('click', event);
            },
            handleMouseEnter() {
                this.$emit('mouseenter');
            },
            handleMouseLeave() {
                this.$emit('mouseleave');
            }
        }
    }
</script>

<style lang="scss" global>
    .ufo-fab {
        width: 48px;
        height: 48px;
        padding: 0;
        border-radius: 4px;
        border: none;
        background-color: var(--fabBackgroundColor);
        cursor: pointer;
        position: relative;
        color: var(--fabColor);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        // Use this class only to force dark mode on a FAB in sidePanel or modal
        // the editor changes it's FAB colors based on background color (see darkModeColors() in _PageFrame.vue)
        &.dark {
            --fabColor: var(--fabDarkColor);
            --fabBackgroundColor: var(--fabDarkBackgroundColor);
            --fabHoverBackgroundColor: var(--fabDarkHoverBackgroundColor);
            --fabDisabledColor: var(--fabDarkDisabledColor);
            --fabSelectedBackgroundColor: var(--fabDarkSelectedBackgroundColor);

            --fabSubtleColor: var(--fabDarkSubtleColor);
            --fabSubtleBackgroundColor: var(--fabDarkSubtleBackgroundColor);
            --fabSubtleHoverBackgroundColor: var(--fabDarkSubtleHoverBackgroundColor);
        }

        svg {
          width: 24px;
          height: 24px;
        }

        &--small {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

          svg {
              width: 20px;
              height: 20px;
          }
        }

        &--tiny {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--fabAlertHoverColor);
            background-color: var(--fabAlertTinyBackgroundColor);

            svg {
                width: 18px;
                height: 18px;
            }
        }

        &[disabled] {
            color: var(--fabDisabledColor) !important;
            cursor: not-allowed;
        }

        &--has-label {
            width: max-content;
            color: var(--fabColor);
            font: var(--editor-ui-b2-regular);
            letter-spacing: 0.12px;
            padding: 8px 12px;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            svg {
                margin-right: 8px;
            }
        }

        &:focus,
        &:active {
            outline: none;
        }

        &:hover {
            background-color: var(--fabHoverBackgroundColor);
            .tooltip {
                background-color: var(--fabHoverBackgroundColor);
                visibility: visible;
                opacity: 1;
            }
            &[disabled] {
                background-color: var(--fabBackgroundColor);
            }
        }

        &.ufo-fab--alert {
            color: var(--fabAlertColor);
            z-index: 2;
            &:hover {
                background-color: var(--fabAlertHoverBackgroundColor);
                color: var(--fabAlertHoverColor);
            }
            &.ufo-fab--tiny {
                color: var(--fabAlertHoverColor);
            }
            .tooltip {
                background-color: var(--fabAlertHoverBackgroundColor);
                color: var(--fabAlertHoverColor);
            }
        }

        &.subtle {
            color: var(--fabSubtleColor);
            background: var(--fabSubtleBackgroundColor);
            &:hover {
                background: var(--fabSubtleHoverBackgroundColor);
            }
        }

        &.selected {
            background-color: var(--fabSelectedBackgroundColor);
        }

        .tooltip {
            visibility: hidden;
            background-color: var(--fabHoverBackgroundColor);
            color: var(--fabColor);
            font: var(--editor-ui-b3-semibold);
            letter-spacing: 0.12px;
            text-align: center;
            padding: 8px 12px;
            border-radius: 4px;
            position: absolute;
            z-index: 6;
            opacity: 0;
            transition: opacity 200ms;
            top: 50%;
            right: 120%;
            transform: translateY(-50%);
            max-width: 220px;
            width: max-content;
            line-break: auto;

            &.top {
                bottom: 120%;
                top: auto;
                right: auto;
                left: 50%;
                transform: translateX(-50%);
                &.spacing {
                    left: 131%;
                }
            }
            &.bottom {
                top: 120%;
                right: auto;
                left: 50%;
                transform: translateX(-50%);
                &.spacing {
                    left: 131%;
                }
            }
            &.right {
                top: 50%;
                left: 120%;
                transform: translateY(-50%);
                display: inline-table;
            }

        }
    }
</style>