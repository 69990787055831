<template>
    <div :id="'section-' + section.index"
         v-if="(section.showText !== null || section.showMedia !== null) && ((section.showText && section.showText === true) && (section.showMedia && section.showMedia === true))"
         :class="['ufo-sec',
          'ufo-sec--textandmedia',
          'flipped-' + section.flipped,
          section.media.frame && section.media.frame !== 'no-mockup' ? 'mockup-on' : '' ]">
        <ToggleGrid />
        <div class="grid">
            <div :class="['col-media ',
                        section.flipped ? 'col-order-2' : '',
                        'media-' + section.media.type.toLowerCase(),
                        mediaDisplayMode,
                        ...frameClasses,
                        section.media.type === 'Video' ||
                        section.media.type === 'Prototype' ||
                        section.media.type === 'Media' ? 'embed-container' : '']"
                 :style="{'padding-top' : section.style&& section.style.paddingTop ? 0 : null, 'paddingBottom': section.style && section.style.paddingBottom ? 0 : null }"
            >
                <div class="media-container">
                    <GetMediaContent :section="section" :default-caption="projectTitle" :placeholder-image="'empty-image-and-text-image.jpg'" />
                    <GetMediaCaption :caption="section.media.caption" />
                </div>
            </div>
            <div :class="['col-text',
                        section.flipped ? 'col-order-1' : '',
                        'media-' + section.media.type.toLowerCase(),
                        mediaDisplayMode,
                        ...frameClasses]"
            >
                <GetSectionText :section-text="section.text"/>
                <CtaButton :form-submit-button="false" :section="section" :alignment="textAlign"/>
            </div>
        </div>
    </div>
    <div v-else-if="(section.showText !== null || section.showMedia !== null) && section.showText === false && section.showMedia === true"
         :id="'section-' + section.index"
         :class="['ufo-sec',
         'ufo-sec--media',
         'image',
         section.media.frame && section.media.frame !== 'no-mockup' ? 'mockup-on' : null]">
        <div class="grid">
            <div :class="['media-container',
                        'display-mode--' + (section.media.displayMode ? section.media.displayMode : 'default'),
                         section.media.type === 'Video' || section.media.type === 'Prototype' || section.media.type === 'Media' ? 'embed-container' : '']"
                 :style="{'padding-top' : section.style&& section.style.paddingTop ? 0 : null, 'paddingBottom': section.style && section.style.paddingBottom ? 0 : null }"
            >
                <GetMediaContent  :section="section" :default-caption="projectTitle" :placeholder-image="'empty-big-image.jpg'" />
                <GetMediaCaption :caption="section.media.caption" />
            </div>
        </div>
    </div>
    <TextSection v-else-if="(section.showText !== null || section.showMedia !== null) && section.showText === true && section.showMedia  === false" :section="section"/>
    <div v-else :id="'section-'+ section.index"
         :class="['ufo-sec', 'ufo-sec--textandmedia', 'flipped-' + section.flipped, section.media.frame && section.media.frame !== 'no-mockup' ? 'mockup-on' : '']">
        <div class="grid">
            <div :class="['col-media',
                            section.flipped ? 'col-order-2' : '',
                            'media-' + section.media.type.toLowerCase(),
                            mediaDisplayMode,
                            ...frameClasses,
                            section.media.type === 'Video' || section.media.type === 'Prototype' || section.media.type === 'Media'? 'embed-container' : '']"
                 :style="{'padding-top' : section.style&& section.style.paddingTop ? 0 : null, 'paddingBottom': section.style && section.style.paddingBottom ? 0 : null }"
            >
                <div class="media-container">
                    <GetMediaContent :section="section" :default-caption="projectTitle" :placeholder-image="'empty-image-and-text-image.jpg'" />
                    <GetMediaCaption :caption="section.media.caption" />
                </div>
            </div>
            <div :class="['col-text',
                            section.flipped ? 'col-order-1' : '',
                            'media-' + section.media.type.toLowerCase(),
                            mediaDisplayMode,
                            ...frameClasses]"
            >
                <GetSectionText :section-text="section.text"/>
                <CtaButton :form-submit-button="false" :section="section" :alignment="textAlign"/>
            </div>
        </div>
    </div>
</template>

<script>
    import GetMediaContent from "./_helper/GetMediaContent.vue";
    import GetMediaCaption from "./_helper/GetMediaCaption.vue";
    import GetSectionText from "./_helper/GetSectionText.vue";
    import TextSection from "./Text.vue";
    import CtaButton from "./_helper/CtaButton.vue";
    import ToggleGrid from "../../../common/components/ToggleGrid.vue";

    export default {
        name: "TextAndMediaSections",
        components: { GetMediaCaption, GetMediaContent, TextSection, CtaButton, GetSectionText, ToggleGrid },
        props:['section', 'projectTitle'],
        computed: {
            mediaDisplayMode() {
                return this.section.media.displayMode ? "media-" + this.section.media.displayMode : "media-default"
            },
            textAlign() {
                return this.section.text.style && this.section.text.style.textAlign ? 'push-to-' + this.section.text.style.textAlign : ""
            },
            frameClasses() {
                let classes = [];
                if(this.section.media && this.section.media.frame) {
                    classes = [this.section.media.frame.includes('mobile') ? 'frame-mobile' : '',
                        this.section.media.frame.includes('tablet') ?'frame-tablet' : '',
                        this.section.media.frame.includes('desktop') ? 'frame-desktop' : '',
                        this.section.media.frame.includes('landscape') ? 'landscape' : '']
                }
                return classes
            }
        }
    }
</script>