<template>
    <div :id="'section-' +section.index"
         class="ufo-sec ufo-sec--header"
        >
        <ToggleGrid />
        <SectionBackgroundImage :section="section" />
        <div class="grid">
            <div class="text-container">
                <AnimationWrapper
                    :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                    :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                >
                    <template #animationContent>
                        <div class="ufo--basic-text-area">
                            <div :class="section.title.style && section.title.style.textStyle ? section.title.style.textStyle : 'default--h2'"
                                 :style="{color: section.title.style && !section.title.style.textStyle && section.title.style.fontColor ? section.title.style.fontColor : null}"
                                 v-html="fixText('title')"
                            >
                            </div>
                        </div>
                    </template>
                </AnimationWrapper>
            </div>
        </div>
    </div>
</template>

<script>

    import ToggleGrid from "../../../common/components/ToggleGrid.vue";
    import AnimationWrapper from "../../../common/components/AnimationWrapper.vue";
    import SectionBackgroundImage from "../../../sections/common/SectionBackgroundImage.vue";

    export default {
        name: "HeaderSection",
        components: {SectionBackgroundImage, AnimationWrapper, ToggleGrid },
        props:['section'],
        methods: {
            fixText(data) {
                return this.section[data].text.replaceAll('\n', '<br/>')
            }
        }
    }
</script>