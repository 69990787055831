<template>
    <div v-if="user.title" class="profile-position">{{ user.title }}</div>
</template>

<script>
    export default {
        name: "UserJobTitle",
        computed: {
            user() {
                return this.$store.state.view.user
            }
        }
    }
</script>