import axios from 'axios';
import ApiLogger from './ApiLogger.js';

function handleError(responseObject, failureFunction) {
    // Currently, the API provide 403 response status if the request time exceeds the session timeout [application.properties: server.session.timeout]
    // We have to disable the reload logic if the user is a reviewer, as we have guest permissions there, which is not in keycloak, so it would cause a forever reload cycle
    console.log('responseObject', responseObject, window.location);
    if(responseObject.response && !window.location.pathname.includes('/review') && responseObject.response.status === 403) {
        location.reload();
    }
    failureFunction(responseObject);
}

export default {

    getWithParams: function (url, params) {
        return new Promise((success, failure) => {
            axios.get(url, { params: params })
                .then(function (response) {
                    ApiLogger.success("GET", url, response.data);
                    success(response);
                })
                .catch(function (response) {
                    ApiLogger.error("GET", url, response);
                    handleError(response, failure);
                });
        })
    },

    get: function (url, params) { return this.getWithParams(url, params) },

    post: function (url, body) {
        return new Promise((success, failure) => {
            axios.post(url, body)
                .then(function (response) {
                    ApiLogger.success("POST", url, response.data);
                    success(response);
                })
                .catch(function (response) {
                    ApiLogger.error("POST", url, response);
                    handleError(response, failure);
                });
        })
    },

    postMultipart: function (url, body) {
        return new Promise((success, failure) => {
            axios.post(url, body, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(function (response) {
                    ApiLogger.success("POST", url, response.data);
                    success(response);
                })
                .catch(function (response) {
                    ApiLogger.error("POST", url, response);
                    handleError(response, failure);
                });
        })
    },

    put: function (url, body) {
        return new Promise((success, failure) => {
            axios.put(url, body)
                .then(function (response) {
                    ApiLogger.success("PUT", url, response.data);
                    success(response);
                })
                .catch(function (response) {
                    ApiLogger.error("PUT", url, response);
                    handleError(response, failure);
                });
        })
    },

    delete: function (url, body) {
        return new Promise((success, failure) => {
            axios({method: 'delete', url: url, data: body})
            //axios.delete(url, {params: body})
                .then(function (response) {
                    ApiLogger.success("DELETE", url, response.data);
                    success(response);
                })
                .catch(function (response) {
                    ApiLogger.error("DELETE", url, response);
                    handleError(response, failure);
                });
        })
    },

    downloadPdf: function (url, body) {
        return new Promise((success, failure) => {
            axios.post(url, body, {responseType: 'arraybuffer'})
                .then(function (response) {
                    ApiLogger.success("POST", url, response.data);
                    success(response);
                })
                .catch(function (response) {
                    ApiLogger.error("POST", url, response);
                    handleError(response, failure);
                });
        });
    }
}
