<template>
    <div :id="'section-' + section.index" class="ufo-sec ufo-sec--projectgrid">
        <ToggleGrid />
        <div v-bind:key="section" v-for="section in filteredHomePageSections" class="grid">
            <div :class="'works_line_'+ portfolio.templateSettings.theme"></div>
        </div>

        <div class="grid ufo--user-projects">
            <a v-for="caseStudy in caseStudies" @click="(evt) => navigateToCaseStudy(evt, caseStudy)"
               :class="['project',
                      'title-is--' + (portfolio.templateSettings.projectTitle.visibility ? portfolio.templateSettings.projectTitle.visibility : 'none'),
                      'title-is--' + (portfolio.templateSettings.projectTitle.placement ? portfolio.templateSettings.projectTitle.placement : 'none'),
                      'title-is--' + (portfolio.templateSettings.projectTitle.color ? portfolio.templateSettings.projectTitle.color : 'default'),
                      'thumbnail-is--' + (portfolio.templateSettings.tileDisplayMode ? portfolio.templateSettings.tileDisplayMode.split('-')[0] : 'default'),
                      'thumbnail-is--' + (portfolio.templateSettings.tileDisplayMode ? portfolio.templateSettings.tileDisplayMode : 'default'),
                      'title-align--' + (portfolio.templateSettings.projectTitle.alignment.vertical ? portfolio.templateSettings.projectTitle.alignment.vertical : 'none'),
                      'title-justify--' + (portfolio.templateSettings.projectTitle.alignment.horizontal ? portfolio.templateSettings.projectTitle.alignment.horizontal : 'none'),
                      'layout-is--' + layout
                    ]"
            >
                <AnimationWrapper
                    :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                    :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                    :disable-animation="portfolio.templateSettings.projectTitle.placement === 'on-thumbnail' && portfolio.templateSettings.projectTitle.visibility === 'hover'"
                >
                    <template #animationContent>
                        <div class="project-name">
                            <div class="ufo--project-title-container">
                                <div class="project-title">{{projectName(caseStudy)}}</div>
                            </div>
                            <div class="ufo--project-title-container">
                                <div class="project-subtitle">{{projectSubtitle(caseStudy)}}</div>
                            </div>
                        </div>
                    </template>
                </AnimationWrapper>
                <AnimationWrapper
                    :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                    :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                >
                    <template #animationContent>
                        <ProjectThumbnail :case-study="caseStudy" />
                    </template>
                </AnimationWrapper>
            </a>
        </div>
        <EmptyProjectGrid v-if="(caseStudies.length < 1) && loggedInUserId && loggedInUserId === user._id && !isReview" />
    </div>
</template>

<script>
    import Cta from "../../../common/components/CtaButton.vue";
    import ToggleGrid from "../../../common/components/ToggleGrid.vue";
    import ProjectThumbnail from "../../../../scripts/sections/projectGrid/ProjectThumbnail.vue";
    import AnimationWrapper from "../../../common/components/AnimationWrapper.vue";
    import EmptyProjectGrid from "./_helper/EmptyProjectGrid.vue";

    export default {
        name: "ProjectGrid",
        components: {EmptyProjectGrid, AnimationWrapper, ProjectThumbnail, Cta , ToggleGrid },
        props: ['section'],
        computed: {
            portfolio() {
                return this.$store.state.view.portfolio
            },
            user() {
                return this.$store.state.view.user
            },
            caseStudies() {
                return this.$store.state.view.caseStudies
            },
            loggedInUserId() {
              return this.$store.state.view.loggedInUserId
            },
            filteredHomePageSections() {
                return this.portfolio.sections.filter(section => section.type !== "ProjectGrid" && section.index < section.index && !section.deletedAt)
            },
            layout() {
                return this.portfolio.templateSettings.thumbnailLayout ? this.portfolio.templateSettings.thumbnailLayout : this.portfolio.templateSettings.virtualThumbnailLayout
            },
            isReview() {
                return window.location.pathname.includes('/review/') || window.location.pathname.includes('/myreviews/')
            },
        },
        methods: {
            navigateToCaseStudy(evt, caseStudy) {
                evt.preventDefault()
                const caseStudyRoute = this.portfolio.ownDomainAddress && this.portfolio.ownDomainAddress === location.origin
                    ? `/p/${caseStudy.shortId}`
                    : `/p/${this.portfolio.shortId}/${caseStudy.shortId}`
                this.$router.push(caseStudyRoute)
            },
            projectName(caseStudy) {
                let headerTitle = caseStudy.sections[0];
                return caseStudy.name !== null ? caseStudy.name : headerTitle.title.text
            },
            projectSubtitle(caseStudy) {
                let headerSubtitle = caseStudy.sections[0];
                return caseStudy.subtitle !== null ? caseStudy.subtitle : headerSubtitle.title.text
            }
        }
    }
</script>