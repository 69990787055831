import Logger from 'js-logger';

export default {
    log: function (method, apiUrl, success, response) {
        Logger.debug(
            (success ? "SUCCESS" : "ERROR" )
            + " @ [" + method + ":" + apiUrl + "]"
            // + "\n\tResponse: " + JSON.stringify(response)
        );
    },
    success: function (method, apiUrl, response) {
        this.log(method, apiUrl, true, response);
    },
    error: function (method, apiUrl, response) {
        this.log(method, apiUrl, false, response);
    }
}
