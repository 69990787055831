<template>
    <div v-if="$store.state.displayToggleGrid" class="toggle-grid">
        <div class="toggle-grid-col"/>
        <div class="toggle-grid-col"/>
        <div class="toggle-grid-col"/>
        <div class="toggle-grid-col"/>
        <div class="toggle-grid-col toggle-grid-col-12"/>
        <div class="toggle-grid-col toggle-grid-col-12"/>
        <div class="toggle-grid-col toggle-grid-col-12"/>
        <div class="toggle-grid-col toggle-grid-col-12"/>
        <div class="toggle-grid-col toggle-grid-col-12"/>
        <div class="toggle-grid-col toggle-grid-col-12"/>
        <div class="toggle-grid-col toggle-grid-col-12"/>
        <div class="toggle-grid-col toggle-grid-col-12"/>
    </div>
</template>

<script>
    export default {
        name: 'toggle-grid',
        props: {
            paddingTop: {
                default: 0,
                type: Number
            },
        }
    }

</script>

<style lang="scss" scoped>
    .toggle-grid {
        min-height: 100%;
        height: 100%;
        width: 100%;
        position: absolute;
        pointer-events: none;
        opacity: 0.3;
        z-index: 102;
        left: 50%;
        right: 50%;
        transform: translate(-50%);
        display: grid;
        margin-top: calc(var(--section-padding-top) * -1);

        --gridGap: 24px;
        @media (min-width: 1024px) {
            --gridGap: 48px;
            padding-left: 126px;
            padding-right: 126px;
        }
        grid-gap: var(--gridGap);

        grid-template-columns: repeat(4, minmax(0, 1fr));
        @media (min-width: 640px) {
            grid-template-columns: repeat(12, minmax(0, 1fr));
        }

        padding-left: 24px;
        padding-right: 24px;
        @media (min-width: 1440px) {
            max-width: 1440px;
        }

        .toggle-grid-col {
            background-color: pink;
        }

        .toggle-grid-col-12 {
            display: none;
        }
        @media (min-width: 640px) {
            .toggle-grid-col-12 {
                display: block;
            }
        }
    }

    .ufo--page-hero,
    .ufo-sec--header-main {
        .toggle-grid {
            margin-top: 0;
        }
    }
</style>
