<template>
    <modal-frame v-if="show" :show="show" @close="close" :modal-size="'small'" disable-outside-click>
        <!-- State for simple new guest reviewers-->
        <div v-if="!continueReview && !$store.state.user && !followup && !authError" class="review-login-modal-container">
            <img v-if="user && user.image && user.image.url" :src="user.image.url"/>
            <UserImagePlaceholder v-else :name="userName" size="medium"/>
            <div class="header-container">
                <div class="header">{{ userName }} has invited you to review their portfolio</div>
                <div class="subheader">Let them know who the feedback is from.</div>
            </div>
            <div class="inputs-container">
                <input-field
                    dark-scheme
                    :label="'Name'"
                    :placeholder="'Name'"
                    :value="name"
                    v-model="name"
                >
                    <template #alert>
                        <span :key="'transferError'" v-if="errors.name" class="alert">Please fill out this field</span>
                    </template>
                </input-field>
                <input-field
                    dark-scheme
                    :label="'Job title'"
                    :placeholder="'Job title'"
                    :value="jobTitle"
                    v-model="jobTitle"
                >
                    <template #alert>
                        <span :key="'transferError'" v-if="errors.jobTitle" class="alert">Please fill out this field</span>
                    </template>
                </input-field>
                <input-field
                    dark-scheme
                    :label="'Email address'"
                    :placeholder="'Email address'"
                    :value="email"
                    v-model="email"
                    :type="'email'"
                >
                    <template #alert>
                        <span :key="'transferError'" v-if="errors.email" class="alert">Please fill out this field</span>
                    </template>
                </input-field>
            </div>
            <div class="button-container">
                <CtaButton type="primary" dark-scheme @click="checkUser">Start reviewing</CtaButton>
                <span>Returning to a previous session? <CtaButton @click="continueWithLogin" type="link" dark-scheme>Continue reviewing</CtaButton></span>
                <span>Do you have a UXfolio account? <CtaButton @click="loginWithUxfolioUser" type="link" dark-scheme>Log in</CtaButton></span>
            </div>
        </div>
        <!-- State for guest reviewers who want to continue a review -->
        <div v-if="continueReview && !loginError && !authError" class="review-login-modal-container">
            <div class="continue-review">
                <div class="header-row">
                    <div class="title-text">Continue Review</div>
                    <div class="subtitle-text">Provide the email address you used before to continue the review.</div>
                </div>
                <input-field
                    dark-scheme
                    :label="'Your email'"
                    :placeholder="'Your email'"
                    :value="email"
                    v-model="email"
                >
                    <template #alert>
                        <span :key="'transferError'" v-if="errors.email" class="alert">Please fill out this field</span>
                    </template>
                </input-field>
                <div class="button-container">
                    <CtaButton type="primary" dark-scheme @click="continueWithReview">Start reviewing</CtaButton>
                    <span>Do you have a UXfolio account? <CtaButton type="link" @click="loginWithUxfolioUser" dark-scheme>Log in</CtaButton></span>
                </div>
            </div>
        </div>
        <!-- State for uxfolio users when they first visit a review page without active review -->
        <div v-if="$store.state.user" class="review-login-modal-container">
            <img v-if="user && user.image && user.image.url" :src="user.image.url"/>
            <UserImagePlaceholder v-else :name="userName" size="medium"/>
            <div class="header-container">
                <div class="header">{{ userName }} has invited you to review their portfolio</div>
                <div class="subheader">You’re logged in to UXfolio as <span class="name">{{$store.state.user.name}}</span>.</div>
            </div>
            <div class="button-container">
                <CtaButton type="primary" dark-scheme @click="startReviewAsUxfolioUser">Start reviewing</CtaButton>
            </div>
        </div>
        <!-- State for followup results if we don't find anything in the cookies -->
        <div v-if="followup" class="review-login-modal-container">
            <img alt="new-message-picto" src="../../../../images/review/new-message.png" />
            <div class="header-container">
                <div class="header">You have a follow-up message</div>
                <div class="subheader">Provide your email to see and reply to {{ userName }}'s follow-up message.</div>
            </div>
            <input-field
                dark-scheme
                :label="'Email address'"
                :placeholder="'Email address'"
                :value="email"
                v-model="email"
                :type="'email'"
            >
                <template #alert>
                    <span :key="'transferError'" v-if="errors.email" class="alert">Please fill out this field</span>
                </template>
            </input-field>
            <div class="button-container">
                <CtaButton type="primary" dark-scheme @click="continueWithReview">Open my review</CtaButton>
                <span>Do you have a UXfolio account? <CtaButton @click="loginWithUxfolioUser" type="link" dark-scheme>Log in</CtaButton></span>
            </div>
        </div>
        <!-- Error state -->
        <div v-if="loginError" class="review-login-modal-container">
            <div class="continue-review">
                <div class="header-row">
                    <div class="title-text">Review not found</div>
                    <div class="subtitle-text">It looks like you haven’t reviewed this portfolio as “{{email}}”. Start a new review now!</div>
                </div>
                <div class="button-container row">
                    <CtaButton type="secondary" dark-scheme @click="loginError = false">Try another email</CtaButton>
                    <CtaButton type="primary" dark-scheme @click="loginError = false; continueReview = false">Start a new review</CtaButton>
                </div>
            </div>
        </div>
        <div v-if="authError" class="review-login-modal-container">
            <div class="continue-review">
                <div class="header-row">
                    <div class="title-text">E-mail already in use.</div>
                    <div class="subtitle-text">It looks like “{{email}}” is already registered to UXfolio. If this is your account, please continue by logging in.</div>
                </div>
                <div class="button-container row">
                    <CtaButton type="secondary" dark-scheme @click="authError = false">Try another email</CtaButton>
                    <CtaButton type="primary" dark-scheme @click="loginWithUxfolioUser">Log in with UXFolio</CtaButton>
                </div>
            </div>
        </div>
    </modal-frame>
</template>

<script>
    import InputField from "../../../common/components/Input.vue";
    import CtaButton from "../../../common/components/CtaButton.vue";
    import ApiHelper from "../../../api/ApiHelper.js";
    import ModalFrame from "../../../common/_ModalFrame.vue";
    import UserImagePlaceholder from "../../../common/components/UserImagePlaceholder.vue";
    import {event} from "vue-gtag";

    export default {
        name: "ReviewerLoginModal",
        props: ['show', 'close', 'getReview' ],
        components: {
            UserImagePlaceholder,
            ModalFrame,
            CtaButton,
            InputField
        },
        data() {
            return {
                name:null,
                jobTitle:null,
                email:null,
                continueReview:false,
                loginError:null,
                authError:false,
                followup:false,
                errors:{
                    name:false,
                    jobTitle:false,
                    email:false,
                }
            }
        },
        mounted() {
            this.followup = this.$route.query.followup;
        },
        methods: {
            async checkUser() {
                let errorsActive = this.checkErrors();
                if(errorsActive) {
                    return;
                }
                try {
                    let response = await ApiHelper.post("/api/review/reviewer", {name:this.name, jobTitle:this.jobTitle, email:this.email});
                    this.$store.state.reviewMode.review.reviewerDetails = response.data.reviewer;

                    //GA Event
                    event('Review with Guest account', {
                        'event_category': 'Review mode',
                        'event_label':  `Reviewer: ${this.email}, Portfolio: ${this.$store.state.view.portfolio._id}`
                    });
                    this.getReview();
                    //Add to cookie as well;
                    this.$cookies.set('reviewer', JSON.stringify(response.data.reviewer));
                    this.continueReview = false;
                    this.authError = false;
                    this.close();

                }
                catch(err) {
                    console.log('Error while getting reviewer user', err);
                    if(err.response.status === 409) {
                        this.authError = true;
                    }
                }
            },
            startReviewAsUxfolioUser() {
                this.getReview();
                this.close();
            },
            async continueWithReview() {
                if(!this.email) {
                    this.errors.email = true;
                    return;
                }
                else {
                    this.errors.email = false;
                }

                try {
                    let response = await ApiHelper.post("/api/review/reviewer/continue", {name:this.name, jobTitle:this.jobTitle, email:this.email});
                    this.$store.state.reviewMode.review.reviewerDetails = response.data.reviewer;
                    this.getReview();
                    //Add to cookie as well;
                    this.$cookies.set('reviewer', JSON.stringify(response.data.reviewer));
                    this.continueReview = false;
                    this.authError = false;
                    this.close();
                }
                catch(e) {
                    console.log('catch err', e)
                    if(e.response.status === 409) {
                        this.authError = true;
                    }else {
                        this.loginError = true;
                    }
                }
            },
            continueWithLogin() {
                this.continueReview = true;
            },
            reviewAsGuest() {
                this.$store.state.user = null;
            },
            loginWithUxfolioUser() {
                //GA Event
                event('Review log-in with UXFolo account', {
                    'event_category': 'Review mode',
                });

                const host = window.location.host;
                const path = "/review/login?host=" + host + "&path=" + window.location.pathname;
                const encodedUrl = `/loginredirect?host=${encodeURIComponent(host)}&redirectTo=${encodeURIComponent(path)}`;
                window.location = encodedUrl;
            },
            checkErrors() {
                let error = false;
                if(!this.continueReview) {
                    if(!this.name) {
                        this.errors.name = true;
                        error = true;
                    }
                    else {
                        this.errors.name = false;
                    }

                    if(!this.jobTitle) {
                        this.errors.jobTitle = true;
                        error = true;
                    }
                    else {
                        this.errors.jobTitle = false;
                    }
                }
                if(!this.email) {
                    this.errors.email = true;
                    error = true;
                }
                else {
                    this.errors.email = false;
                }
                return error;
            }
        },
        computed: {
            user() {
                return this.$store.state.view.user
            },
            userName() {
                return this.$store.state.view.user ? this.$store.state.view.user.name : 'John Doe'
            }
        }
    }
</script>

<style scoped lang="scss">
    .review-login-modal-container {
        display: flex;
        padding: 40px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
        background-color: var(--asphalt95);

        border-radius: 8px;
        border: 1px solid var(--asphalt90);
        box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.40), 0 32px 40px 0 rgba(0, 0, 0, 0.32);

        &.error {
            padding: 32px;
        }

        img {
            display: flex;
            width: 60px;
            height: 60px;
            justify-content: center;
            align-items: center;
            border-radius:30px;
        }

        .header-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;

            .header {
                text-align: center;
                font: var(--editor-ui-h2);
                color: var(--asphalt10);
            }

            .subheader {
                font: var(--editor-ui-b2-regular);
                color: var(--asphalt30);

                .name {
                    color: var(--asphalt10);
                    font: var(--editor-ui-b2-medium);
                }
            }
        }

        .inputs-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            width: 100%;
        }

        .button-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
            width: 100%;

            &.row {
                flex-direction: row;

                button {
                    width: 100%;
                }
            }

            .ufo-cta--primary {
                width: 100%;
            }

            span {
                font:var(--editor-ui-b2-regular);
                color: var(--asphalt40);
            }

        }

        .continue-review {
            display: flex;
            gap: 40px;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .header-row {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 16px;

                .title-text {
                    font: var(--editor-ui-h1);
                    color: var(--asphalt10);
                }

                .subtitle-text {
                    text-align: center;
                    font: var(--editor-ui-b1-regular);
                    color: var(--asphalt30);
                }
            }
        }

    }
</style>