<template>
    <div class="new-modal-footer" :class="modalSize">
        <div class="new-ufo-cta--container">
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {
    name: 'modal-footer',
    inject: ['modalSize']
}
</script>

<style lang="scss" scoped>
.new-modal-footer {
    padding: 24px;
    border-top: 1px solid var(--modal-border);

    .new-ufo-cta--container {
        align-items: center;
        display: flex;
        gap: 16px;
        justify-content: flex-end;
    }

    button {
        &:not(.link) {
            width: 100%;
        }
    }

    &.small {
        border-top: none;
        padding: 32px;
        .new-ufo-cta--container {
            justify-content: space-around;
            width: 100%;

            :deep(button) {
                &:not(.link) {
                    width: 100%;
                }
            }
        }
    }
}
</style>
