<template>
    <div v-if="!iframeUrl" :class="['ufo--site-nav', portfolioTemplate, showNavigation ? 'nav-is-on' : '']">
        <template v-if="showNavigation">

            <template v-if="portfolioTemplate.includes('profile_')">
                <AnimationWrapper
                    :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                    :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                >
                    <template #animationContent>
                        <GetSiteLogo />
                    </template>
                </AnimationWrapper>
                <HamburgerToggle />
            </template>

            <button v-if="showSidebarToggleButton" @click="toggleNav" class="ufo--site-nav-sidebar-toggle"><span></span>Menu</button>
            <div :class="['navbar', {'ufo--site-nav-container': portfolioTemplate.includes('profile_')}, navBarLayout, navBarStyle]">
                <GetSiteLogo v-if="!portfolioTemplate.includes('profile_')" />
                <div v-if="showSidebarToggleButton || portfolioTemplate === 'profile_theme_four' || portfolioTemplate === 'profile_theme_three' || portfolioTemplate === 'profile_theme_nine'" :class="['ufo--site-nav-owner-id', contentPosition]">
                    <div v-if="showUserImage && portfolio.image.url" class="image">
                        <img :src="portfolio.image.url" width="145" height="145">
                    </div>
                    <div v-if="showUserName" class="name">{{user.name}}</div>
                    <div v-if="portfolioTemplate === 'profile_theme_nine'" class="profile-links">
                        <GetHeroSocialLinks v-if="socialLinks && socialLinks.length"/>
                    </div>
                    <div v-if="portfolioTemplate === 'profile_theme_nine'" class="profile-bio">
                        <div :class="['description',
                                    'ufo--page-hero-text',
                                    portfolio.templateSettings.hero && portfolio.templateSettings.hero.textAlign ? 'align-text-' + portfolio.templateSettings.hero.textAlign : '']"
                             v-on:scroll="scrolledDown()"
                             v-html="portfolio.description"
                        >
                        </div>
                    </div>
                    <button v-if="showSidebarToggleButton" @click="toggleNav" class="ufo-site-nav-sidebar-close"><span></span></button>
                </div>

                <div :class="['navbar-container',  $store.state.view.navOpen ? 'on' : '']" :style="usePortfolioHeroBackground">
                    <div v-if="showLines" class="line"></div>
                    <GetHeroNavigationItem v-for="(page, index) in portfolioPages"
                                           :page="page"
                                           :key="page._id"
                                           :index="index"

                    />
                    <GetHeroSocialLinks v-if="socialLinks && socialLinks.length && showSocialLinks"/>
                    <div v-if="showLines" class="line"></div>
                </div>
                <div v-if="portfolioTemplate === 'theme_three' && showUserImage && portfolio.image.url" class="ufo--page-hero-image">
                    <img class="hero-image" :src="portfolio.image.croppedImageUrl ? portfolio.image.croppedImageUrl : portfolio.image.url" width="48" height="48">
                </div>

                <HamburgerToggle v-if="!portfolioTemplate.includes('profile_')" />
            </div>
        </template>
        <div v-if="!showNavigation" class="ufo--site-owner" @click="goHome()">
            <div v-if="user.image.url" class="picture">
                <img height="50" :src="user.image.url"/>
            </div>
            <div :class="['info', section.backgroundColor && section.backgroundColor === 'FFFFFF' ? 'dark' : '']">
                <div v-if="user.name" class="name">{{user.name}}</div>
                <div v-if="user.title" class="company">{{user.title}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import GetSiteSocialLinks from "./getSiteSocialLinks.vue";
    import GetHeroNavigationItem from "../../common/hero/GetHeroNavigationItem.vue";
    import GetHeroSocialLinks from "../../common/hero/GetHeroSocialLinks.vue";
    import GetSiteLogo from "../_helper/GetSiteLogo.vue";
    import HamburgerToggle from "../../../components/HamburgerToggle.vue";
    import {NavigationLayoutEnum} from "../../../../common/enums.js";
    import AnimationWrapper from "../../../../common/components/AnimationWrapper.vue";

    export default {
        name: "GetSiteNavigation",
        components: {
            GetHeroNavigationItem,
            GetSiteLogo,
            AnimationWrapper,
            HamburgerToggle,
            GetHeroSocialLinks,
            GetSiteSocialLinks
        },
        props: ['section'],
        data() {
            return {
                showSidebarNav:false
            }
        },
        mounted() {
            window.onload = this.setScrollClass;
            window.onresize = this.setScrollClass;
        },
        methods: {
            toggleNav() {
                let nav = document.querySelector('.navbar')
                nav.classList.toggle('visible');
                this.showSidebarNav = !this.showSidebarNav;
            },
            scrolledDown() {
                const e = document.querySelector('.ufo--page-hero-text');
                e.classList.toggle('scrollable', e.scrollTop !== (e.scrollHeight - e.offsetHeight));
            },
            setScrollClass() {
                const e = document.querySelector('.ufo--page-hero-text');
                e.classList.toggle('scrollable', e.scrollHeight > e.clientHeight);
            },
            goHome() {
                window.location = this.portfolio.ownDomainAddress ? 'https://' + this.portfolio.ownDomainAddress :  '/' + this.portfolio.shortId
            }
        },
        computed: {
            portfolioPages() {
                return this.$store.state.view.portfolioPages
            },
            portfolioTemplate() {
                return this.portfolio.templateSettings.theme.replace("_old", "")
            },
            socialLinks() {
                return this.portfolio.socialLinks ? this.portfolio.socialLinks :false
            },
            showNavigation() {
                return this.portfolio.settings && this.portfolio.settings.showNavOnCollectionItems === true
            },
            showSocialLinks() {
                return (this.portfolioTemplate === 'profile_theme_four'
                    || this.portfolioTemplate === 'profile_theme_one'
                    || this.portfolioTemplate === 'profile_theme_two'
                    || this.portfolioTemplate === 'theme_five'
                    || this.portfolioTemplate === 'profile_theme_five'
                    || this.portfolioTemplate === 'theme_six'
                    || this.portfolioTemplate === 'profile_theme_six'
                    || this.portfolioTemplate === 'theme_ten'
                    || this.portfolioTemplate.includes('profile_theme_default')
                )
            },
            showSidebarToggleButton() {
                return (this.portfolioTemplate === 'profile_theme_four' || this.portfolioTemplate === 'profile_theme_six' || this.portfolioTemplate === 'profile_theme_nine' || this.portfolioTemplate === 'theme_six')
            },
            showUserImage() {
                return this.portfolio.templateSettings.showProfilePicture &&
                        (
                            this.portfolioTemplate.includes("theme_three") ||
                            this.portfolioTemplate === "profile_theme_four" ||
                            this.portfolioTemplate === "profile_theme_nine"
                        )
            },
            showUserName()  {
                return this.portfolioTemplate === 'profile_theme_four';
            },
            iframeUrl() {
                return window.location.pathname.includes('/uxs-headless/\'')
            },
            user() {
                return this.$store.state.view.user
            },
            portfolio() {
                return this.$store.state.view.portfolio
            },
            project() {
                return this.$store.state.view.project
            },
            theme() {
                return this.portfolio.templateSettings.theme
            },
            usePortfolioHeroBackground() {
                return this.portfolio.templateSettings.theme === 'theme_five' && this.portfolio.templateSettings.hero.image.url && !this.portfolio.designSettings.colorStyles.background.primaryColor
                    ? [
                        {'background': `url('${this.portfolio.templateSettings.hero.image.url}') 100% 100% / 100% 100% no-repeat`},
                    ]
                    : null
            },
            navBarLayout() {
                return this.portfolio.templateSettings.navigation.layout
            },
            navBarStyle() {
                return this.portfolio.templateSettings.navigation.style
            },
            showLines() {
                return this.portfolio.templateSettings.navigation.layout === NavigationLayoutEnum.LINE_MIDDLE
            },
            contentPosition() {
                return this.theme === "profile_theme_nine"
                    ? `align-content-to-${this.portfolio.templateSettings.hero.alignment.vertical}`
                    : null
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "../../../../../styles/layouts/new-portfolio-layout/components/navbar";
</style>