<template>
    <div v-if="section && section.columns" :id="'section-' + section.index" class="ufo-sec ufo-sec--columns">
        <ToggleGrid />
        <div :class="['grid', 'ufo--columns', 'ufo--columns-' + section.columns.length]">
            <div v-bind:key="column" v-for="(column, index) in section.columns" class="ufo--column" :class="mockupsOn ? 'mockups-on' : ''">
                <div v-if="section.showMedia && section.showMedia"  class="media-container">
                    <ColumnImageHelper :index="index" :total-columns="section.columns.length" :section="section" :column-media="column.media" :placeholder-image="`${isCaseStudy ? '' : 'portfolio-'}empty-columns-0${index+1}.${isCaseStudy ? 'jpg' : 'png'}`"/>
                </div>
                <SectionText :text-index="index" :total-columns="section.columns.length" :section-text="column.text"/>
            </div>
        </div>
    </div>
</template>

<script>
    import ColumnImageHelper from "./_helper/ColumnImageHelper.vue";
    import SectionText from "./_helper/GetSectionText.vue";
    import ToggleGrid from "../../../common/components/ToggleGrid.vue";

    export default {
        name: "ColumnsSection",
        components: {SectionText, ColumnImageHelper, ToggleGrid},
        props: [ 'section' ],
        computed: {
            mockupsOn() {
                return this.section.columns.filter(x => x.media.frame).length
            },
            isCaseStudy() {
                return this.$store.getters.isCaseStudy
            }
        }
    }
</script>

<style scoped>

</style>