<template>
    <RouterLink v-if="showOwnerId || showLogo"
                 :to="homeLink"
                 :class="[showOwnerId && !showLogo ? 'navbar-id' : 'navbar-logo']"
    >
        <!-- user name -->
        <template v-if="showOwnerId && !showLogo">
            {{$store.state.view.user.name}}
        </template>

        <!-- logo -->
        <template v-if="showLogo">
            <img :src="portfolio.templateSettings.logo.image.url"
                 :height="portfolio.templateSettings.logo.image.height"
                 alt="Portfolio logo"
            >
        </template>

    </RouterLink>
</template>

<script>

export default {
    name: 'GetSiteLogo',
    computed: {
        portfolio() {
            return this.$store.state.view.portfolio
        },
        homeLink() {
            return this.portfolio.ownDomainAddress ? '/' : `/${this.portfolio.shortId}`
        },
        showLogo() {
            let hiddenInCaseStudy = (this.$store.getters.isCaseStudy && this.portfolio.templateSettings.theme !== 'theme_seventeen') || !this.$store.getters.isCaseStudy;
            return !!this.portfolio.templateSettings.logo && this.logoIsAvailableInTheme && hiddenInCaseStudy
        },
        showOwnerId() {
            return this.portfolio.templateSettings.navigation.navigationItems.ownerId.visible
        },
        logoIsAvailableInTheme() {
            // logo supported in the following templates
            // Nelson 13, Otis 12, Bauhaus 11, Orielle 10, Nominee 8, Panorama 5, Poster
            let notAllowedInThemes = ['theme_one', 'theme_two', 'theme_three', 'theme_six', 'theme_seven', 'theme_eleven'];
            return !notAllowedInThemes.includes(this.portfolio.templateSettings.theme)
                && !this.portfolio.templateSettings.theme.includes('profile_'); // it is not allowed in legacy themes
        }
    }
}
</script>