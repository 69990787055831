<template>
    <div v-if="socialLinks" :class="['social-links', alignment(), socialLinksLocation]">
        <template v-bind:key="socialLink" v-for="(socialLink, index) in filteredSocialLinks">
            <AnimationWrapper
                :animation-style="$store.getters.animationSettings && $store.getters.animationSettings.animationStyle !== 'NONE' ? 'FADE_IN' : null"
                :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                :delay-animation="0.6 + index * 0.1"
            >
                <template #animationContent>
                    <a :href="socialLink.url" target="_blank" rel="nofollow noopener" :class="[socialLinkStyle, linkIsTextType ? 'text' : 'icon', getCleanType(socialLink.type).toLowerCase()]">
                        <span v-if="linkIsTextType">{{ getCleanType(socialLink.type) }}</span>
                        <i v-else :class="['fa', 'fa-' + socialLink.type.toLowerCase()]"></i>
                    </a>
                </template>
            </AnimationWrapper>
        </template>
    </div>
</template>

<script>
    import {SocialLinkTypeEnum} from "../../../../common/enums.js";

    import AnimationWrapper from "../../../../common/components/AnimationWrapper.vue";

    export default {
        name: "UserSocialLinks",
        components: {AnimationWrapper},
        methods: {
            getCleanType(linkType) {
                if(linkType.toLowerCase() === "x-twitter") return 'x';

                return this.portfolio.templateSettings.navigation.navigationItems.button.upperCase
                    ? linkType.toUpperCase()
                    : linkType
            },
            alignment() {
                return !!this.portfolio.templateSettings.hero && !!this.portfolio.templateSettings.hero.textAlign
                    ? `align-${this.portfolio.templateSettings.hero.textAlign}`
                    : null
            },
        },
        computed: {
            user() {
                return this.$store.state.view.user
            },
            portfolio() {
                return this.$store.state.view.portfolio
            },
            templateSettings() {
                return this.portfolio.templateSettings
            },
            socialLinks() {
                return this.user.socialLinks ? this.user.socialLinks : this.portfolio.socialLinks ? this.portfolio.socialLinks : null;
            },
            filteredSocialLinks(){
                return this.socialLinks.filter(x => x.url !== "")
            },
            socialLinkStyle() {
                return this.portfolio.templateSettings.navigation.navigationItems.socialLink.style
            },
            linkIsTextType() {
                return this.portfolio.templateSettings.navigation.navigationItems.socialLink.type === SocialLinkTypeEnum.TEXT
            },
            socialLinksLocation() {
                return this.portfolio.templateSettings.navigation.socialLinksLocation
            },
        }
    }
</script>

<style lang="scss" scoped>
@import "../../../../../styles/layouts/new-portfolio-layout/components/social-links";
</style>