<template>
    <div :id="'section-' + section.index"
         class="ufo-sec ufo-sec--testimonial"
    >
        <ToggleGrid />
        <SectionBackgroundImage :section="section" />
        <div class="grid">
            <div class="testimonial">
                <div ref="slider" class="ufo--slider">
                    <div class="ufo--slider-track slider__track" data-glide-el="track" style="overflow:hidden;">
                        <ul class="ufo--slides carousel__slides--slider">
                            <li v-for="(quote, index) in section.testimonial" class="ufo--slide" :key="`quote-${index}`">
                                <div v-if="section.showMedia" class="image">
                                    <AnimationWrapper
                                        :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                                        :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                                    >
                                        <template #animationContent>
                                            <GetColumnMediaContent v-if="quote.media.url" :column-media="quote.media" :placeholder-image="''" />
                                        </template>
                                    </AnimationWrapper>
                                </div>
                                <AnimationWrapper
                                    :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                                    :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                                >
                                    <template #animationContent>
                                        <div :class="['quote', quote.text.style.textStyle]" v-html="getQuote(quote.text.text)"></div>
                                    </template>
                                </AnimationWrapper>
                                <AnimationWrapper
                                    :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                                    :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                                >
                                    <template #animationContent>
                                        <div :class="['author', quote.author.style.textStyle]" v-html="getQuote(quote.author.text)"></div>
                                    </template>
                                </AnimationWrapper>
                            </li>
                        </ul>
                    </div>
                    <AnimationWrapper
                        :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                        :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                    >
                        <template #animationContent>
                            <div v-if="section.testimonial.length > 1" class="ufo--slider-bullets glide__bullets" data-glide-el="controls[nav]">
                                <button v-bind:key="index" v-for="(quote, index) in section.testimonial" class="ufo--slider-bullet" :data-glide-dir="`=${index}`" @click="navToSlide(index)"></button>
                            </div>
                        </template>
                    </AnimationWrapper>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GetColumnMediaContent from "./_helper/GetColumnMediaContent.vue";
    import Glide from "@glidejs/glide";
    import ToggleGrid from "../../../common/components/ToggleGrid.vue";
    import AnimationWrapper from "../../../common/components/AnimationWrapper.vue";
    import SectionBackgroundImage from "../../../sections/common/SectionBackgroundImage.vue";

    export default {
        name: "TestimonialSection",
        components: {
            SectionBackgroundImage,
            AnimationWrapper, GetColumnMediaContent, ToggleGrid },
        props: ['section'],
        data() {
            return {
                slider: null,
            }
        },
        mounted() {
            if(this.section.testimonial.length > 1) this.mountSlider(0);
        },
        unmounted() {
            this.destroySlider();
        },
        methods: {
            getQuote(text) {
                return text.replace('\n', '<br />')
            },
            mountSlider(position) {
                if(!!this.slider) return this.$nextTick(() => this.slider.update({ startAt: position }) );
                this.slider = new Glide(this.$refs.slider, {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    startAt: position,
                    dots: '.ufo--slider-bullets',
                    activeNav: 'ufo--slider-bullet-active',
                    keyboard: false,
                    perView: 1,
                    animationDuration: 300,
                    focusAt: 'center',
                    dragThreshold: false
                }).mount();
            },
            navToSlide(slide) {
                return this.slider.go(`=${slide}`)
            },
            destroySlider() {
                if(!!this.slider) {
                    this.slider.destroy();
                    this.slider = null;
                }
            }
        }
    }
</script>