export const FontCategoryEnum = Object.freeze({
    OUR_PICKS: "OurPicks",
    SANS: "Sans",
    SERIF: "Serif",
    MONO: "Mono"
});

export const FontCategoryColors = Object.freeze({
    OUR_PICKS: '#E4FE72',
    SANS: '#fe9772',
    SERIF: '#72fefe',
    MONO: '#fe72b8'
});

export const FontRepositoryTypes = Object.freeze({
    UNIQUE_REPOSITORY: 'UNIQUE_REPOSITORY',
    GOOGLE_API: 'GOOGLE_API'
})