<template>
    <!-- For profile_theme_one and profile_theme_two -->
    <ToggleGrid />
    <UserThemeCoverImage v-if="portfolio && portfolio.image.url"/>
    <UserImage v-else />
    <div class="profile-short-cv">
        <UserPortfolioDescription v-if="portfolio && portfolio.description"/>
        <template v-else>
            <UserName />
            <UserJobTitle />
            <UserDescription />
        </template>
        <GetHeroNavigation />
    </div>
</template>

<script>
    import UserThemeCoverImage from "../../common/user/UserThemeCoverImage.vue";
    import UserImage from "../../common/user/UserImage.vue";
    import UserPortfolioDescription from "../../common/user/UserPortfolioDescription.vue";
    import UserSocialLinks from "../../common/user/UserSocialLinks.vue";
    import UserName from "../../common/user/UserName.vue";
    import UserJobTitle from "../../common/user/UserJobTitle.vue";
    import UserDescription from "../../common/user/UserDescription.vue";
    import ToggleGrid from "../../../../common/components/ToggleGrid.vue";
    import GetHeroNavigation from "../../common/hero/GetHeroNavigation.vue";

    export default {
        name: "ProfileThemeCommon",
        components: {
            GetHeroNavigation,
            ToggleGrid,
            UserDescription,
            UserJobTitle,
            UserName,
            UserSocialLinks,
            UserPortfolioDescription,
            UserImage,
            UserThemeCoverImage
        },
        computed: {
            portfolio() {
                return this.$store.state.view.portfolio
            }
        }
    }
</script>