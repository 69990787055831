<template>
    <ReceiverFabs v-if="isReceiverMode" :invertedIndex="invertedIndex" :hovering="hovering" :section="section" />
    <ReviewFabs v-else :invertedIndex="invertedIndex" :hovering="hovering" :section="section"/>
</template>

<script>
    import ReviewFabs from "./reviewer/ReviewFabs.vue";
    import ReceiverFabs from "./receiver/ReceiverFabs.vue";

    export default {
        name: "FabWrapper",
        components: {
            ReceiverFabs, ReviewFabs
        },
        props:['section', 'hovering', 'invertedIndex'],
        computed: {
            isReceiverMode() {
                return this.$store.state.reviewMode.receiver || (this.$store.state.reviewMode.review && this.$store.state.reviewMode.review.submitted)
            }
        }
    }
</script>