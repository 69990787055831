import ApiHelper from '../ApiHelper.js';

const FontApiUrl = "/api/fonts";
const FontPairApiUrl = "/api/fontpairs"

export default {

    //FONTS
    //get
    getFonts(query) {
        return ApiHelper.get(FontApiUrl + query);
    },
    //put
    updateFont(id, font) {
        return ApiHelper.put(FontApiUrl + "/" + id, font);
    },
    //delete
    deleteFont(id) {
        return ApiHelper.delete(FontApiUrl + "/" + id);
    },


    //FONT-PAIRS
    //get
    getFontPairs(query) {
        return ApiHelper.get(FontPairApiUrl + query);
    },
    //post
    createFontPair(data) {
        return ApiHelper.post(FontPairApiUrl, data)
    },
    //put
    updateFontPair(id, fontPair) {
        return ApiHelper.put(FontPairApiUrl + "/" + id, fontPair);
    },
    //delete
    deleteFontPair(id) {
        return ApiHelper.delete(FontPairApiUrl + "/" + id);
    }
}