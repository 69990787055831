<template>
    <ModalWrapper :show="show" @close="close" :modal-size="'medium'" dark-scheme>
        <template #modal-header>
            <ModalHeader
                :title="'Review summary'"
                dark-scheme
            >
                <template #tabPills>
                    <TabBar dark-scheme hide-border>
                        <TabItem v-bind:key="tab.key"
                             v-for="tab in tabs"
                             :icon="tab.svg"
                             :label="tab.name"
                             :active="tab.key === activeTab"
                             dark-scheme
                             @click="() => changeTab(tab.key)"/>
                    </TabBar>
                </template>
            </ModalHeader>
        </template>
        <template #modal-body>
            <ModalBody>
                <div class="tab-data">
                    <div class="overview" v-if="activeTab === 'overview'">
                        <OverviewField :fieldToUpdate="'positiveAspects'" :is-input="false" :title="'Positive aspects'" icon="positive-aspect" />
                        <OverviewField :fieldToUpdate="'negativeAspects'" :is-input="false" :title="'Areas of improvement'" icon="areas-of-improvement" />
                    </div>
                    <div class="comments" v-if="activeTab === 'comments' && sectionReactionComments.length">
                        <div class="selector-container">
                            <DropDownSelect
                                :label="'Show comments on'"
                                :selected="getSelectedName(selectedPage.shortId)"
                                :options="availablePages.map(x => x.name)"
                                @click="updateSelected($event)"
                                dark-scheme
                            />

                        </div>
                        <div class="comments-list">
                            <Comment
                                v-bind:key="comment.label"
                                v-for="(comment, index) in sectionReactionComments"
                                :comment="comment"
                                :edit="false"
                                :index="index"
                            />
                        </div>
                    </div>
                </div>
            </ModalBody>
        </template>
        <template #modal-footer>
            <ModalFooter v-if="!admin">
                <CtaButton dark-scheme type="secondary" @click="close">Back to reviewing</CtaButton>
                <CtaButton dark-scheme type="primary" @click="submitReview">Submit review</CtaButton>
            </ModalFooter>
        </template>
    </ModalWrapper>
</template>

<script>
import CtaButton from "../../../common/components/CtaButton.vue";
import OverviewField from "../components/OverviewField.vue";
import Comment from "../components/Comment.vue";
import ApiHelper from "../../../api/ApiHelper.js";
import DropDownSelect from "../../../common/components/DropDownSelect.vue";
import ModalWrapper from "../../../sections/modals/_ModalWrapper.vue";
import ModalFooter from "../../../sections/modals/_ModalFooter.vue";
import ModalHeader from "../../../sections/modals/_ModalHeader.vue";
import TabBar from "../../../common/components/TabBar.vue";
import TabItem from "../../../common/components/TabItem.vue";
import ModalBody from "../../../sections/modals/_ModalBody.vue";
import {event} from "vue-gtag";

export default {
        name: "OverviewModal",
        props: ['show', 'close', 'admin', 'openSuccessModal'],
        components: {
            ModalBody,
            TabItem,
            TabBar,
            ModalHeader,
            ModalFooter,
            ModalWrapper,
            DropDownSelect,
            CtaButton,
            OverviewField,
            Comment
        },
        data() {
            return {
                tabs: [
                    {key:'overview', name:'Overview', svg:null},
                    {key:'comments', name:'Comments', svg:null},
                ],
                activeTab:'overview',
                selectedPage:{
                    name:'',
                    shortId:'home'
                }
            }
        },
        methods: {
            async submitReview() {
                try {
                    let res = await ApiHelper.post('/api/review/submit/' + this.$store.state.reviewMode.review._id + "/" + this.$store.state.reviewMode.review.contentId);
                    //GA Event
                    event('Submit Review', {
                        'event_category': 'Review mode',
                        'event_label':  `Reviewer: ${this.$store.state.reviewMode.review.reviewerDetails.email}, Portfolio: ${this.$store.state.view.portfolio._id}`
                    });
                    if(this.$store.state.user) {
                        this.close();
                        this.openSuccessModal();
                    }else {
                        window.location.replace(window.location.origin + "/result");
                    }
                }
                catch(err) {
                    console.log('Failed to save review', err)
                }
            },
            changeTab(tab) {
                this.activeTab = tab
            },
            updateSelected(page) {
                this.selectedPage = this.availablePages.find(x => x.name === page);
            },
            getSelectedName(shortId) {
                let page = this.availablePages.find(x => x.shortId === shortId)
                return page.name
            }
        },
        mounted() {
            let pages = this.availablePages;
            if(pages.length) {
                this.selectedPage = pages[0]
            }
        },
        computed: {
            sectionReactionComments() {
                let commentsWithPage =  this.$store.state.reviewMode.review.sectionReactions.map(x => x.comments.map(comment => { return {...comment, page: x.pageData.shortId} })).flat();
                return commentsWithPage.filter(comment => comment.page === this.selectedPage.shortId);
            },
            availablePages() {
                let uniquePageData = [];
                const uniqueIds = new Set();

                this.$store.state.reviewMode.review.sectionReactions.forEach(section => {
                    if (!uniqueIds.has(section.pageData.shortId) && section.comments.length) {
                        uniqueIds.add(section.pageData.shortId);
                        uniquePageData.push(section.pageData);
                    }
                });

                return uniquePageData
            }
        }

    }
</script>

<style scoped lang="scss">
    .tab-data {
        display: flex;
        height: 500px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        overflow: auto;
        overscroll-behavior: contain;

        ::-webkit-scrollbar-track {
            background-color: transparent; /* Makes the track transparent */
        }

        scrollbar-color: #888 transparent; /* Thumb color and track color */

        p {
            color: var(--asphalt10);
        }

        .overview {
            width: 100%;
            padding: 40px 24px;
        }

        .comments {
            display: flex;
            height: 500px;
            flex-direction: column;
            width: 100%;

            .selector-container {
                display: flex;
                padding: 24px;
                flex-direction: column;
                justify-content: center;
                gap: 10px;

                .text {
                    font: var(--editor-ui-b2-regular);
                    color:var(--asphalt40);
                }

                .string-value {
                    font: var(--editor-ui-b2-regular);
                    color:var(--asphalt40);
                }

                .string-option {
                    font: var(--editor-ui-b2-regular);
                    color:var(--asphalt40);

                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    svg {
                        width: 24px;
                        height: 24px;
                        color:var(--asphalt10);
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: var(--asphalt80);
                    }
                }
            }

            .comments-list {
                overflow:auto;
                overscroll-behavior: contain;

                ::-webkit-scrollbar-track {
                    background-color: transparent; /* Makes the track transparent */
                }

                scrollbar-color: #888 transparent; /* Thumb color and track color */
            }
        }
    }
</style>