<template>
    <svg width="67" height="18" viewBox="0 0 69 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M33.0226 13.8586C32.6981 13.9416 32.2752 14.0286 31.7538 14.1195C31.1792 14.2145 30.5978 14.2602 30.0158 14.2559C29.5137 14.2705 29.0137 14.1839 28.5442 14.0009C28.1746 13.8512 27.8478 13.6085 27.5941 13.2952C27.3485 12.9761 27.1744 12.6056 27.0843 12.21C26.9771 11.7577 26.9246 11.2937 26.9279 10.8283V6.67725H28.6196V10.5555C28.5748 11.1451 28.6954 11.7356 28.9672 12.2575C29.2537 12.6251 29.7038 12.8174 30.1606 12.7674C30.3653 12.7674 30.5816 12.7674 30.8095 12.7674C30.9809 12.7566 31.1513 12.7329 31.3193 12.6963V6.68318H33.0226V13.8586Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M37.8541 8.94854L39.3951 6.68325H41.1679L38.7115 10.2413C39.2252 10.8936 39.7157 11.5538 40.183 12.2219C40.6106 12.8208 40.9978 13.4489 41.3417 14.1018H39.5168C39.4185 13.8975 39.3082 13.6994 39.1865 13.5088L38.7578 12.8861C38.6033 12.6608 38.443 12.4374 38.277 12.216C38.1109 11.9946 37.9487 11.7871 37.7903 11.5934C37.4466 11.9995 37.1255 12.4251 36.8286 12.8683C36.5196 13.319 36.2628 13.7203 36.0581 14.0721H34.3201C34.4727 13.7637 34.643 13.4647 34.8299 13.1767C35.0307 12.8604 35.247 12.5382 35.4788 12.2101C35.7105 11.882 35.948 11.5538 36.1913 11.2257C36.4347 10.8976 36.6761 10.5852 36.9155 10.2887L34.4359 6.67139H36.2956L37.8541 8.94854Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M45.6347 3.10152C45.9813 3.09712 46.3273 3.13292 46.666 3.20826C46.8934 3.25784 47.1178 3.32121 47.338 3.39802L47.0136 4.81531C46.8278 4.73186 46.6335 4.67019 46.4342 4.63148C46.2144 4.59173 45.9911 4.57583 45.768 4.58404C45.5347 4.57713 45.3024 4.61743 45.0844 4.70264C44.911 4.77369 44.7577 4.88782 44.6383 5.03473C44.522 5.18613 44.4408 5.36252 44.4007 5.55064C44.3528 5.76657 44.3294 5.98746 44.3312 6.20888V6.70108H45.768V8.13023H44.3486V14.0722H42.6569V6.15551C42.6112 5.33822 42.877 4.53464 43.3985 3.91394C43.9964 3.3287 44.8102 3.03304 45.6347 3.10152Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M53.421 10.3777C53.4283 10.9221 53.3441 11.4637 53.1719 11.9788C53.0185 12.4352 52.7761 12.8549 52.4593 13.2123C52.1481 13.5553 51.7688 13.8262 51.347 14.0069C50.8938 14.2001 50.4069 14.297 49.916 14.2916C49.4269 14.2977 48.9418 14.2008 48.4908 14.0069C48.0732 13.8246 47.6981 13.5537 47.3901 13.2123C47.0726 12.8543 46.8284 12.4349 46.6717 11.9788C46.4933 11.465 46.405 10.923 46.411 10.3777C46.4045 9.83433 46.4927 9.29413 46.6717 8.78251C46.8268 8.32565 47.0712 7.90595 47.3901 7.54905C47.702 7.20836 48.0812 6.93951 48.5024 6.76035C48.9506 6.57138 49.4313 6.4766 49.916 6.48164C50.4025 6.47725 50.885 6.57199 51.3354 6.76035C51.7558 6.93796 52.1333 7.20709 52.4419 7.54905C52.7584 7.90556 53.0025 8.32279 53.1603 8.77658C53.3392 9.2882 53.4275 9.8284 53.421 10.3718M51.683 10.3718C51.7155 9.75205 51.5508 9.13826 51.2137 8.6224C50.8972 8.21457 50.4162 7.97689 49.9073 7.97689C49.3985 7.97689 48.9174 8.21457 48.6009 8.6224C48.2638 9.13826 48.0992 9.75205 48.1316 10.3718C48.0966 11.0011 48.2612 11.6251 48.6009 12.1508C48.9149 12.5628 49.3968 12.8036 49.9073 12.8036C50.4178 12.8036 50.8997 12.5628 51.2137 12.1508C51.553 11.6249 51.7176 11.001 51.683 10.3718Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M60.605 4.51287C60.6181 4.80178 60.5057 5.08184 60.2979 5.27785C60.1009 5.464 59.8418 5.56586 59.5737 5.5625C59.3 5.56861 59.0345 5.46669 58.8322 5.27785C58.6201 5.08404 58.5033 4.80368 58.5135 4.51287C58.4985 4.22017 58.611 3.93571 58.8206 3.73603C59.0232 3.54772 59.2885 3.44587 59.5621 3.45138C59.8301 3.44862 60.089 3.5504 60.2863 3.73603C60.4995 3.934 60.6162 4.21843 60.605 4.51287ZM58.7337 14.0722H60.4254V6.68328H58.7337V14.0722Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M68.9996 10.3777C69.007 10.9221 68.9227 11.4637 68.7505 11.9788C68.5972 12.4352 68.3547 12.8549 68.0379 13.2123C67.7267 13.5553 67.3474 13.8262 66.9256 14.0069C66.4724 14.2001 65.9855 14.297 65.4946 14.2916C65.0055 14.2977 64.5204 14.2008 64.0694 14.0069C63.6518 13.8246 63.2767 13.5537 62.9687 13.2123C62.6512 12.8543 62.407 12.4349 62.2503 11.9788C62.0719 11.465 61.9836 10.923 61.9896 10.3777C61.9831 9.83433 62.0714 9.29413 62.2503 8.78251C62.4054 8.32565 62.6498 7.90595 62.9687 7.54905C63.2806 7.20836 63.6598 6.93951 64.081 6.76035C64.5292 6.57138 65.0099 6.4766 65.4946 6.48164C65.9811 6.47725 66.4636 6.57199 66.914 6.76035C67.3344 6.93796 67.712 7.20709 68.0205 7.54905C68.337 7.90556 68.5811 8.32279 68.7389 8.77658C68.9179 9.2882 69.0061 9.8284 68.9996 10.3718M67.2616 10.3718C67.2941 9.75205 67.1294 9.13826 66.7923 8.6224C66.4758 8.21457 65.9948 7.97689 65.4859 7.97689C64.9771 7.97689 64.496 8.21457 64.1795 8.6224C63.8424 9.13826 63.6778 9.75205 63.7102 10.3718C63.6752 11.0011 63.8398 11.6251 64.1795 12.1508C64.4935 12.5628 64.9755 12.8036 65.4859 12.8036C65.9964 12.8036 66.4784 12.5628 66.7923 12.1508C67.1316 11.6249 67.2962 11.001 67.2616 10.3718Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M55.0723 14.0722H56.7755V3.67676H55.0723V14.0722Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1379 6.59422L23.2953 7.6557L23.4749 7.89884L12.4674 19L0 0.0236816L20.1379 6.59422ZM13.9042 15.5012V15.5309L21.0243 8.36139L3.38915 2.60327L11.5868 15.092L12.6354 16.7643L13.9042 15.5012Z" fill="currentColor"/>
        <path d="M16.5287 0L15.4048 4.14513L16.7952 4.60175L17.2239 3.01248L20.4972 6.71286L20.138 6.59426L23.2954 7.66167L16.5287 0Z" fill="currentColor"/>
        <path d="M14.5531 7.2644L12.2647 16.1062L11.6042 15.098L12.6644 16.7644L13.9332 15.5013L15.9377 7.72102L14.5531 7.2644Z" fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: "UXFolioLogo"
}
</script>

<style lang="scss" scoped>
    svg {
        color: var(--asphalt100);
    }
</style>
