<template>
    <div :id="section._id" v-if="$store.state.reviewMode.displayUI" :class="['review-fabs', section.type]" :style="{zIndex:invertedIndex}">
        <div class="sticky">
            <div ref="reaction" v-if="hovering || hasReaction || (reaction || comments)" class="reaction-fab-container">
                <fab :selected="reaction" :icon="sectionReaction.reaction === null ? 'reaction' : null" @click="showReaction" tooltip="Reaction" :tooltip-position="'top'" >
                    <template v-if="sectionReaction.reaction" #reaction>
                        <div class="reaction-in-fab">
                            <div class="reaction-wrapper">
                                <img :src="getReactionImage(sectionReaction.reaction)" alt="reaction"/>
                            </div>
                        </div>
                    </template>
                </fab>
                <div class="reactions-container" v-if="reaction">
                    <div @click="() => reviewMode ? setEmoji(reaction.value) : null" class="reaction" :class="[{selected:reaction.value === sectionReaction.reaction}]" v-for="reaction in reactions">
                        <div class="reaction-wrapper">
                            <img :src="reaction.img" alt="reaction"/>
                        </div>
                    </div>
                </div>
            </div>
            <div ref="comments" v-if="hovering || hasReaction || (comments || reaction)" class="comments-fab-container">
                <fab :selected="comments" :icon="!sectionReaction.comments.length ? 'comment' : null" @click="showComments" tooltip="Comments" :tooltip-position="'top'">
                    <template v-if="sectionReaction.comments.length" #comment>
                        <div class="comment-in-fab">
                            <Icon :icon-name="'chat'" />
                            <span>{{sectionReaction.comments.length}}</span>
                        </div>
                    </template>
                </fab>
                <div class="comments-container" v-if="comments">
                    <div class="header-row">
                        <div>{{ sectionReaction.comments.length ? 'Comments' : 'Add comment' }}</div>
                        <Icon iconName="close" @click="showComments"/>
                    </div>
                    <div ref="commentList" class="comments" v-if="sectionReaction.comments.length && updating === null">
                        <Comment
                            v-bind:key="comment.label"
                            v-for="(comment, index) in sortedComments"
                            :comment="comment"
                            :updateComment="() => updateComment(index)"
                            :deleteComment="() => showDeleteModal(index)"
                            :edit="reviewMode"
                            :index="index"

                        />
                    </div>
                    <CommentInput v-if="reviewMode" :current-comment="currentComment" :add-comment="addComment" :update-comment-label="updateCommentLabel" />
                </div>
            </div>
            <ConfirmationModal :show="showConfirmationModal" :modal-size="'small'" @action="deleteComment()" @close="closeDeleteCommentModal()">
                <template #title>Delete comment</template>
                <template #content>
                    <p>Are you sure you want to delete this comment?</p>
                </template>
            </ConfirmationModal>
        </div>
    </div>
</template>

<script>
import Fab from "../../../../common/components/Fab.vue";
import Icon from "../../../../common/Icon.vue";
import Comment from "../Comment.vue";
import ApiHelper from "../../../../api/ApiHelper.js";
import ConfirmationModal from "../../../../common/ConfirmationModal.vue";
import CommentInput from "../CommentInput.vue";

//Emojis
import thumbsUp from "../../../../../images/review/emojis/thumbs-up.png";
import heart from "../../../../../images/review/emojis/heart.png";
import clap from "../../../../../images/review/emojis/clap.png";
import bulb from "../../../../../images/review/emojis/light-bulb.png";
import checkMark from "../../../../../images/review/emojis/check-mark.png";

export default {
        name: "ReviewFabs",
        props:['section', 'hovering', 'invertedIndex', 'review'],
        components: {
            CommentInput,
            ConfirmationModal,
            Fab,
            Icon,
            Comment
        },
        data() {
            return {
                reaction:false,
                comments:false,
                updating:null,
                indexToDelete:null,
                showConfirmationModal:false,
                show:false,
                listenerMounted:false,
                reactions: [
                    {name:'like', value:'&#128077', img:thumbsUp},
                    {name:'heart', value:'&#128150', img:heart},
                    {name:'clap', value:'&#128079', img:clap},
                    {name:'bulb', value:'&#128161', img:bulb},
                    {name:'check', value:'&#x2705', img:checkMark},
                ],
                currentComment: {text:null, label:null},
                defaultSectionReaction: {
                    sectionId:null,
                    reaction:null,
                    comments:[],
                    pageData:{
                        shortId:null,
                        name:null
                    }
                },
                indexToUpdate:null,
            }
        },
        watch: {
            '$route'(to, from) {
                //TODO: somehow when route changes and everything updates this shit is keeping the data of the previous section
                this.defaultSectionReaction = {
                    sectionId:null,
                        reaction:null,
                        comments:[],
                        pageData:{
                        shortId:null,
                        name:null
                    }
                }
                this.setDefaultValues();
            }
        },
        mounted() {
            this.setDefaultValues();
        },
        methods: {
            setDefaultValues() {
                this.defaultSectionReaction.sectionId = this.section._id;
                this.defaultSectionReaction.pageData = {
                    shortId:this.$store.state.view.project.shortId,
                    name:this.$store.state.view.project.name
                }
            },
            showReaction() {
                this.reaction = !this.reaction;
                this.comments = false;
                this.mountOutsideClick();
            },
            showComments() {
                this.comments = !this.comments;
                this.updating = null;
                this.reaction = false;
                this.mountOutsideClick();
            },
            mountOutsideClick() {
                if((this.reaction || this.comments) && !this.listenerMounted) {
                    this.listenerMounted = true;
                    document.addEventListener('click', this.clickOutside, true);
                } else {
                    this.listenerMounted = false;
                    document.removeEventListener('click', this.clickOutside, true);
                }
            },
            clickOutside() {
                if(this.reaction) {
                    if (this.reaction && this.$refs.reaction && !this.$refs.reaction.contains(event.target)) {
                        this.showReaction();
                    }
                }
                if(this.comments) {
                    if (this.comments && this.$refs.comments && !this.$refs.comments.contains(event.target)) {
                        this.showComments();
                    }
                }
            },
            setEmoji(emoji) {
                if(emoji === this.sectionReaction.reaction) {
                    this.sectionReaction.reaction = null;
                }
                else {
                    this.sectionReaction.reaction = emoji;
                }
                this.reaction = false;
                this.saveReaction();
            },
            updateCommentLabel(label) {
                this.currentComment.label = this.currentComment.label === label ? null : label;
            },
            addComment() {
                if(this.currentComment.text) {
                    if(this.updating === null) {
                        this.sectionReaction.comments.push(
                            {
                                ...this.currentComment,
                                createdAt:Date.now(),
                                fromUserId:this.$store.state.reviewMode.review.reviewerDetails._id
                            }
                            );
                        this.currentComment = {text:null, label:null};
                    }
                    else {
                        this.sectionReaction.comments[this.updating] = {...this.currentComment, createdAt:Date.now(), fromUserId:this.$store.state.reviewMode.review.reviewerDetails._id};
                        this.currentComment = {text:null, label:null};
                    }
                    this.saveReaction();

                    //Sroll down to new comment if not there
                    if(this.$refs.commentList) {
                        this.$nextTick(() => {
                            const container = this.$refs.commentList;
                            container.scrollTop = container.scrollHeight;
                        });
                    }
                }
            },
            async saveReaction() {
                if(this.hasReaction) {
                    if(!this.isReactionExists) {
                        this.sectionReactions.push({sectionId:this.section._id, ...this.sectionReaction});
                    }
                }
                else {
                    //If no reaction and comment, remove data
                    if(this.isReactionExists) {
                        if (this.sectionReactions.hasOwnProperty(this.savedDataIndex)) {
                            this.sectionReactions.splice(this.savedDataIndex, 1);
                        }
                    }
                }
                this.updating = null;
                await ApiHelper.post('/api/review/' + this.$store.state.reviewMode.review._id + "/" + this.$store.state.reviewMode.review.contentId, this.$store.state.reviewMode.review);
            },
            updateComment(index) {
                this.currentComment = this.sectionReaction.comments[index];
                this.updating = index;

            },
            showDeleteModal(index) {
                this.indexToDelete = index;
                this.showConfirmationModal = true;
            },
            closeDeleteCommentModal() {
                this.showConfirmationModal = false;
                this.indexToDelete = null;
            },
            deleteComment() {
                this.sectionReaction.comments.splice(this.indexToDelete, 1);
                this.indexToDelete = null
                this.saveReaction();
                this.showConfirmationModal = false;
            },
            getReactionImage(reaction) {
                return this.reactions.find(x => x.value === reaction).img;
            }
        },
        computed: {
            sectionReactions() {
                return this.$store.state.reviewMode.review.sectionReactions;
            },
            hasReaction() {
                return this.sectionReaction.reaction || this.sectionReaction.comments.length
            },
            reviewMode() {
                return !this.$store.state.reviewMode.receiver
            },
            sortedComments() {
                return [...this.sectionReaction.comments].sort((a, b) => {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                });
            },
            savedDataIndex() {
                return this.sectionReactions.findIndex(x => x.sectionId === this.section._id)
            },
            isReactionExists() {
                return this.sectionReactions.find(x => x.sectionId === this.section._id)
            },
            sectionReaction() {
                let reactionGroup = this.sectionReactions.find(x => x.sectionId === this.section._id);
                return reactionGroup ? reactionGroup : this.defaultSectionReaction
            }
        }
    }
</script>

<style lang="scss">
    //sidebar templates
    .profile_theme_four {
        .review-fabs {
            &.hero {
                top: 20px !important;
            }
        }
    }

    .profile_theme_nine {
        .review-fabs {
            &.hero {
                top: -300px !important;
                right: -20px !important;
            }
        }
    }

    .profile_theme_default {
        .review-fabs {
            &.hero {
                top: -60px !important;
                right: 0 !important;
            }
        }
    }
</style>

<style scoped lang="scss">
    .review-fabs {
        position: absolute;
        right: 24px;
        top: 10px;
        z-index: 2;
        height: 90%;

        transition-property: transform;
        transition-timing-function: ease-in-out;
        transition-duration: 100ms;

        //Case study hero
        &.MainHeader {
            top: 100px;
        }

        &.hero {
            top: 100px;
            height: calc(100% - 100px);
        }

        .sticky {
            display: flex;
            position: sticky;
            top: 75px;
            gap: 8px;
        }

        .ufo-fab {
            min-width: 48px;
            width: auto;
            border-radius: 6px;
            border: 1px solid var(--asphalt90);
        }

        .reaction-fab-container {
            position: relative;

            .reaction-in-fab {
                font-size: 18px;

                .reaction-wrapper {
                    display: flex;
                    width: 24px;
                    height: 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    flex-shrink: 0;

                    img {
                        width: 20px;
                        height: 20px;
                        flex-shrink: 0;
                    }
                }
            }

            .reactions-container {
                position: absolute;
                right: 0;
                top: 55px;

                padding: 4px;
                display: flex;
                gap: 2px;
                border-radius: 8px;
                border: 1px solid var(--asphalt90);
                background: var(--asphalt95);
                box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.10);

                .reaction {
                    display: flex;
                    width: 56px;
                    height: 56px;
                    padding: 8px;
                    gap: 10px;
                    border-radius: 6px;
                    align-items: center;
                    justify-content: center;

                    &.selected {
                        background-color: var(--asphalt70);
                    }

                    p {
                        margin: 0;
                    }

                    &:hover {
                        background-color: var(--asphalt70);
                        cursor: pointer;
                    }

                    .reaction-wrapper {
                        display: flex;
                        width: 24px;
                        height: 24px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        flex-shrink: 0;

                        img {
                            width: 20px;
                            height: 20px;
                            flex-shrink: 0;
                        }
                    }
                }
            }
        }

        .comments-fab-container {
            position: relative;

            :deep(.ufo-fab) {
                border-radius: 6px;
            }


            .comment-in-fab {
                display: flex;
                padding: 0 12px;
                justify-content: center;
                align-items: center;
                gap: 8px;

                span {
                    color: var(--asphalt10);
                    font: var(--editor-ui-b3-medium)
                }

                .placeholder-image {
                    display: flex;
                    width: 28px;
                    height: 28px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 36px;
                    background: var(--asphalt30);

                    p {
                        margin: 0;
                        color: var(--asphalt70);
                        font: var(--editor-ui-l-semibold);
                    }
                }
            }

            .comments-container {
                position: absolute;
                right: 0;
                top: 55px;

                display: flex;
                width: 400px;
                flex-direction: column;
                align-items: center;

                border-radius: 8px;
                border: 1px solid var(--asphalt90);
                background: var(--asphalt95);
                box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.10);
                z-index: 3;

                .header-row {
                    display: flex;
                    border-bottom: 1px solid var(--asphalt90);
                    width: 100%;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: 16px 20px;

                    svg {
                        width: 20px;
                        height: 20px;

                        &:hover {
                            cursor:pointer;
                        }
                    }

                    font: var(--editor-ui-b1-semibold);
                    color: var(--asphalt10);
                }

                .comments {
                    display: flex;
                    max-height: 360px;
                    flex-direction: column;
                    align-items: center;
                    align-self: stretch;
                    border-bottom: 1px solid var(--asphalt90);
                    overflow: auto;
                    overscroll-behavior: contain;

                    ::-webkit-scrollbar-track {
                        background-color: transparent; /* Makes the track transparent */
                    }

                    scrollbar-color: #888 transparent; /* Thumb color and track color */
                }
            }
        }
    }
</style>