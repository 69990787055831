<template>
    <div class="option-bar-group-container">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "OptionBarGroup",
}
</script>

<style scoped>


.option-bar-group-container {
        padding: 4px;
        gap: 8px;
        background: var(--asphalt90);
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
}
</style>