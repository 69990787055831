import axios from 'axios';
import Dropzone from 'dropzone';

Dropzone.autoDiscover = false;

export default {
    getDropzone(dropzoneConfig) {
        return new Dropzone(dropzoneConfig.documentElementId, {
            url: dropzoneConfig.url,
            path: dropzoneConfig.path,
            paramName: dropzoneConfig.paramName ? dropzoneConfig.paramName : "imageFile",
            autoProcessQueue: typeof dropzoneConfig.autoProcessQueue !== 'undefined' ? dropzoneConfig.autoProcessQueue : true,
            uploadMultiple: dropzoneConfig.uploadMultiple ? dropzoneConfig.uploadMultiple : false,
            parallelUploads: dropzoneConfig.parallelUploads ? dropzoneConfig.parallelUploads : 2,
            maxFiles: dropzoneConfig.maxFiles ? dropzoneConfig.maxFiles : 1,
            maxFilesize: dropzoneConfig.maxFilesize ? dropzoneConfig.maxFilesize : 5,
            queueLimit: dropzoneConfig.queueLimit ? dropzoneConfig.queueLimit : 1,
            acceptedFiles: dropzoneConfig.acceptedFiles ? dropzoneConfig.acceptedFiles : ".jpg, .jpeg, .png, .svg, .gif",
            dictDefaultMessage: dropzoneConfig.dictDefaultMessage ? dropzoneConfig.dictDefaultMessage : '<p class="dz-default-message">Drop Your image here or <span class="blue">click to upload</span>.</p>',
            dictFileTooBig: dropzoneConfig.dictFileTooBig ? dropzoneConfig.dictFileTooBig : "This file is too large ({{filesize}} MB). The size limit is {{maxFilesize}} MB.",
            dictInvalidFileType: "Upload canceled: This file format is not supported. <br /> Use " + (dropzoneConfig.acceptedErrorMessage ? dropzoneConfig.acceptedErrorMessage : "png, jpeg, svg or gif") + ".",
            dictMaxFilesExceeded: "You can not upload more than {{maxFiles}} files.",
            previewTemplate: dropzoneConfig.previewTemplate ? dropzoneConfig.previewTemplate : '<div class="dz-preview dz-image-preview"><div class="dz-image"><img data-dz-thumbnail /></div><div class="dz-details"><div class="dz-size"><span data-dz-size=""></span></div><div class="dz-filename"><span data-dz-name=""></span></div></div><div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress=""></span></div><div class="dz-error-message"><span data-dz-errormessage=""></span></div><div class="dz-success-mark"></div><div class="dz-error-mark"></div></div>',
            thumbnailWidth: dropzoneConfig.thumbnailWidth ? dropzoneConfig.thumbnailWidth : null,
            thumbnailHeight: dropzoneConfig.thumbnailHeight ? dropzoneConfig.thumbnailHeight : 250,
            headers: {
                'X-CSRF-TOKEN': axios.defaults.headers.common['X-CSRF-TOKEN']
            },
            init() {
                this.on("thumbnail", function (file) {
                    if(file.size < 5030000 && (file.width > 3000 || file.height > 3000)) {
                        document.querySelector('.slow-upload-warning').innerHTML = `${!dropzoneConfig.uploadMultiple ? 'This image seems' : 'The images seem'} a bit big. Uploading can take several seconds.`
                    }
                });
                this.on("maxfilesexceeded", function (file) {
                    document.querySelector('.slow-upload-warning').innerHTML = ""
                    this.removeFile(file);
                });
                this.on("error", function (file, errormessage, xhr) {
                    document.querySelector('.slow-upload-warning').innerHTML = ""
                    this.removeFile(file);
                    if (!file.accepted || xhr) this.removeFile(file);
                    if(dropzoneConfig.onErrorCallback) { dropzoneConfig.onErrorCallback(xhr, errormessage); }
                });
                this.on("addedfiles", function(files) {
                    if(dropzoneConfig.onAddedFilesCallback) { dropzoneConfig.onAddedFilesCallback(files); }
                });
            },
            accept(imageFile, done) {
                done();
                if(dropzoneConfig.onAcceptCallback) { dropzoneConfig.onAcceptCallback(); }
            },
            sending(file, xhr, formData) {
                formData.append('path', dropzoneConfig.path);
                if(dropzoneConfig.onSendingCallback) { dropzoneConfig.onSendingCallback(formData); }
            },
            success(imageFile, response) {
                document.querySelector('.slow-upload-warning').innerHTML = ""
                imageFile.previewElement.classList.add("dz-success");
                if(dropzoneConfig.onSuccessCallback) { dropzoneConfig.onSuccessCallback(response); }
            },
            successmultiple(files, response) {
                if(dropzoneConfig.onSuccessMultipleCallback) { dropzoneConfig.onSuccessMultipleCallback(response); }
            }
        });
    }
}
