<template>
    <div class="new-modal-body" :class="[modalSize, textAlign]">
        <slot></slot>
    </div>
</template>

<script>

export default {
    name: 'modal-body',
    inject: ['modalSize'],
    props: {
        textAlign:{
            type: String,
            default: 'left',    //  left or center (only on small modal-size)
        }

    }
}
</script>

<style lang="scss" scoped>
.new-modal-body {
    overflow: hidden;
    flex: 1;
    height: 100%;

    &.small {
        padding: 0 32px;
        font: var(--editor-ui-b1-regular);
        letter-spacing: 0.16px;
        color: var(--modal-description);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        &.center {
            text-align: center;
        }
    }
}
</style>
