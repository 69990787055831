<template>
    <div :class="['ufo--dd-select', {dark: darkScheme}]" tabindex="1" @blur="showOptions = false">
        <label v-if="label" :class="{open : showOptions}">{{ label }}</label>
        <div class="ufo--dd-selector">
            <div :class="['ufo--dd-selected', {open : showOptions} ]" @click="toggleDropDown()">
                <div class="user-value">
                    <img class="profile-picture" alt="userimage" v-if="selected.image && selected.image.url" :src="selected.image.url"/>
                    <UserImagePlaceholder v-else :name="selected.name" size="small" />

                    <div class="user-details">
                        <div class="user-name">{{ selected.name }}</div>
                        <div class="user-job-title">{{ selected.jobTitle }}</div>
                    </div>
                </div>
                <Icon icon-name="dropdown-select" />
            </div>
            <transition name="drop-down">
                <div v-if="showOptions" class="ufo--dd-options">
                    <div v-for="option in options"
                         :key="option"
                         @click="selectOption(option)"
                         :class="['ufo--dd-option', {selected : selected === option.email}]"
                    >
                        <div class="user-value">
                            <img class="profile-picture" alt="userimage" v-if="option.image && option.image.url" :src="option.image.url"/>
                            <UserImagePlaceholder v-else :name="option.name" size="small" />
                            <div class="user-details">
                                <div class="user-name">{{ option.name }}</div>
                                <div class="user-job-title">{{ option.jobTitle }}</div>
                            </div>
                        </div>
                        <Icon v-if="selected === option.email" icon-name="checkmark" />
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
import dropdownSelectIcon from "../../../images/general-icons/dropdown-select.svg"
import Icon from "../Icon.vue";
import UserImagePlaceholder from "../../common/components/UserImagePlaceholder.vue";


export default {
    name: 'UserSelectDropdown',
    inheritAttrs: false,
    components: {
        UserImagePlaceholder,
        Icon
    },
    props: {
        label: {
            type: String,
            default: null
        },
        selected: {
            type: Object,
            default: null
        },
        options: {
            type: Array,
            default: null
        },
        darkScheme: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showOptions: false,
            dropdownSelectIcon: dropdownSelectIcon,
        }
    },
    methods: {
        toggleDropDown() {
            this.showOptions = !this.showOptions
        },
        selectOption(option) {
            this.showOptions = false;
            this.$emit('click', option);
        },
    }
}
</script>

<style lang="scss" scoped>
.ufo--dd-select {
    --labelColor: var(--asphalt50);
    --selectColor: var(--asphalt60);
    --selectBackgroundColor: var(--asphalt20);
    --selectBackgroundHoverColor: var(--asphalt30);
    --activeColor: var(--asphalt100);
    --optionsBackgroundColor: var(--asphalt10);
    --optionsBackgroundBorderColor: var(--asphalt20);
    --itemColor: var(--asphalt100);
    --itemBackgroundHoverColor: var(--asphalt20);
    --optionsShadow: var(--shadow-light-small);

    &.dark{
        --labelColor: var(--asphalt40);
        --selectColor: var(--asphalt40);
        --selectBackgroundColor: var(--asphalt90);
        --selectBackgroundHoverColor: var(--asphalt80);
        --activeColor: var(--asphalt10);
        --optionsBackgroundColor: var(--asphalt80);
        --optionsBackgroundBorderColor: var(--asphalt70);
        --itemColor: var(--asphalt10);
        --itemBackgroundHoverColor: var(--asphalt70);
        //--optionsShadow: var(--shadow-dark-small);
    }

    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    font: var(--editor-ui-b2-regular);

    //User desing
    .user-value {
        display: flex;
        align-items: center;
        gap: 12px;

        img {
            width: 36px;
            height: 36px;
            border-radius: 36px;
        }

        .user-details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 2px;

            .user-name {
                font: var(--editor-ui-b2-medium);
                color: var(--asphalt20);
            }

            .user-job-title {
                font: var(--editor-ui-b3-medium);
                color: var(--asphalt40);
            }
        }
    }

    label {
        font: var(--editor-ui-b2-regular);
        color: var(--labelColor);
        margin-bottom: 16px;
    }

    .ufo--dd-selector {
        width: 100%;
        position: relative;
        text-transform: capitalize;
    }
    .ufo--dd-selected {
        font-size: 14px;
        color: var(--selectColor);
        background-color: var(--selectBackgroundColor);
        padding: 12px;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 48px;

        &:hover {
            background-color: var(--selectBackgroundHoverColor);
        }

        svg {
            margin-left: 10px;
            transition: transform 100ms ease-in-out;
            width: 24px;
            height: 24px;
        }

        &.open {
            color: var(--activeColor);
            outline: 1px solid var(--asphalt80);
            &:hover {
                background-color: var(--selectBackgroundColor);
            }
            svg {
                transform: rotate(180deg);
            }
        }
    }

    .ufo--dd-options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        max-height: 180px;
        border: 1px solid var(--optionsBackgroundBorderColor);

        overflow: auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        overscroll-behavior: contain;

        /* Hide scrollbar for Chrome, Safari, and Opera */
        &::-webkit-scrollbar {
            display: none;
        }

        position: absolute;
        left: 0;
        top: 68px;
        z-index: 1;
        background: var(--optionsBackgroundColor);

        border-radius: 4px;
        box-shadow: var(--optionsShadow);

        .ufo--dd-option {
            padding: 14px 16px;
            cursor: pointer;
            width: 100%;
            color: var(--itemColor);
            display: flex;
            justify-content: space-between;

            &:hover {
                background-color: var(--itemBackgroundHoverColor);
            }
            &.selected {
                font: var(--editor-ui-b2-medium);
            }

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
}
</style>