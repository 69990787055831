import {FontRepositoryTypes} from "./enums.js";
import GoogleFontsHelper from "../../common/GoogleFontsHelper.js";
import SettingsFontAPI from "../../api/settings/SettingsFontAPI.js";

export function fontLoader() {
    SettingsFontAPI.getFonts("").then(fonts => {
        checkFonts(fonts.data)
    });
}

export async function specificFontsLoader(textStyles) {
    let fonts = [...new Set(textStyles.map((style) => style.font))];
    await loadFontsByName(fonts);
}

export async function loadFontsByName(fonts) {
    let fontsRes = await SettingsFontAPI.getFonts("?names=" + fonts.join(","))
    checkFonts(fontsRes.data)
}

export function checkFonts(fonts) {
    let sheet = window.document.styleSheets[0];
    fonts.forEach((font) => {
        if(font.repositoryType === FontRepositoryTypes.UNIQUE_REPOSITORY) {
            font.attributes.forEach((type) => {
                fontFileLoader(font, type, sheet);
            })
        }
        else {
            GoogleFontsHelper.getFontUrlWithParams(font.name, font.attributes);
        }
    })
}

function fontFileLoader(font, type, sheet) {
    let fontWithType = font.fonts.find((font) => font.type.toLowerCase() === type);

    if(fontWithType) {
        let type = fontWithType.type.toLowerCase();

        if(!!document.querySelector(`link[href="${fontWithType.url}"]`)) return;

        let preLoad = document.createElement('link');
        preLoad.rel="preload"
        preLoad.href=fontWithType.url
        preLoad.as="font"
        preLoad.crossOrigin="anonymous"
        document.head.appendChild(preLoad);

        if (type === "light") {
            sheet.insertRule("@font-face {font-family:" + font.name + "; src:url(" + fontWithType.url + "); font-weight: 300;}", sheet.cssRules.length);
        } else if (type === "regular") {
            sheet.insertRule("@font-face {font-family:" + font.name + "; src:url(" + fontWithType.url + "); font-weight: 400;}", sheet.cssRules.length);
        } else if (type === "medium") {
            sheet.insertRule("@font-face {font-family:" + font.name + "; src:url(" + fontWithType.url + "); font-weight: 500;}", sheet.cssRules.length);
        }
        //bold and italic
        else if (type === "bold") {
            sheet.insertRule("@font-face {font-family:" + font.name + "; src:url(" + fontWithType.url + "); font-weight: 700;}", sheet.cssRules.length);
        } else if (type === "semibold") {
                sheet.insertRule("@font-face {font-family:" + font.name + "; src:url(" + fontWithType.url + "); font-weight: 600;}", sheet.cssRules.length);
        } else if (type === "italic") {
            sheet.insertRule("@font-face {font-family:" + font.name + "; src:url(" + fontWithType.url + "); font-style: italic;}", sheet.cssRules.length);
        }
        //cross with italic
        else if (type.includes("bold") && type.includes("italic")) {
            sheet.insertRule("@font-face {font-family:" + font.name + "; src:url(" + fontWithType.url + "); font-weight: 700; font-style: italic}", sheet.cssRules.length);
        } else if (type.includes("semibold") && type.includes("italic")) {
            sheet.insertRule("@font-face {font-family:" + font.name + "; src:url(" + fontWithType.url + "); font-weight: 600; font-style: italic}", sheet.cssRules.length);
        } else if (type.includes("medium") && type.includes("italic")) {
            sheet.insertRule("@font-face {font-family:" + font.name + "; src:url(" + fontWithType.url + "); font-weight: 500; font-style: italic}", sheet.cssRules.length);
        } else if (type.includes("light") && type.includes("italic")) {
            sheet.insertRule("@font-face {font-family:" + font.name + "; src:url(" + fontWithType.url + "); font-weight: 300; font-style: italic}", sheet.cssRules.length);
        }
        else {
            //default
        }
    }
}