<template>
    <div class="navbar-toggle">
        <div class="hamburger-icon" @click="handleClick">
            <svg v-if="!$store.state.view.navOpen" :class="['hamburger', 'open', $store.state.view.navOpen ? 'nav-open' : '']" width="48" height="48" viewBox="0 0 48 48" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="color: var(--hero-nav-button-color)">
                <rect x="14" y="16" width="20" height="2.28571" fill="currentColor"/>
                <rect x="14" y="22.8571" width="20" height="2.28571" fill="currentColor"/>
                <rect x="14" y="29.7143" width="20" height="2.28571" fill="currentColor"/>
            </svg>
            <svg v-else :class="['hamburger', 'close', $store.state.view.navOpen ? 'nav-open' : '']" width="48" height="48" viewBox="0 0 48 48" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="color: var(--hero-nav-button-color)">
                <path d="M33 16.8129L31.1871 15L24 22.1871L16.8129 15L15 16.8129L22.1871 24L15 31.1871L16.8129 33L24 25.8129L31.1871 33L33 31.1871L25.8129 24L33 16.8129Z" fill="currentColor"/>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HamburgerToggle",
        methods: {
            handleClick() {
                this.$store.state.view.navOpen = !this.$store.state.view.navOpen
            }
        }
    }
</script>

<style lang="scss" scoped>
// navigation toggle button on mobile
.navbar-toggle {
    @media (max-width: 768px) {
        position: relative;
        z-index: 11;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        margin-left: auto;

        &.on {
            svg {
                display: block;
            }
        }
    }
    display: none;
}
</style>