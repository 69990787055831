<template>
    <ToggleGrid/>
    <UserThemeCoverImage v-if="portfolio && portfolio.image.url"/>
    <UserImage v-else />
    <div class="profile-short-cv">
        <UserPortfolioDescription v-if="portfolio && portfolio.description"/>
    </div>
</template>

<script>
    import UserThemeCoverImage from "../../common/user/UserThemeCoverImage.vue";
    import UserImage from "../../common/user/UserImage.vue";
    import UserPortfolioDescription from "../../common/user/UserPortfolioDescription.vue";
    import ToggleGrid from "../../../../common/components/ToggleGrid.vue";

    export default {
        name: "ProfileThemeDefault",
        components: {ToggleGrid, UserPortfolioDescription, UserImage, UserThemeCoverImage},
        computed: {
            portfolio() {
                return this.$store.state.view.portfolio
            }
        }
    }
</script>