import DefaultTextStylesHelper from "./DefaultTextStylesHelper.js";
import {ThumbnailLayoutsEnum} from "../enums.js";
import {defaultThumbnailData, thumbnailDesignData} from "./ThumbnailLayouts.js";
import {heroStyleData} from "./HeroLayouts.js";
import {defaultCtaButtonStyle, ctaButtonStyle} from "./CtaButtonStyle.js";
import {defaultNavigationData, navigationData} from "./NavigationLayoutAndStyle.js";
import {dividerLineSettings} from "./DividerLineSettings.js";
import {footerSettings} from "./FooterSettings.js";
import {defaultImages} from "./DefaultImages.js";
import {getDefaultHeroText} from "./DefaultHeroTexts.js";

export function getDefaultThemeStyles(userName) {
    return [
        {
            name: 'theme_eighteen',
            title:'Tesler',
            order: 18,
            hidden: false,
            newTheme:true,
            image:'template_18-tesler.webp',
            fonts: ["Inter", "Inter"],
            introText: getDefaultHeroText(userName)['theme_eighteen'],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_eighteen'),
            settings: {
                projectTitle: {visibility: "visible", placement: "on-thumbnail", alignment: {horizontal: "left", vertical: "center"}, color: "dark"},
                showProfilePicture: false,
                theme: "theme_eighteen",
                tileDisplayMode: "large",
                defaultCaseStudyValues: {
                    backgroundColor:'#0B0C0D',
                    hoverColor: '#F9FAFB',
                    //TODO: removed default values for activation, but keep it here for examples
                    //heroHeight: "550",
                    //heroBackgroundColor: '25272D',
                    //defaultForegroundImage: true,
                    //TODO: this is probably not needed here, because we can get it from the styles, but we always use white elsewhere
                    navButtonColor:'#8F94A3',
                    navShadow:false,
                },
                logo: {
                    image: {
                        type: "Image",
                        url: "/template-default-images/logos/theme_18-logo.svg",
                        width: 40,
                        height: 40
                    },
                    visible: true
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_eighteen']},
                thumbnailLayout:ThumbnailLayoutsEnum.LARGE_BLOCK,
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_eighteen']},
                hero: heroStyleData['theme_eighteen'],
                navigation: {...defaultNavigationData, ...navigationData['theme_eighteen']},
                dividerLineSettings: dividerLineSettings['theme_eighteen'],
                footerSettings: footerSettings['theme_eighteen'],
                defaultImages: defaultImages['theme_eighteen'],
            }
        },
        {
            name: 'theme_seventeen',
            title:'Aurora',
            order: 17,
            hidden: false,
            newTheme:true,
            image:'template_17-aurora.webp',
            fonts: ["Merchant", "Open Sans"],
            introText: getDefaultHeroText(userName)['theme_seventeen'],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_seventeen'),
            settings: {
                projectTitle: {visibility: "hover", placement: "on-thumbnail", alignment: {horizontal: "center", vertical: "center"}, color: "dark"},
                showProfilePicture: false,
                theme: "theme_seventeen",
                tileDisplayMode: "large",
                defaultCaseStudyValues: {
                    backgroundColor:'#F5F4FF',
                    hoverColor: '#3D2A8F',
                    //heroHeight: "400",
                    //heroBackgroundColor: 'DEDBFA',
                    //defaultForegroundImage: true,
                    //TODO: this is probably not needed here, because we can get it from the styles, but we always use white elsewhere
                    navButtonColor:'#33303F',
                    navShadow:false,
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_seventeen']},
                thumbnailLayout:ThumbnailLayoutsEnum.LARGE_BLOCK_MIXED,
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_seventeen']},
                hero: heroStyleData['theme_seventeen'],
                navigation: {...defaultNavigationData, ...navigationData['theme_seventeen']},
                dividerLineSettings: dividerLineSettings['theme_seventeen'],
                footerSettings: footerSettings['theme_seventeen'],
                defaultImages: defaultImages['theme_seventeen'],
            }
        },
        {
            name: 'theme_sixteen',
            title:'Norman',
            order: 16,
            hidden: false,
            newTheme:true,
            image:'template_16-norman.webp',
            fonts: ["Outfit","Poppins"],
            introText: getDefaultHeroText(userName)['theme_sixteen'],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_sixteen'),
            settings: {
                projectTitle: {
                    visibility: "visible",
                    placement: "below",
                    alignment: {
                        horizontal: "center",
                        vertical: "center"
                    },
                    color: "dark"
                },
                showProfilePicture: true,
                theme: "theme_sixteen",
                tileDisplayMode: "small",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_sixteen']},
                thumbnailLayout: ThumbnailLayoutsEnum.SMALL_BLOCK_SLIDED,
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_sixteen']},
                hero: heroStyleData['theme_sixteen'],
                navigation: {...defaultNavigationData, ...navigationData['theme_sixteen']},
                dividerLineSettings: dividerLineSettings['theme_sixteen'],
                footerSettings: footerSettings['theme_sixteen'],
                defaultImages: defaultImages['theme_sixteen'],
            }
        },
        /*
        {
            name: 'theme_fifteen',
            title:'Miller',
            hidden: true,
            newTheme:true,
            image:'template_15.webp',
            fonts: ["ClashDisplay","ClashGrotesk"],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_fifteen'),
            settings: {
                projectTitle: {
                    visibility: "visible",
                    placement: "right",
                    alignment: {
                        horizontal: "left",
                        vertical: "center"
                    },
                    color: "dark"
                },
                showProfilePicture: false,
                theme: "theme_fifteen",
                tileDisplayMode: "small",
                dividerLineSettings: {
                    text_position: "below",
                    text_alignment: "left",
                    weight: "weight-light",
                },
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                ctaStyle: {
                    fillStyle: "solid",
                    shape: "square",
                    backgroundColor: "#353738",
                    stroke: null,
                    align: "left",
                    textStyle: {
                        type: "ctaButton",
                        font: "ClashDisplay",
                        size: 14,
                        color: "#FFFFFF",
                        lineHeight: 17,
                        fontWeight: 500,
                        fontStyle:"normal"
                    }
                },
                hero: {
                    height: 670,
                    textAlign: "left",
                    image: {
                        height: "670",
                        type: "Image",
                        url: "/hero-images/template15-hero-image.jpg",
                        width: "1440",
                    }
                }
            },
            introText: `<h1>Hi, I’m a human centred designer creating intuitive experiences.</h1><p></p><p>I’m a digital designer based in LA. Passionate about building human centred experiences that make a lasting impact on everyday life. </p>`,
        },
         */
        {
            name: 'theme_fourteen',
            title: 'Jakob',
            order: 15,
            newTheme: false,
            image: 'template_14-jakob.webp',
            fonts: ["Bricolage Grotesque", "Inter"],
            introText: getDefaultHeroText(userName)['theme_fourteen'],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_fourteen'),
            settings: {
                projectTitle: { visibility: "visible", placement: "left", alignment: { horizontal: "left", vertical: "center" }, color: "dark" },
                showProfilePicture: false,
                theme: "theme_fourteen",
                tileDisplayMode: "small",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_fourteen']},
                thumbnailLayout: ThumbnailLayoutsEnum.SMALL_BLOCK_TEXT,
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_fourteen']},
                hero: heroStyleData['theme_fourteen'],
                navigation: {...defaultNavigationData, ...navigationData['theme_fourteen']},
                dividerLineSettings: dividerLineSettings['theme_fourteen'],
                footerSettings: footerSettings['theme_fourteen'],
                defaultImages: defaultImages['theme_fourteen'],
            }
        },
        {
            name: 'theme_thirteen',
            title: 'Nelson',
            order: 5,
            newTheme: false,
            image: 'template_13-nelson.webp',
            fonts: ["Albert Sans", "Albert Sans"],
            introText: getDefaultHeroText(userName)['theme_thirteen'],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_thirteen'),
            settings: {
                projectTitle: { visibility: "visible", placement: "below", alignment: { horizontal: "left", vertical: "center" }, color: "dark" },
                showProfilePicture: false,
                theme: "theme_thirteen",
                tileDisplayMode: "large",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_thirteen']},
                thumbnailLayout: ThumbnailLayoutsEnum.LARGE_BLOCK_MIXED,
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_thirteen']},
                hero: heroStyleData['theme_thirteen'],
                navigation: {...defaultNavigationData, ...navigationData['theme_thirteen']},
                dividerLineSettings: dividerLineSettings['theme_thirteen'],
                footerSettings: footerSettings['theme_thirteen'],
                defaultImages: defaultImages['theme_thirteen'],
            },
            socialLinks: [
                {
                    type: "LinkedIn",
                    url: "https://www.linkedin.com"
                },
                {
                    type: "Dribbble",
                    url: "https://dribbble.com"
                },
                {
                    type: "Behance",
                    url: "https://www.behance.net"
                },
                {
                    type: "Facebook",
                    url: "https://www.facebook.com"
                } ]
        },
        {
            name: 'theme_twelve',
            title: 'Otis',
            order: 14,
            newTheme: false,
            image: 'template_12-otis.webp',
            fonts: ["General Sans", "Gambetta"],
            introText: getDefaultHeroText(userName)['theme_twelve'],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_twelve'),
            settings: {
                projectTitle: {visibility: "hidden", placement: "on-thumbnail", alignment: {horizontal: "center", vertical: "center"}, color: "dark"},
                showProfilePicture: false,
                theme: "theme_twelve",
                tileDisplayMode: "small",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_twelve']},
                thumbnailLayout: ThumbnailLayoutsEnum.SMALL_BLOCK,
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_twelve']},
                hero: heroStyleData['theme_twelve'],
                navigation: {...defaultNavigationData, ...navigationData['theme_twelve']},
                dividerLineSettings: dividerLineSettings['theme_twelve'],
                footerSettings: footerSettings['theme_twelve'],
                defaultImages: defaultImages['theme_twelve'],
            }
        },
        {
            name: 'theme_eleven',
            title: 'Bauhaus',
            order: 12,
            image: 'template_11-bauhaus.webp',
            fonts: ["DM Sans", "DM Sans"],
            introText: getDefaultHeroText(userName)['theme_eleven'],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_eleven'),
            settings: {
                projectTitle: {visibility: "visible", placement: "below", alignment: {horizontal: "left", vertical: "center"}, color: "dark"},
                showProfilePicture: false,
                theme: "theme_eleven",
                tileDisplayMode: "small",
                defaultCaseStudyValues: {
                    backgroundColor:'#F7F6F2',
                    navButtonColor:'#FF3637',
                    hoverColor:'#ff4f50',
                    //heroHeight: "600",
                    //heroBackgroundColor: 'ECEBE7'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_eleven']},
                thumbnailLayout: ThumbnailLayoutsEnum.SMALL_BLOCK,
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_eleven']},
                hero: heroStyleData['theme_eleven'],
                navigation: {...defaultNavigationData, ...navigationData['theme_eleven']},
                dividerLineSettings: dividerLineSettings['theme_eleven'],
                footerSettings: footerSettings['theme_eleven'],
                defaultImages: defaultImages['theme_eleven'],
            }
        },
        {
            name: 'theme_ten',
            title: 'Orielle',
            order: 11,
            image: 'template_10-orielle.webp',
            fonts: ["Space Grotesk","Work Sans"],
            introText: getDefaultHeroText(userName)['theme_ten'],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_ten'),
            settings: {
                projectTitle: {visibility: "visible", placement: "below", alignment: {horizontal: "left", vertical: "center"}, color: "dark"},
                showProfilePicture: true,
                theme: "theme_ten",
                tileDisplayMode: "small",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_ten']},
                thumbnailLayout: ThumbnailLayoutsEnum.SMALL_BLOCK,
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_ten']},
                hero: heroStyleData['theme_ten'],
                navigation: {...defaultNavigationData, ...navigationData['theme_ten']},
                dividerLineSettings: dividerLineSettings['theme_ten'],
                footerSettings: footerSettings['theme_ten'],
                defaultImages: defaultImages['theme_ten'],
            }
        },
        {
            name: 'profile_theme_nine',
            title: 'Anthra',
            order: 2,
            image: 'template_09-anthra.webp',
            fonts: ["Roundo", "Karla"],
            introText: getDefaultHeroText(userName)['profile_theme_nine'],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_nine'),
            settings: {
                projectTitle: {visibility: "visible", placement: "below", alignment: {horizontal: "center", vertical: "center"}, color: "dark"},
                showProfilePicture: true,
                theme: "profile_theme_nine",
                tileDisplayMode: "small",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF',
                    //heroBackgroundColor: '262f34'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['profile_theme_nine']},
                thumbnailLayout: ThumbnailLayoutsEnum.SMALL_BLOCK,
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['profile_theme_nine']},
                hero: heroStyleData['profile_theme_nine'],
                navigation: {...defaultNavigationData, ...navigationData['profile_theme_nine']},
                dividerLineSettings: dividerLineSettings['profile_theme_nine'],
                footerSettings: footerSettings['profile_theme_nine'],
                defaultImages: defaultImages['profile_theme_nine'],
            }
        },
        {
            name: 'theme_eight',
            title: 'Nominee',
            order: 13,
            image: 'template_08-nominee.webp',
            fonts: ["Inter", "Inter"],
            introText: getDefaultHeroText(userName)['theme_eight'],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_eight'),
            settings: {
                projectTitle: {visibility: "hidden", placement: null, alignment: {horizontal: null, vertical: null}, color: "dark"},
                showProfilePicture: false,
                theme: "theme_eight",
                tileDisplayMode: "small",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_eight']},
                thumbnailLayout: ThumbnailLayoutsEnum.SMALL_BLOCK,
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_eight']},
                hero: heroStyleData['theme_eight'],
                navigation: {...defaultNavigationData, ...navigationData['theme_eight']},
                dividerLineSettings: dividerLineSettings['theme_eight'],
                footerSettings: footerSettings['theme_eight'],
                defaultImages: defaultImages['theme_eight'],
            }
        },
        {
            name: 'theme_seven',
            title: 'Uncurled',
            order: 10,
            image: 'template_07-uncurled.webp',
            fonts: ["Gambetta", "Inter"],
            introText: getDefaultHeroText(userName)['theme_seven'],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_seven'),
            settings: {
                projectTitle: {visibility: "visible", placement: "below", alignment: {horizontal: "center", vertical: "center"}, color: "dark"},
                showProfilePicture: false,
                theme: "theme_seven",
                tileDisplayMode: "large",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_seven']},
                thumbnailLayout: ThumbnailLayoutsEnum.LARGE_BLOCK,
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_seven']},
                hero: heroStyleData['theme_seven'],
                navigation: {...defaultNavigationData, ...navigationData['theme_seven']},
                dividerLineSettings: dividerLineSettings['theme_seven'],
                footerSettings: footerSettings['theme_seven'],
                defaultImages: defaultImages['theme_seven'],
            }
        },
        {
            name: 'theme_six',
            title: 'Poster',
            order: 9,
            image: 'template_06-poster.webp',
            fonts: ["STIX Two Text", "Switzer"],
            introText: getDefaultHeroText(userName)['theme_six'],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_six'),
            settings: {
                projectTitle: {visibility: "hidden", placement: null, alignment: {horizontal: null, vertical: null}, color: "dark"},
                showProfilePicture: true,
                theme: "theme_six",
                tileDisplayMode: "large",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_six']},
                thumbnailLayout: ThumbnailLayoutsEnum.LARGE_BLOCK_MIXED,
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_six']},
                hero: heroStyleData['theme_six'],
                navigation: {...defaultNavigationData, ...navigationData['theme_six']},
                dividerLineSettings: dividerLineSettings['theme_six'],
                footerSettings: footerSettings['theme_six'],
                defaultImages: defaultImages['theme_six']
            }
        },
        {
            name: 'theme_five',
            title: 'Panorama',
            order: 8,
            image: 'template_05-panorama.webp',
            fonts: ["ClashDisplay", "Inter"],
            introText: getDefaultHeroText(userName)['theme_five'],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_five'),
            settings: {
                projectTitle: {visibility: "visible", placement: "below", alignment: {horizontal: "left", vertical: "center"}, color: "dark"},
                showProfilePicture: false,
                theme: "theme_five",
                tileDisplayMode: "small",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_five']},
                thumbnailLayout: ThumbnailLayoutsEnum.SMALL_BLOCK,
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_five']},
                hero: heroStyleData['theme_five'],
                navigation: {...defaultNavigationData, ...navigationData['theme_five']},
                dividerLineSettings: dividerLineSettings['theme_five'],
                footerSettings: footerSettings['theme_five'],
                defaultImages: defaultImages['theme_five'],
            }
        },
        {
            name: 'profile_theme_four',
            title: 'Cubic',
            order: 4,
            image: 'template_04-cubic.webp',
            fonts: ["Outfit", "Poppins"],
            introText: getDefaultHeroText(userName)['profile_theme_four'],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_four'),
            settings: {
                projectTitle: {visibility: "visible", placement: "below", alignment: {horizontal: "left", vertical: "center"}, color: "dark"},
                showProfilePicture: true,
                theme: "profile_theme_four",
                tileDisplayMode: "small",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['profile_theme_four']},
                thumbnailLayout: ThumbnailLayoutsEnum.SMALL_BLOCK,
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['profile_theme_four']},
                hero: heroStyleData['profile_theme_four'],
                navigation: {...defaultNavigationData, ...navigationData['profile_theme_four']},
                dividerLineSettings: dividerLineSettings['profile_theme_four'],
                footerSettings: footerSettings['profile_theme_four'],
                defaultImages: defaultImages['profile_theme_four'],
            }
        },
        {
            name: 'theme_three',
            title: 'Axis',
            order: 6,
            image: 'template_03-axis.webp',
            fonts: ["ClashDisplay", "Inter"],
            introText: getDefaultHeroText(userName)['theme_three'],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_three'),
            settings: {
                projectTitle: {visibility: "hover", placement: "on-thumbnail", alignment: {horizontal: "center", vertical: "middle"}, color: "dark"},
                showProfilePicture: true,
                theme: "theme_three",
                tileDisplayMode: "large",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_three']},
                thumbnailLayout: ThumbnailLayoutsEnum.LARGE_BLOCK,
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_three']},
                hero: heroStyleData['theme_three'],
                navigation: {...defaultNavigationData, ...navigationData['theme_three']},
                dividerLineSettings: dividerLineSettings['theme_three'],
                footerSettings: footerSettings['theme_three'],
                defaultImages: defaultImages['theme_three'],
            }
        },
        {
            name: 'theme_two',
            title: 'Alaine',
            order: 3,
            image: 'template_02-alaine.webp',
            fonts: ["Roundo", "Karla"],
            introText: getDefaultHeroText(userName)['theme_two'],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_two'),
            settings: {
                projectTitle: {visibility: "visible", placement: "right", alignment: {horizontal: "left", vertical: "middle"}, color: "dark"},
                showProfilePicture: true,
                theme: "theme_two",
                tileDisplayMode: "small",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_two']},
                thumbnailLayout: ThumbnailLayoutsEnum.MEDIUM_BLOCK_TEXT,
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_two']},
                hero: heroStyleData['theme_two'],
                navigation: {...defaultNavigationData, ...navigationData['theme_two']},
                dividerLineSettings: dividerLineSettings['theme_two'],
                footerSettings: footerSettings['theme_two'],
                defaultImages: defaultImages['theme_two'],
            }
        },
        {
            name: 'theme_one',
            title: 'Twee',
            order: 7,
            image: 'template_01-twee.webp',
            fonts: ["IBM Plex Mono", "IBM Plex Sans"],
            introText: getDefaultHeroText(userName)['theme_one'],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_one'),
            settings: {
                projectTitle: {visibility: "visible", placement: "on-thumbnail", alignment: {horizontal: "left", vertical: "center"}, color: "dark"},
                showProfilePicture: false,
                theme: "theme_one",
                tileDisplayMode: "large",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_one']},
                thumbnailLayout: ThumbnailLayoutsEnum.LARGE_BLOCK,
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_one']},
                hero: heroStyleData['theme_one'],
                navigation: {...defaultNavigationData, ...navigationData['theme_one']},
                dividerLineSettings: dividerLineSettings['theme_one'],
                footerSettings: footerSettings['theme_one'],
                defaultImages: defaultImages['theme_one'],
            }
        },
        {
            name: 'profile_theme_default',
            title: 'Baze',
            order: 1,
            image: 'template_00-baze.webp',
            fonts: ["Bricolage Grotesque", "Karla"],
            introText: getDefaultHeroText(userName)['profile_theme_default'],
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_default'),
            settings: {
                projectTitle: {visibility: "visible", placement: "below", alignment: {horizontal: "left", vertical: "center"}, color: "dark"},
                showProfilePicture: true,
                theme: "profile_theme_default",
                tileDisplayMode: "small-under",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['profile_theme_default']},
                thumbnailLayout: ThumbnailLayoutsEnum.SMALL_BLOCK,
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['profile_theme_default']},
                hero: heroStyleData['profile_theme_default'],
                navigation: {...defaultNavigationData, ...navigationData['profile_theme_default']},
                dividerLineSettings: dividerLineSettings['profile_theme_default'],
                footerSettings: footerSettings['profile_theme_default'],
                defaultImages: defaultImages['profile_theme_default'],
            }
        },
        ///////////////////////////////////////////
        ///////////////////////////////////////////
        // HIDDEN / OLD / LEGACY TEMPLATES BELOW //
        ///////////////////////////////////////////
        ///////////////////////////////////////////
        {
            name: 'profile_theme_one',
            title: 'Twee',
            hidden: true,
            image: 'template_01.jpg',
            fonts: {headline: "Montserrat", text: "Noto Serif"},
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_one'),
            settings: {
                projectTitle: {visibility: "visible", placement: "below", alignment: {horizontal: "center", vertical: "center"}, color: "dark"},
                showProfilePicture: false,
                theme: "profile_theme_one",
                tileDisplayMode: "large",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_one']},
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_one']},
                hero: heroStyleData['theme_one'],
                navigation: {...defaultNavigationData, ...navigationData['theme_one']},
                dividerLineSettings: dividerLineSettings['theme_one'],
                footerSettings: footerSettings['theme_one']
            }
        },
        {
            name: 'profile_theme_two',
            title: 'Alaine',
            hidden: true,
            image: 'template_02.jpg',
            fonts: {headline: "Work Sans", text: "Work Sans"},
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_two'),
            settings: {
                projectTitle: {visibility: "visible", placement: "alternating", alignment: {horizontal: "left", vertical: "middle"}, color: "dark"},
                showProfilePicture: true,
                theme: "profile_theme_two",
                tileDisplayMode: "medium-alternating",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_two']},
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_two']},
                hero: heroStyleData['theme_two'],
                navigation: {...defaultNavigationData, ...navigationData['theme_two']},
                dividerLineSettings: dividerLineSettings['theme_two'],
                footerSettings: footerSettings['theme_two']
            }
        },
        {
            name: 'profile_theme_three',
            title: 'Axis',
            hidden: true,
            image: 'template_03.jpg',
            fonts: {headline: "Raleway", text: "Mulish"},
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_three'),
            settings: {
                projectTitle: {visibility: "visible", placement: "on-thumbnail", alignment: {horizontal: "left", vertical: "middle"}, color: "dark"},
                showProfilePicture: true,
                theme: "profile_theme_three",
                tileDisplayMode: "large",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_three']},
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_three']},
                hero: heroStyleData['theme_three'],
                navigation: {...defaultNavigationData, ...navigationData['theme_three']},
                dividerLineSettings: dividerLineSettings['theme_three'],
                footerSettings: footerSettings['theme_three']
            },
            introText: `<h1>${userName ? userName : 'Your name'}</h1><h2>UX designer</h2><p></p><p>I’m a UX designer. I’m passionate about creating usable digital products. I have worked with incredibly talented people across different companies.</p>`
        },
        {
            name: 'profile_theme_five',
            title: 'Panorama',
            hidden: true,
            image: 'template_05.jpg',
            fonts: {headline: "Muli", text: "Muli"},
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_five'),
            settings: {
                projectTitle: {visibility: "hidden", placement: null, alignment: {horizontal: null, vertical: null}, color: "light"},
                showProfilePicture: true,
                theme: "profile_theme_five",
                tileDisplayMode: "small",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_five']},
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_five']},
                hero: heroStyleData['theme_five'],
                navigation: {...defaultNavigationData, ...navigationData['theme_five']},
                dividerLineSettings: dividerLineSettings['theme_five'],
                footerSettings: footerSettings['theme_five']
            }
        },
        {
            name: 'profile_theme_six',
            title: 'Poster',
            hidden: true,
            image: 'template_06.jpg',
            fonts: {headline: "Gothic A1", text: "Gothic A1"},
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_six'),
            settings: {
                projectTitle: {visibility: "visible", placement: "left", alignment: {horizontal: "left", vertical: "middle"}, color: "dark"},
                showProfilePicture: true,
                theme: "profile_theme_six",
                tileDisplayMode: "small",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_six']},
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_six']},
                hero: heroStyleData['theme_six'],
                navigation: {...defaultNavigationData, ...navigationData['theme_six']},
                dividerLineSettings: dividerLineSettings['theme_six'],
                footerSettings: footerSettings['theme_six']
            },
            introText: `<h2>Hello. My name is ${userName ? userName : ''} and I am a UX designer. I’m passionate about creating usable digital products. I have worked with incredibly talented people across different companies.</h2>`

        },
        {
            name: 'profile_theme_seven',
            title: 'Uncurled',
            hidden: true,
            image: 'template_07.jpg',
            fonts: {headline: "Poppins", text: "IBM Plex Mono"},
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_seven'),
            settings: {
                projectTitle: {visibility: "visible", placement: "on-thumbnail", alignment: {horizontal: "right", vertical: "middle"}, color: "dark"},
                showProfilePicture: false,
                theme: "profile_theme_seven",
                tileDisplayMode: "large",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_seven']},
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_seven']},
                hero: heroStyleData['theme_seven'],
                navigation: {...defaultNavigationData, ...navigationData['theme_seven']},
                dividerLineSettings: dividerLineSettings['theme_seven'],
                footerSettings: footerSettings['theme_seven']
            }
        },
        {
            name: 'profile_theme_eight',
            title: 'Nominee',
            hidden: true,
            image: 'template_08.jpg',
            fonts: {headline: "Mulish", text: "Mulish"},
            designSettings: DefaultTextStylesHelper.getDesignSettingsByTheme('theme_eight'),
            settings: {
                projectTitle: {visibility: "hidden", placement: null, alignment: {horizontal: null, vertical: null}, color: "dark"},
                showProfilePicture: false,
                theme: "profile_theme_eight",
                tileDisplayMode: "small",
                defaultCaseStudyValues: {
                    backgroundColor:'#FFFFFF'
                },
                defaultThumbnailData: {...defaultThumbnailData, ...thumbnailDesignData['theme_eight']},
                ctaStyle: {...defaultCtaButtonStyle, ...ctaButtonStyle['theme_eight']},
                hero: heroStyleData['theme_eight'],
                navigation: {...defaultNavigationData, ...navigationData['theme_eight']},
                dividerLineSettings: dividerLineSettings['theme_eight'],
                footerSettings: footerSettings['theme_eight']
            },
            introText: `<h1>Hi there! I’m ${userName ? userName : 'Your name'} 👋</h1><p>I’m passionate about creating usable digital products. I have worked with incredibly talented people across different companies.</p>`
        },
    ].sort(({order:a}, {order:b}) => b-a);
}