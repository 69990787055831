<template>
    <div class="new-modal-header-wrapper" :class="[{'has-tabs': hasTabPillsSlot, 'dark': darkScheme}, modalSize, variant]">
        <div class="new-modal-header">
            <div class="new-modal-title">
                {{ title }}
            </div>
            <Cta v-if="modalSize !== 'small'" :dark-scheme="darkScheme" :type="'icon subtle no-bg'" @click="closeModal()">
                <Icon icon-name="close" alt="Close modal"/>
            </Cta>
        </div>

        <div v-if="description" class="new-modal-description" v-html="description" />

        <div v-if="hasTabPillsSlot" class="new-modal-tabs-container">
            <slot name="tabPills"></slot>
        </div>
    </div>
</template>

<script>
import Icon from "../../common/Icon.vue";
import Cta from "../../common/components/CtaButton.vue";

export default {
    name: 'modal-header',
    components: {Cta, Icon},
    inject: ['modalSize'],
    props: {
        title: {
            default: null,
            type: String
        },
        description: {
            default: null,
            type: String
        },
        darkScheme: {
            default: false,
            type:Boolean
        },
        variant: {
            type: String,   //  "section-settings"
            default: null,
        }
    },
    methods: {
        closeModal() {
            this.$parent.$emit('close');
        },
    },
    computed: {
        hasTabPillsSlot() {
            return !!this.$slots.tabPills
        },
    },
}
</script>

<style lang="scss" scoped>
.new-modal-header-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border-bottom: 1px solid var(--modal-border);

    .new-modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .new-modal-title {
            font: var(--editor-ui-h2);
            color: var(--modal-title);
        }
    }
    .new-modal-description {
        font: var(--editor-ui-b2-regular);
        color: var(--modal-description);
        letter-spacing: 0.14px;
    }
    .new-modal-tabs-container {
        width: 100%;
    }

    &.has-tabs {
        padding: 24px 24px 0;
    }

    &.small {
        padding: 32px 32px 16px 32px;
        border-bottom: none;
        .new-modal-header {
            justify-content: center;
            text-align: center;
            .new-modal-title {
                font: var(--editor-ui-h1);
            }
            .new-modal-description {
                font: var(--editor-ui-b1-regular);
                letter-spacing: 0.16px;
            }
        }
    }

    &.section-settings {
        .new-modal-title {
            font: var(--editor-ui-h3);
        }
    }

}
</style>
