<!-- This component is very similar to ReviewFabs, but only for displaying data
     There were to many different branches for the logic, so for more readability I moved this out
     Loaded for Reciever mode (Owner checks reviews) and Submitted mode (Reviewer checks their review, gives followup but they can't edit)
 -->
<template>
    <div :id="section._id" v-if="$store.state.reviewMode.displayUI" :class="['review-fabs', section.type]" :style="{zIndex:invertedIndex}">
        <div class="sticky">
            <div v-if="Object.values(sectionReactions).length" ref="reaction" class="reaction-fab-container">
                <fab :key="key" v-for="(value, key) in sectionReactions" :icon="null" :tooltip="getTooltip(key, value.reviewers)" :tooltip-position="'top'" >
                    <template #reaction>
                        <div class="reaction-in-fab-container">
                            <div class="reaction-wrapper">
                                <img :src="getReactionImage(key)" alt="reaction"/>
                            </div>
                            <span v-if="value.number > 1">{{value.number}}</span>
                        </div>
                    </template>
                </fab>
            </div>
            <div ref="comments" v-if="sectionComments.length" class="comments-fab-container">
                <fab :icon="null" @click="showComments" tooltip="Comments" :tooltip-position="'top'">
                    <template #comment>
                        <div class="comment-in-fab">
                            <Icon :icon-name="'chat'" />
                            <span>{{sectionComments.length}}</span>
                        </div>
                    </template>
                </fab>
                <div class="comments-container" v-if="comments">
                    <div class="header-row">
                        <div>Comments</div>
                        <Icon iconName="close" @click="showComments"/>
                    </div>
                    <div ref="commentList" class="comments">
                        <Comment
                            v-bind:key="comment.label"
                            v-for="(comment, index) in sortedComments"
                            :comment="comment"
                            :edit="false"
                            :index="index"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Fab from "../../../../common/components/Fab.vue";
import Icon from "../../../../common/Icon.vue";
import Comment from "./../Comment.vue";
import thumbsUp from "../../../../../images/review/emojis/thumbs-up.png";
import heart from "../../../../../images/review/emojis/heart.png";
import clap from "../../../../../images/review/emojis/clap.png";
import bulb from "../../../../../images/review/emojis/light-bulb.png";
import checkMark from "../../../../../images/review/emojis/check-mark.png";

export default {
    name: "ReceiverFabs",
    props:['section', 'hovering', 'invertedIndex'],
    components: {
        Fab,
        Icon,
        Comment
    },
    data() {
        return {
            comments:false,
            show:false,
            listenerMounted:false,
            reactions: [
                {name:'like', value:'&#128077', img:thumbsUp},
                {name:'heart', value:'&#128150', img:heart},
                {name:'clap', value:'&#128079', img:clap},
                {name:'bulb', value:'&#128161', img:bulb},
                {name:'check', value:'&#x2705', img:checkMark},
            ],
            sectionReaction: {
                sectionId:null,
                reaction:null,
                comments:[],
                pageData:{
                    shortId:null,
                    name:null
                }
            },
        }
    },
    mounted() {
        this.emitter.on('open-comment', (sectionId) => {
            this.comments = sectionId === this.section._id;
        });
    },
    methods: {
        showComments() {
            this.comments = !this.comments;
            this.updating = null;
            this.mountOutsideClick();
        },
        mountOutsideClick() {
            if(this.comments && !this.listenerMounted) {
                this.listenerMounted = true;
                document.addEventListener('click', this.clickOutside, true);
            } else {
                this.listenerMounted = false;
                document.removeEventListener('click', this.clickOutside, true);
            }
        },
        clickOutside() {
            if (this.comments && this.$refs.comments && !this.$refs.comments.contains(event.target)) {
                this.showComments();
            }

        },
        getTooltip(reaction, reviewers) {
            return reviewers.join(", ") + " reacted with :" + this.reactions.find(x => x.value === reaction).name + ":"
        },
        getReactionImage(reaction) {
            return this.reactions.find(x => x.value === reaction).img;
        }
    },
    computed: {
        sectionReactions() {
            let allReactions = {};
            //This monster
            this.$store.state.reviewMode.reviews.forEach(review => {
                review.sectionReactions.forEach(reactionGroup => {
                    if(reactionGroup.sectionId === this.section._id && reactionGroup.reaction != null) {
                        if(allReactions.hasOwnProperty(reactionGroup.reaction)) {
                            allReactions[reactionGroup.reaction].number += 1
                            allReactions[reactionGroup.reaction].reviewers.push(review.reviewerDetails.name)
                        }
                        else {
                            allReactions[reactionGroup.reaction] = {
                                number:1,
                                reviewers:[review.reviewerDetails.name]
                            }
                        }
                    }
                })
            })

            return allReactions;
        },
        sectionComments() {
            let allComments =  this.allReviewsReactions.filter(x => x.sectionId === this.section._id).map(y => y.comments).flat();
            return allComments
        },
        allReviewsReactions() {
            let allReviews = this.$store.state.reviewMode.reviews.map(review => review.sectionReactions).flat();
            return allReviews;
        },
        sortedComments() {
            return [...this.sectionComments].sort((a, b) => {
                return new Date(a.createdAt) - new Date(b.createdAt);
            });
        }
    }
}
</script>

<style scoped lang="scss">
    .review-fabs {
        position: absolute;
        right: 24px;
        top: 10px;
        z-index: 2;
        height: 90%;

        transition-property: transform;
        transition-timing-function: ease-in-out;
        transition-duration: 100ms;

        //Case study hero
        &.MainHeader {
            top: 100px;
        }

        &.hero {
            top: 100px;
            height: calc(100% - 100px);
        }

        .sticky {
            display: flex;
            position: sticky;
            top: 75px;
            gap: 8px;
        }

        .ufo-fab {
            min-width: 48px;
            width: auto;
            border-radius: 6px;
            border: 1px solid var(--asphalt90);

        }

        .reaction-fab-container {
            display: flex;
            flex-direction: row-reverse;
            gap: 12px;

            .reaction-in-fab-container {
                display: flex;
                padding: 0 12px;
                justify-content: center;
                align-items: center;
                gap: 8px;

                .reaction-wrapper {
                    display: flex;
                    width: 24px;
                    height: 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    flex-shrink: 0;

                    img {
                        width: 20px;
                        height: 20px;
                        flex-shrink: 0;
                    }
                }

                span {
                    color: var(--asphalt10);
                    font: var(--editor-ui-b3-medium)
                }
            }
        }

        .comments-fab-container {
            position: relative;

            .comment-in-fab {
                display: flex;
                padding: 0 12px;
                justify-content: center;
                align-items: center;
                gap: 8px;

                span {
                    color: var(--asphalt10);
                    font: var(--editor-ui-b3-medium)
                }

                .placeholder-image {
                    display: flex;
                    width: 28px;
                    height: 28px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 36px;
                    background: var(--asphalt30);

                    p {
                        margin: 0;
                        color: var(--asphalt70);
                        font: var(--editor-ui-l-semibold);
                    }
                }
            }

            .comments-container {
                position: absolute;
                right: 0;
                top: 55px;

                display: flex;
                width: 400px;
                flex-direction: column;
                align-items: center;

                border-radius: 8px;
                border: 1px solid var(--asphalt90);
                background: var(--asphalt95);
                box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.10);
                z-index: 3;

                .header-row {
                    display: flex;
                    border-bottom: 1px solid var(--asphalt90);
                    width: 100%;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: 16px 20px;

                    svg {
                        width: 20px;
                        height: 20px;

                        &:hover {
                            cursor:pointer;
                        }
                    }

                    font: var(--editor-ui-b1-semibold);
                    color: var(--asphalt10);
                }

                .comments {
                    display: flex;
                    max-height: 360px;
                    flex-direction: column;
                    align-items: center;
                    align-self: stretch;
                    border-bottom: 1px solid var(--asphalt90);
                    overflow: auto;
                    overscroll-behavior: contain;

                    ::-webkit-scrollbar-track {
                        background-color: transparent; /* Makes the track transparent */
                    }

                    scrollbar-color: #888 transparent; /* Thumb color and track color */

                }
            }
        }
    }
</style>