<template>
    <button
        :class="[{'selected' : selected, 'disabled': disabled, }, size]"
    >
        <div v-if="icon" class="picto">
            <icon :icon-name="icon"/>
        </div>
        <div v-if="label" class="option-label">{{ label }}</div>
    </button>
</template>

<script>
import Icon from "../Icon.vue";

export default {
    name: "OptionBar",
    components: {Icon},
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        size: {
            type:String, //small, medium, large //TODO: large is not implemented, default is medium
            default:'medium'
        }
    },
}
</script>

<style lang="scss" scoped>
button {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font: var(--editor-ui-btn-l-default);
    color: var(--asphalt40);
    cursor: pointer;
    padding: 6px;
    margin: 0;
    width: 100%;
    border-radius: 2px;
    height: 32px;

    &.small {
        height: 28px;
        font: var(--editor-ui-btn-l-small);

    }

    .picto {
        width: auto;
        height: 100%;
    }

    &:focus {
        outline: none;
    }
    &:hover {
        color: var(--asphalt10);
    }
    &.selected {
        background-color: var(--asphalt60);
        color: var(--asphalt10);
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
    }
    &.disabled {
        opacity: .4;
        pointer-events: none;
        cursor: not-allowed;
    }
    svg {
        width: 100%;
        height: 100%;
    }
}
</style>