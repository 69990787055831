export function getDefaultHeroText(userName = "Your name") {
    return {
        //Tesler
        theme_eighteen: `<h1>I’m ${userName}, UX designer</h1><p>Contact me here if you want to collaborate or check my work down below.</p>`,
        //Aurora
        theme_seventeen: `<h1>${userName}</h1><p>Product designer and researcher</p>`,
        //Norman
        theme_sixteen: `<p>${userName}, product designer</p><h1>Transforming user experiences with intuitive design</h1>`,
        //Jakob
        theme_fourteen: `<h1>${userName}, UX professional based in Berlin, Germany</h1><p>A multidisciplinary designer with expertise in branding, UX/Ul and Product design. I developed my skills working on various projects from small studios through to award-winning agencies, startups and software houses.</p>`,
        //Nelson
        theme_thirteen: "<h1>A product designer with a mission to design memorable products that make life easier.</h1>",
        //Otis
        theme_twelve: `<h1>I’m ${userName}, a UX designer from Paris, creating interfaces for Facebook.</h1><p>Contact me here if you want to collaborate or check my work down below.</p>`,
        //Bauhaus
        theme_eleven: `<p>Hey there!</p><h1>I’m ${userName}, an interaction designer based in Buenos Aires.</h1>`,
        //Orielle
        theme_ten: `<p>Hey there!</p><h1>I’m ${userName}, a UX designer based in NYC.</h1>`,
        //Anthra
        profile_theme_nine: `<h1>${userName}</h1><p>I’m a UX designer. I’m passionate about creating usable digital products. I have worked with incredibly talented people across different companies.</p>`,
        //Nominee
        theme_eight: `<h1>Hi, I’m ${userName}</h1><p>I’m passionate about creating usable digital products. I have worked with incredibly talented people across different companies.</p>`,
        //Uncurled
        theme_seven: `<h1>I’m ${userName}, senior interaction designer based in Toronto.</h1>`,
        //Poster
        theme_six: `<h1>${userName}</h1><p>I’m a UX designer. I’m passionate about creating usable digital products. I have worked with incredibly talented people across different companies.</p>`,
        //Panorama
        theme_five: `<h1>I’m ${userName}, junior product designer based in Brooklyn.</h1>`,
        //Cubic
        profile_theme_four: `<h1>${userName}</h1><p>I’m a UX designer. I’m passionate about creating usable digital products. I have worked with incredibly talented people across different companies.</p>`,
        //Axis
        theme_three: `<h1>Hey, I’m ${userName}.</h1><p>Hey there, I’m a UX Architect. My biggest passion is utilizing my analytical and creative sides to deliver digital sites from concepts to the launch itself.</p>`,
        //Alaine
        theme_two: `<h1>${userName}, junior designer based in Brooklyn.</h1>`,
        //Twee
        theme_one: `<h1>${userName}</h1><p>I’m a UX designer. I’m passionate about creating usable digital products. I have worked with incredibly talented people across different companies.</p>`,
        // Baze
        profile_theme_default: `<h1>${userName}</h1><p>I’m a UX designer. I’m passionate about creating usable digital products. I have worked with incredibly talented people across different companies.</p>`,
    }
}