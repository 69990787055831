// LIGHT PALETTES
// template remaster 2024.07.
export const ColorPaletteGraphite = Object.freeze({
    ID: 'graphite',
    NAME: 'Graphite',
    IMAGE: 'graphite',
    TYPE: 'light',
    COLORS: {
        HEADING: '#000000',
        PARAGRAPH: '#787878',
        NAVIGATION: '#787878',
        NAVIGATION_HOVER: '#000000',
        BUTTON_FILL: '#505050',
        BUTTON_TEXT: '#FFFFFF',
        HYPERLINK: '#4C4CFF',
        BACKGROUND: '#FFFFFF',
        FOREGROUND: '#EBEBEB',
        DIVIDER_LINE: '#C0C0C0'
    }
})

export const ColorPaletteCoal = Object.freeze({
    ID: 'coal',
    NAME: 'Coal',
    IMAGE: 'coal',
    TYPE: 'light',
    COLORS: {
        HEADING: '#000000',
        PARAGRAPH: '#000000',
        NAVIGATION: '#000000',
        NAVIGATION_HOVER: '#787878',
        BUTTON_FILL: '#000000',
        BUTTON_TEXT: '#FFFFFF',
        HYPERLINK: '#4C4CFF',
        BACKGROUND: '#FFFFFF',
        FOREGROUND: '#E1E1E1',
        DIVIDER_LINE: '#000000'
    }
})

export const ColorPaletteAsphalt = Object.freeze({
    ID: 'asphalt',
    NAME: 'Asphalt',
    IMAGE: 'asphalt',
    TYPE: 'light',
    COLORS: {
        HEADING: '#1E1C1B',
        PARAGRAPH: '#727272',
        NAVIGATION: '#727272',
        NAVIGATION_HOVER: '#1E1C1B',
        BUTTON_FILL: '#D5D1CF',
        BUTTON_TEXT: '#1E1C1B',
        HYPERLINK: '#4C4CFF',
        BACKGROUND: '#F1F1F1',
        FOREGROUND: '#FFFFFF',
        DIVIDER_LINE: '#D4D4D4'
    }
})

export const ColorPaletteConcrete = Object.freeze({
    ID: 'concrete',
    NAME: 'Concrete',
    IMAGE: 'concrete',
    TYPE: 'light',
    COLORS: {
        HEADING: '#646464',
        PARAGRAPH: '#646464',
        NAVIGATION: '#646464',
        NAVIGATION_HOVER: '#646464',
        BUTTON_FILL: '#646464',
        BUTTON_TEXT: '#D7D7D7',
        HYPERLINK: '#3A3AE4',
        BACKGROUND: '#D7D7D7',
        FOREGROUND: '#C3C3C3',
        DIVIDER_LINE: '#646464'
    }
})

export const ColorPaletteIndigo = Object.freeze({
    ID: 'indigo',
    NAME: 'Indigo',
    IMAGE: 'indigo',
    TYPE: 'light',
    COLORS: {
        HEADING: '#3A405A',
        PARAGRAPH: '#848292',
        NAVIGATION: '#848292',
        NAVIGATION_HOVER: '#3A405A',
        BUTTON_FILL: '#3A405A',
        BUTTON_TEXT: '#F8E9DD',
        HYPERLINK: '#2A304A',
        BACKGROUND: '#F8E9DD',
        FOREGROUND: '#DACEC8',
        DIVIDER_LINE: '#ACA5A9'
    }
})

export const ColorPaletteChestnut = Object.freeze({
    ID: 'chestnut',
    NAME: 'Chestnut',
    IMAGE: 'chestnut',
    TYPE: 'light',
    COLORS: {
        HEADING: '#3D312A',
        PARAGRAPH: '#7C7069',
        NAVIGATION: '#7C7069',
        NAVIGATION_HOVER: '#3D312A',
        BUTTON_FILL: '#D7C4BA',
        BUTTON_TEXT: '#3D312A',
        HYPERLINK: '#3D312A',
        BACKGROUND: '#E8DDD6',
        FOREGROUND: '#CDC1BB',
        DIVIDER_LINE: '#A49891'
    }
})

export const ColorPaletteCaramel = Object.freeze({
    ID: 'caramel',
    NAME: 'Caramel',
    IMAGE: 'caramel',
    TYPE: 'light',
    COLORS: {
        HEADING: '#5C5651',
        PARAGRAPH: '#B58D56',
        NAVIGATION: '#B58D56',
        NAVIGATION_HOVER: '#5C5651',
        BUTTON_FILL: '#B58D56',
        BUTTON_TEXT: '#F5F4EB',
        HYPERLINK: '#5C5651',
        BACKGROUND: '#F5F4EB',
        FOREGROUND: '#E2E0D8',
        DIVIDER_LINE: '#B58D56'
    }
})

export const ColorPaletteStamp = Object.freeze({
    ID: 'stamp',
    NAME: 'Stamp',
    IMAGE: 'stamp',
    TYPE: 'light',
    COLORS: {
        HEADING: '#2D1B4D',
        PARAGRAPH: '#2D1B4D',
        NAVIGATION: '#2D1B4D',
        NAVIGATION_HOVER: '#2D1B4D',
        BUTTON_FILL: '#B58D56',
        BUTTON_TEXT: '#2D1B4D',
        HYPERLINK: '#A593C5',
        BACKGROUND: '#E1EFE6',
        FOREGROUND: '#C4CDCE',
        DIVIDER_LINE: '#2D1B4D'
    }
})

export const ColorPaletteBorealis = Object.freeze({
    ID: 'borealis',
    NAME: 'Borealis',
    IMAGE: 'borealis',
    TYPE: 'light',
    COLORS: {
        HEADING: '#2C2669',
        PARAGRAPH: '#817D93',
        NAVIGATION: '#817D93',
        NAVIGATION_HOVER: '#2C2669',
        BUTTON_FILL: '#E5E4F3',
        BUTTON_TEXT: '#2C2669',
        HYPERLINK: '#2C2669',
        BACKGROUND: '#F5F4FF',
        FOREGROUND: '#D5D3E7',
        DIVIDER_LINE: '#A5A2C3'
    }
})

export const ColorPaletteCrimson = Object.freeze({
    ID: 'crimson',
    NAME: 'Crimson',
    IMAGE: 'crimson',
    TYPE: 'light',
    COLORS: {
        HEADING: '#FF000F',
        PARAGRAPH: '#FF000F',
        NAVIGATION: '#FF000F',
        NAVIGATION_HOVER: '#FF000F',
        BUTTON_FILL: '#FF000F',
        BUTTON_TEXT: '#FF000F',
        HYPERLINK: '#FF000F',
        BACKGROUND: '#FFF3E5',
        FOREGROUND: '#FFCCC3',
        DIVIDER_LINE: '#FF000F'
    }
})

// before template remaster
export const ColorPaletteTaupe = Object.freeze({
    ID: 'taupe',
    NAME: 'Taupe',
    IMAGE: 'taupe',
    TYPE: 'light',
    COLORS: {
        HEADING: '#534D46',
        PARAGRAPH: '#6C6760',
        NAVIGATION: '#6C6760',
        BUTTON_FILL: '#9C886F',
        BUTTON_TEXT: '#F6F6F6',
        HYPERLINK: '#9C886F',
        BACKGROUND: '#F6F5F3',
        FOREGROUND: '#E0DDD6',
        DIVIDER_LINE: '#6C6760'
    },
},)

export const ColorPaletteBlueberry = Object.freeze({
    ID: 'blueberry',
    NAME: 'Blueberry',
    IMAGE: 'blueberry',
    TYPE: 'light',
    COLORS: {
        HEADING: '#213BC8',
        PARAGRAPH: '#383830',
        NAVIGATION: '#383830',
        BUTTON_FILL: '#213BC8',
        BUTTON_TEXT: '#F6F6F6',
        HYPERLINK: '#213BC8',
        BACKGROUND: '#F5F5F4',
        FOREGROUND: '#DDDDDA',
        DIVIDER_LINE: '#383830'
    },
},)

export const ColorPaletteClementine = Object.freeze({
    ID: 'clementine',
    NAME: 'Clementine',
    IMAGE: 'clementine',
    TYPE: 'light',
    COLORS: {
        HEADING: '#BD7920',
        PARAGRAPH: '#4E4D4B',
        NAVIGATION: '#4E4D4B',
        BUTTON_FILL: '#BD7920',
        BUTTON_TEXT: '#F6F6F6',
        HYPERLINK: '#BD7920',
        BACKGROUND: '#F4EFE7',
        FOREGROUND: '#E6DCCB',
        DIVIDER_LINE: '#4E4D4B'
    },
},)

export const ColorPaletteOlive = Object.freeze({
    ID: 'olive',
    NAME: 'Olive',
    IMAGE: 'olive',
    TYPE: 'light',
    COLORS: {
        HEADING: '#33332A',
        PARAGRAPH: '#494946',
        NAVIGATION: '#494946',
        BUTTON_FILL: '#4C5334',
        BUTTON_TEXT: '#F6F6F6',
        HYPERLINK: '#4C5334',
        BACKGROUND: '#E8E9E4',
        FOREGROUND: '#CFD1C7',
        DIVIDER_LINE: '#494946'
    },
},)

////////////////////////////////
////////////////////////////////
////////////////////////////////
// DARK PALETTES
////////////////////////////////
////////////////////////////////
////////////////////////////////

// template remaster 2024.07.
export const ColorPaletteObsidian = Object.freeze({
    ID: 'obsidian',
    NAME: 'Obsidian',
    IMAGE: 'obsidian',
    TYPE: 'dark',
    COLORS: {
        HEADING: '#FFFFFF',
        PARAGRAPH: '#B9B9B9',
        NAVIGATION: '#B9B9B9',
        NAVIGATION_HOVER: '#FFFFFF',
        BUTTON_FILL: '#313030',
        BUTTON_TEXT: '#FFFFFF',
        HYPERLINK: '#7575FF',
        BACKGROUND: '#060606',
        FOREGROUND: '#101010',
        DIVIDER_LINE: '#5A5A5A'
    }
})

export const ColorPaletteAbyss = Object.freeze({
    ID: 'abyss',
    NAME: 'Abyss',
    IMAGE: 'abyss',
    TYPE: 'dark',
    COLORS: {
        HEADING: '#FFFFFF',
        PARAGRAPH: '#FFFFFF',
        NAVIGATION: '#FFFFFF',
        NAVIGATION_HOVER: '#FFFFFF',
        BUTTON_FILL: '#FFFFFF',
        BUTTON_TEXT: '#000000',
        HYPERLINK: '#7575FF',
        BACKGROUND: '#000000',
        FOREGROUND: '#141414',
        DIVIDER_LINE: '#FFFFFF'
    }
})

export const ColorPaletteGranite = Object.freeze({
    ID: 'granite',
    NAME: 'Granite',
    IMAGE: 'granite',
    TYPE: 'dark',
    COLORS: {
        HEADING: '#FFFFFF',
        PARAGRAPH: '#FFFFFF',
        NAVIGATION: '#FFFFFF',
        NAVIGATION_HOVER: '#FFFFFF',
        BUTTON_FILL: '#363636',
        BUTTON_TEXT: '#FFFFFF',
        HYPERLINK: '#3B89FF',
        BACKGROUND: '#171A1C',
        FOREGROUND: '#0A0A0A',
        DIVIDER_LINE: '#5A5D5E'
    }
})

export const ColorPaletteEclipse = Object.freeze({
    ID: 'eclipse',
    NAME: 'Eclipse',
    IMAGE: 'eclipse',
    TYPE: 'dark',
    COLORS: {
        HEADING: '#E9FF72',
        PARAGRAPH: '#A3A3A3',
        NAVIGATION: '#A3A3A3',
        NAVIGATION_HOVER: '#E9FF72',
        BUTTON_FILL: '#E9FF72',
        BUTTON_TEXT: '#0A0A0A',
        HYPERLINK: '#E9FF72',
        BACKGROUND: '#0A0A0A',
        FOREGROUND: '#1C1E12',
        DIVIDER_LINE: '#A3A3A3'
    }
})

// before template remaster
export const ColorPaletteSpaceGrey = Object.freeze({
    ID: 'spaceGrey',
    NAME: 'Space grey',
    IMAGE: 'space-grey',
    TYPE: 'dark',
    COLORS: {
        HEADING: '#ECECEE',
        PARAGRAPH: '#A0A0A8',
        NAVIGATION: '#A0A0A8',
        BUTTON_FILL: '#ECECEE',
        BUTTON_TEXT: '#0B0B0D',
        HYPERLINK: '#ECECEE',
        BACKGROUND: '#0B0B0D',
        FOREGROUND: '#232329',
        DIVIDER_LINE: '#494946'
    },
},)

export const ColorPaletteNeon = Object.freeze({
    ID: 'neon',
    NAME: 'Neon',
    IMAGE: 'neon',
    TYPE: 'dark',
    COLORS: {
        HEADING: '#F2F3ED',
        PARAGRAPH: '#BDC3C1',
        NAVIGATION: '#BDC3C1',
        BUTTON_FILL: '#D9E783',
        BUTTON_TEXT: '#131A18',
        HYPERLINK: '#D9E783',
        BACKGROUND: '#131A18',
        FOREGROUND: '#293834',
        DIVIDER_LINE: '#BDC3C1'
    },
},)

export const ColorPalettePurpleHaze = Object.freeze({
    ID: 'purpleHaze',
    NAME: 'Purple haze',
    IMAGE: 'purple-haze',
    TYPE: 'dark',
    COLORS: {
        HEADING: '#F0F0F8',
        PARAGRAPH: '#AAAFC9',
        NAVIGATION: '#AAAFC9',
        BUTTON_FILL: '#5A53AB',
        BUTTON_TEXT: '#F0F0F8',
        HYPERLINK: '#5A53AB',
        BACKGROUND: '#11121B',
        FOREGROUND: '#25283B',
        DIVIDER_LINE: '#AAAFC9'
    },
},)

export const ColorPaletteMoonlight = Object.freeze({
    ID: 'moonlight',
    NAME: 'Moonlight',
    IMAGE: 'moonlight',
    TYPE: 'dark',
    COLORS: {
        HEADING: '#F6E4BF',
        PARAGRAPH: '#CDC9C1',
        NAVIGATION: '#CDC9C1',
        BUTTON_FILL: '#F6E4BF',
        BUTTON_TEXT: '#1F2532',
        HYPERLINK: '#F6E4BF',
        BACKGROUND: '#1F2532',
        FOREGROUND: '#333D52',
        DIVIDER_LINE: '#CDC9C1'
    },
},)

export function getLightColorPalettes() {
    return [
        ColorPaletteGraphite,
        ColorPaletteCoal,
        ColorPaletteAsphalt,
        ColorPaletteConcrete,
        ColorPaletteIndigo,
        ColorPaletteChestnut,
        ColorPaletteCaramel,
        ColorPaletteStamp,
        ColorPaletteBorealis,
        ColorPaletteCrimson,
        ColorPaletteTaupe,
        ColorPaletteBlueberry,
        ColorPaletteClementine,
        ColorPaletteOlive
    ]
}

export function  getDarkColorPalettes() {
    return [
        ColorPaletteObsidian,
        ColorPaletteAbyss,
        ColorPaletteGranite,
        ColorPaletteEclipse,
        ColorPaletteSpaceGrey,
        ColorPaletteNeon,
        ColorPalettePurpleHaze,
        ColorPaletteMoonlight
    ]
}

export function getAllColorPalettes() {
    return [
        ...getLightColorPalettes(),
        ...getDarkColorPalettes()
    ]
}


