<template>
    <ToggleGrid/>
    <div class="profile-short-cv">
        <HamburgerToggle />
        <UserThemeCoverImage v-if="portfolio && portfolio.image.url"/>
        <UserImage v-else />

        <UserPortfolioDescription v-if="portfolio && portfolio.description" />
        <template v-else>
            <UserImage />
            <UserJobTitle />
            <UserDescription />
        </template>
        <GetHeroNavigation :show-home-button="true" />
    </div>
</template>

<script>
    import UserImage from "../../common/user/UserImage.vue";
    import UserJobTitle from "../../common/user/UserJobTitle.vue";
    import UserDescription from "../../common/user/UserDescription.vue";
    import UserThemeCoverImage from "../../common/user/UserThemeCoverImage.vue";
    import UserPortfolioDescription from "../../common/user/UserPortfolioDescription.vue";
    import HamburgerToggle from "../../../components/HamburgerToggle.vue";
    import ToggleGrid from "../../../../common/components/ToggleGrid.vue";
    import GetHeroNavigation from "../../common/hero/GetHeroNavigation.vue";

    export default {
        name: "ProfileThemeFour",
        components: {
            GetHeroNavigation,
            ToggleGrid,
            HamburgerToggle,
            UserPortfolioDescription,
            UserThemeCoverImage,
            UserDescription,
            UserJobTitle,
            UserImage
        },
        mounted() {
            window.onload = this.setScrollClass;
            window.onresize = this.setScrollClass;
        },
        computed: {
            portfolio() {
                return this.$store.state.view.portfolio
            },
        },
        methods: {
            setScrollClass() {
                const e = document.querySelector('.ufo--page-hero-text');
                e.classList.toggle('scrollable', e.scrollHeight > e.clientHeight);
            }
        }
    }
</script>