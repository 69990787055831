<template>
    <div v-if="user.socialLinks || portfolio.socialLinks"
        :class="['social-links',
                !templateSettings.showProfilePicture ? 'full-w' : '',
                (templateSettings.hero && templateSettings.hero.alignment.horizontal) ? templateSettings.hero.alignment.horizontal : '']"
    >
        <template v-bind:key="socialLink" v-for="(socialLink, index) in filteredSocials">
            <AnimationWrapper
                :animation-style="$store.getters.animationSettings && $store.getters.animationSettings.animationStyle !== 'NONE' ? 'FADE_IN' : null"
                :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                :delay-animation="0.6 + index * 0.1"
            >
                <template #animationContent>
                    <a
                       :href="socialLink.url"
                       target="_blank"
                       rel="nofollow noopener"
                       :class="[socialLinkStyle, linkIsTextType ? 'text' : 'icon', getCleanType(socialLink.type).toLowerCase()]"
           :data-text="linkIsTextType ? getCleanType(socialLink.type) : null"
                    >
                        <span v-if="linkIsTextType">{{ getCleanType(socialLink.type) }}</span>
                        <i v-else :class="['fa', 'fa-' + socialLink.type.toLowerCase()]"></i>
                    </a>
                </template>
            </AnimationWrapper>
        </template>
    </div>
</template>

<script>
    import {SocialLinkTypeEnum} from "../../../../common/enums.js";
    import AnimationWrapper from "../../../../common/components/AnimationWrapper.vue";

    export default {
        name: "GetHeroSocialLinks",
        components: {AnimationWrapper},
        methods: {
            getCleanType(linkType) {
                if(linkType.toLowerCase() === "x-twitter") return 'x';
                    return this.portfolio.templateSettings.navigation.navigationItems.button.upperCase
                        ? linkType.toUpperCase()
                        : linkType
            }
        },
        computed: {
            user() {
                return this.$store.state.view.user
            },
            portfolio() {
                return this.$store.state.view.portfolio
            },
            templateSettings() {
                return this.portfolio.templateSettings
            },
            socialLinks() {
                return this.user.socialLinks ? this.user.socialLinks : this.portfolio.socialLinks
            },
            filteredSocials() {
                return this.socialLinks.filter(link => link.url !== "")
            },
            socialLinkStyle() {
                return this.portfolio.templateSettings.navigation.navigationItems.socialLink.style
            },
            linkIsTextType() {
                return this.portfolio.templateSettings.navigation.navigationItems.socialLink.type === SocialLinkTypeEnum.TEXT
            },
        }
    }
</script>

<style lang="scss" scoped>
@import "../../../../../styles/layouts/new-portfolio-layout/components/social-links";
</style>