<template>
    <AnimationWrapper
        :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
        :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
        :is-final-section="true"
    >
        <template #animationContent>
            <div v-if="section.cta && section.cta.show" :class="['ufo--cta-btn-wr', alignment]">
                <a v-if="externalLink()"
                   :href="section.cta.url"
                   :target="[urlIsOwnDomain(this.section.cta.url) ? '_self': '_blank']"
                   rel="nofollow noopener"
                   :class="['btn', 'btn--cta', ctaStyle.fillStyle, ctaStyle.shape]"
                >
                    {{section.cta.title}}
                </a>
                <button v-else
                        :type="formSubmitButton ? 'submit' : ''"
                        :class="['btn', 'btn--cta', ctaStyle.fillStyle, ctaStyle.shape]"
                        @click="section.cta.url ? handleNavigation() : null"
                >
                    {{ section.cta.title }}
                </button>
            </div>
        </template>
    </AnimationWrapper>
</template>

<script>
import AnimationWrapper from "../../../../common/components/AnimationWrapper.vue";

export default {
    name: "CtaButton",
    components: {AnimationWrapper},
    props:{
        'section': {
            required: true
        },
        'formSubmitButton': {
            type:Boolean,
            required: true,
            default:false,
        },
        'alignment': {
            type:String,
            default:''
        }
    },
    methods: {
        handleNavigation() {
            if(this.section.cta.url && !this.section.cta.url.includes('/p/')) {
                this.ctaIntNavigation(this.section.cta.url)
            }
            else {
                this.navigateToProject(this.section.cta.url)
            }
        },
        ctaIntNavigation(page) {
            if(this.portfolio.ownDomainAddress) {
                if(!!page) {
                    window.location.href = window.location.protocol + "//" + this.portfolio.ownDomainAddress + "/" + page;
                } else {
                    window.location.href = window.location.protocol + "//" + this.portfolio.ownDomainAddress
                }
            }
            else {
                let location;
                if(!!page) {
                    if(page.slice(1, page.length) === this.portfolio.shortId) {
                        location = window.location.origin + '/' + this.portfolio.shortId;
                    }
                    else {
                        location = window.location.origin + '/' + this.portfolio.shortId + page;
                    }
                } else {
                    location = window.location.origin + '/' + this.portfolio.shortId;
                }
                window.location.href = location;
            }
        },
        navigateToProject(projectUrl) {
            window.location.href = location.origin + projectUrl;
        },
        externalLink() {
            return !this.formSubmitButton
                && this.section.cta.url
                && (this.section.cta.url.includes('http://') || this.section.cta.url.includes('https://') || this.section.cta.url.includes('mailto:'))
        },
        urlIsOwnDomain(url) {
            return this.portfolio.ownDomainAddress && this.portfolio.ownDomainAddress === url.replace(/^https?\:\/\//i, "");
        }
    },
    computed: {
        project() {
            return this.$store.state.view.project;
        },
        portfolio() {
            return this.$store.state.view.portfolio
        },
        ctaStyle() {
            return this.$store.getters.isCaseStudy ?
                this.$store.state.project.settings.ctaStyle :
                this.$store.state.portfolio.templateSettings.ctaStyle;
        },
    }
}
</script>

<style scoped>

</style>