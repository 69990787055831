<template>
    <div v-if="!templateSettings.theme || templateSettings.theme.includes('profile_')" class="ufo--portfolio-container">
        <div :class="['ufo--portfolio-page',
                      'profile-page',
                      'profile-subpage',
                      templateSettings.theme,
                      'profile--hp',
                      templateSettings.theme === '' && portfolio.about ? 'default-template' : '']"
             :style="[getTextStyles]"
        >
            <div :class="['grid', 'ufo--page-hero', templateSettings.theme]">
                <div :class="['ufo--page-hero-content', templateSettings.theme === 'theme_three' || templateSettings.theme === 'theme_seven' ? 'no-profile-picture' : 'has-picture', contentPosition]">
                    <PortfolioHeroHelper />
                </div>
            </div>
            <div class="ufo--page-content ufo--home-page-content">
                <GetHeroNavigation v-if="templateSettings.theme === 'profile_theme_default'" :show-home-button="templateSettings.theme === 'profile_theme_default'" />
                <div :class="['ufo--about-page', 'ufo--about-page-' + templateSettings.theme === 'profile_theme_default' ? 'default-template' : templateSettings.theme]">
                    <div class="ufo--about-header"
                         v-if="templateSettings.theme !== 'profile_theme_default' &&
                                templateSettings.theme !== 'theme_three' &&
                                templateSettings.theme !== 'profile_theme_four' &&
                                templateSettings.theme !== 'theme_five' &&
                                templateSettings.theme !== 'theme_six' &&
                                 templateSettings.theme !== 'theme_eight'"
                    >
                        <div class="grid">
                            <div class="about-title"><h1 class="title">Contact me</h1></div>
                            <div v-if="templateSettings.theme !== 'profile_theme_four' && templateSettings.theme !== 'theme_seven' && templateSettings.theme !== 'profile_theme_nine'" class="back" @click="navigateToPage()">⟵ Back</div>
                        </div>
                    </div>
                </div>
                <div class="ufo--about-content ufo--page-sections">
                    <section id="section-0" class="ufo-sec ufo-sec--contactform">
                        <div class="grid">
                            <div class="ufo--contact-form">
                                <form class="ufo--form" name="contact_form_section">
                                    <div class="title h4">
                                        Dear {{user.name}},
                                    </div>
                                    <input type="hidden" name="toEmail" :value="user.contactEmail" />
                                    <textarea name="message" type="text" class="form-handler" required placeholder="Write your message here..."></textarea>
                                    <br />
                                    <div class="subtitle h4">
                                        Best,
                                    </div>
                                    <div class="sender">
                                        <input class="s-name form-handler" name="senderName" type="text" required placeholder="Your name" />
                                        <input class="s-mail form-handler" name="senderEmail" type="text" required placeholder="Your email" />
                                    </div>
                                    <div class="ufo--cta-btn-wr">
                                        <button :class="['btn', 'btn--cta', templateSettings.ctaStyle.fillStyle, templateSettings.ctaStyle.shape]">
                                            Send email
                                        </button>
                                    </div>
                                </form>
                                <div class="ufo--form-status-message"></div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
    <div v-else :class="['ufo--portfolio-page', templateSettings.theme]" :style="[getTextStyles]">
        <PortfolioHeroHelper />
        <div :class="['ufo--about-page', 'ufo--about-page-' + templateSettings.theme]">
            <div v-if="templateSettings.theme === 'theme_one' || templateSettings.theme === 'theme_two'" class="ufo--about-header">
                <div class="grid">
                    <div class="about-title">
                        <div class="about-title-container">
                            <h1 class="title">Contact me</h1>
                        </div>
                    </div>
                    <div class="back" @click="navigateToPage()">⟵ Back</div>
                </div>
            </div>
        </div>
        <div class="ufo--page-content ufo--home-page-content">
            <div class="ufo--page-sections">
                <div class="project-wrapper">
                    <section id="section-0" class="ufo-sec ufo-sec--contactform">
                        <div class="grid">
                            <div class="ufo--contact-form">
                                <form class="ufo--form" name="contact_form_section">
                                    <div class="title h4">
                                        Dear {{ user.name }},
                                    </div>
                                    <input type="hidden" name="toEmail" :value="user.contactEmail" />
                                    <textarea name="message" type="text" class="form-handler" required placeholder="Write your message here..."></textarea>
                                    <br />
                                    <div class="subtitle h4">
                                        Best,
                                    </div>
                                    <div class="sender">
                                        <input class="s-name form-handler" name="senderName" type="text" required placeholder="Your name" />
                                        <input class="s-mail form-handler" name="senderEmail" type="text" required placeholder="Your email" />
                                    </div>
                                    <div class="ufo--cta-btn-wr">
                                        <button :class="['btn', 'btn--cta', templateSettings.ctaStyle.fillStyle, templateSettings.ctaStyle.shape]">
                                            Send email
                                        </button>
                                    </div>
                                </form>
                                <div class="ufo--form-status-message"></div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <PublicPageFooter />
    </div>
</template>

<script>
import PortfolioHeroHelper from "../../../view/portfolio/themes/_helper/PortfolioHeroHelper.vue";
import PublicPageFooter from "../../../view/portfolio/common/PublicPageFooter.vue";
import GetHeroNavigation from "../../portfolio/common/hero/GetHeroNavigation.vue";

export default {
    name: "ContactPage",
    components:{
        GetHeroNavigation,
        PublicPageFooter,
        PortfolioHeroHelper
    },
    props:['portfolio', 'projects', 'user', 'themeId'],
    mounted() {
        this.$store.state.view.activePage = 'contact';
    },
    computed: {
        templateSettings() {
            return this.portfolio.templateSettings
        },
        getTextStyles() {
            return this.$store.getters.getAllTextStylesInCss;
        },
        contentPosition() {
            return this.templateSettings.theme === "profile_theme_nine"
                ? `align-content-to-${this.templateSettings.hero.alignment.vertical}`
                : null
        }
    },
    methods: {
        navigateToPage(page) {
            let currentLocation = location.href.replace(location.search, "").replace('/p/', '/');
            if(window.location.pathname.includes('/contact') || (window.location.pathname.includes('/about') || (window.location.pathname.includes('/p/')))
            ){
                window.location = currentLocation.substring(0, currentLocation.lastIndexOf('/')) + (page ? '/' + page : '');
            }
            else {
                window.location = currentLocation + (currentLocation[currentLocation.length - 1] === '/' ? '' : '/') + (page ? page : '');
            }
        }
    }
}
</script>

<style scoped>

</style>