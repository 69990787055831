import ApiHelper from '../ApiHelper.js';

const NotificationsApiUrl = "/api/notifications/";

// COPY object:
// var copy = {
//     _id: String,
//     type: String,
//     title: String,
//     definition: String,
//     example: String,
//     questions: Array[String],
// };

export default {

    getLatestNotificationsForUser: function (userId) {
        return ApiHelper.getWithParams(NotificationsApiUrl + "latest", { userId: userId });
    },

    getAllNotificationForUser: function (userId) {
        return ApiHelper.getWithParams(NotificationsApiUrl + "all", { userId: userId });
    },

    getPaginatedNotification: function (userId, page, perPage) {
        return ApiHelper.getWithParams(NotificationsApiUrl + "paginated", {userId:userId, page:page, perPage:perPage});
    },

    setNotificationSeenBy: function (userId, notificationId) {
        return ApiHelper.put(NotificationsApiUrl + "read", { userId: userId, notificationId: notificationId });
    },

}
