<template>
    <ToggleGrid/>
    <UserName />
    <GetHeroNavigation :show-home-button="true" />
    <div class="profile-short-cv">
        <template v-if="checkPath">
            <UserThemeCoverImage v-if="templateSettings.showProfilePicture" />
            <div>
                <div class="profile-bio">
                    <UserPortfolioDescription />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import UserName from "../../common/user/UserName.vue";
    import UserPortfolioDescription from "../../common/user/UserPortfolioDescription.vue";
    import UserThemeCoverImage from "../../common/user/UserThemeCoverImage.vue";
    import ToggleGrid from "../../../../common/components/ToggleGrid.vue";
    import GetHeroNavigation from "../../common/hero/GetHeroNavigation.vue";

    export default {
        name: "ProfileThemeSeven",
        components: {
            GetHeroNavigation,
            ToggleGrid,
            UserThemeCoverImage,
            UserPortfolioDescription,
            UserName
        },
        computed: {
            portfolio() {
                return this.$store.state.view.portfolio
            },
            templateSettings() {
                return this.$store.state.view.portfolio.templateSettings
            },
            checkPath() {
                return !window.location.pathname.includes('/p/') &&
                    !window.location.pathname.includes('/about') &&
                    !window.location.pathname.includes('/contact')
            },
        }
    }
</script>