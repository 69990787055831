<template>
    <template v-if="portfolio.templateSettings.logo && !notAllowedInThemes.includes(portfolio.templateSettings.theme) && !portfolio.templateSettings.theme.includes('profile_')">
        <AnimationWrapper
            :animation-style="$store.getters.animationSettings && $store.getters.animationSettings.animationStyle !== 'NONE' ? 'FADE_IN' : null"            :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
        >
            <template #animationContent>
                <div v-if="!checkLocation"
                     class="navbar-logo view">
                    <img :src="portfolio.templateSettings.logo.image.url"
                         :height="portfolio.templateSettings.logo.image.height"
                         alt="Portfolio logo">
                </div>
                <a v-else @click="(evt) => homeNavigation(evt)" class="navbar-logo view">
                    <img :src="portfolio.templateSettings.logo.image.url"
                         :height="portfolio.templateSettings.logo.image.height"
                         alt="Portfolio logo">
                </a>
            </template>
        </AnimationWrapper>
    </template>
    <template v-else-if="showOwnerId">
        <template v-if="portfolio.templateSettings.theme !== 'theme_sixteen'">
            <AnimationWrapper
                :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
            >
                <template #animationContent>
                    <div v-if="!checkLocation" class="navbar-id">{{ user.name }}</div>
                    <a v-else @click="(evt) => homeNavigation(evt)" class="navbar-id">{{ user.name }}</a>
                </template>
            </AnimationWrapper>
        </template>
    </template>
</template>

<script>
import AnimationWrapper from "../../../../common/components/AnimationWrapper.vue";

export default {
    name: "GetHeroPortfolioLogo",
    components: {
        AnimationWrapper
    },
    methods: {
        homeNavigation(evt) {
            evt.preventDefault();
            const homeRoute = this.rootIsOwnDomain()
                ? "/"
                : `/${this.portfolio.shortId}`
            this.$router.push(homeRoute)
            this.$store.state.view.navOpen = false;
        },
        rootIsOwnDomain() {
            return this.portfolio.ownDomainAddress && location.origin.includes(this.portfolio.ownDomainAddress);
        },
    },
    computed: {
        user() {
            return this.$store.state.view.user
        },
        portfolio() {
          return this.$store.state.view.portfolio
        },
        notAllowedInThemes() {
            return ['theme_one', 'theme_two', 'theme_three', 'theme_six', 'theme_seven', 'theme_eleven']
        },
        checkLocation() {
            return window.location.pathname.includes('/p/')
        },
        showOwnerId() {
            return this.portfolio.templateSettings.navigation.navigationItems.ownerId.visible
        },
    }
}
</script>