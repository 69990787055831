export default {
    getMoment: function (dateCreated) {
        let tsc = Date.parse(new Date()) - dateCreated; // Time Since Created
        if(tsc < 60*1000) {
            return 'just now';
        } else if (tsc > 60*1000 && tsc < 3600*1000) {
            return Math.round(tsc / (60*1000)) + 'm ago';
        } else if (tsc > 3600*1000 && tsc < 86400*1000) {
            return Math.round(tsc / (6*1000*1000)) + 'h ago';
        } else if (tsc > 86400*1000 && tsc < 172800*1000) {
            return '1 day ago';
        } else {
            return formatDate(dateCreated);
        }
    }
}

function formatDate(dateCreated) {
    const date = new Date(dateCreated);
    const now = new Date();
    const options = { month: 'long', day: 'numeric' }; // Default options for the current year

    // Check if the year of the date is not the current year
    if (date.getFullYear() !== now.getFullYear()) {
        options.year = 'numeric'; // Add year to the options if it's not the current year
    }

    return date.toLocaleDateString('en-US', options);
}
