<template>
    <div class="review-wrapper" v-if="loaded">
        <ReviewNav :review-mode="true" :showOverviewModal="() => this.showOverviewModal = true"/>
        <slot></slot>
        <Overview :review-mode="true" />
        <Transition name="fade-in">
            <TourModal />
        </Transition>
        <!-- Modal for logging in the user -->
        <ReviewerLoginModal :show="showLoginModal" :close="closeLoginModal" :getReview="getReview" />
        <OverviewModal :show="showOverviewModal" :close="closeOverviewModal" :openSuccessModal="openSuccessModal"/>
        <OverviewSuccessModal :show="showSuccessModal" :close="closeSuccessModal"/>
    </div>
</template>

<script>
    import ReviewNav from "./components/ReviewNavigation.vue";
    import Overview from "./components/Overview.vue";
    import TourModal from "./modals/TourModal.vue";
    import ReviewerLoginModal from "./modals/ReviewerLoginModal.vue";
    import ApiHelper from "../../api/ApiHelper.js";
    import {ReviewTypeEnum} from "../../common/enums.js";
    import OverviewModal from "./modals/OverviewModal.vue";
    import UsersAPI from "../../api/users/UserAPI.js";
    import OverviewSuccessModal from "./modals/OverviewSuccessModal.vue";

    export default {
        name: "ReviewMode",
        components: {
            OverviewSuccessModal,
            OverviewModal,
            ReviewerLoginModal,
            TourModal,
            Overview,
            ReviewNav
        },
        data() {
            return {
                showLoginModal:false,
                showOverviewModal:false,
                showSuccessModal:false,
                loaded:false,
                tourDisabled:false
            }
        },
        mounted() {
            this.checkForReviewer();
        },
        methods: {
            async checkForReviewer() {
                try {
                    let response = await UsersAPI.getMyUser();
                    this.$store.state.user = response.data.user;
                    this.$store.state.reviewMode.review.reviewerDetails = {
                        _id: response.data.user._id,
                        name: response.data.user.name,
                        email: response.data.user.email,
                        jobTitle: response.data.user.title,
                        image: response.data.user.image
                    };
                    try {
                        let contentId = this.$store.state.reviewMode.reviewType === ReviewTypeEnum.PORTFOLIO ? this.$store.state.view.portfolio._id : this.$store.state.view.project._id;
                        let encodedEmail = encodeURIComponent(this.$store.state.user.email);
                        await ApiHelper.get("/api/review?reviewer=" + encodedEmail + "&contentId=" + contentId);
                        await this.getReview();
                    }
                    catch (e) {
                        this.loaded = true;
                        this.showLoginModal = true;
                    }

                } catch (e) {
                    console.error('Error in checkForReviewer:', e);
                    this.handleReviewerCheckFailure();
                }
            },
            openSuccessModal() {
                this.showSuccessModal = true;
            },
            closeSuccessModal() {
                this.showSuccessModal = false;
            },
            handleReviewerCheckFailure() {
                let reviewer = this.$cookies.get('reviewer');
                if (reviewer) {
                    this.initiateReviewForCookieReviewer(reviewer);
                } else {
                    this.showLoginPrompt();
                }
            },
            async initiateReviewForCookieReviewer(reviewer) {
                try {
                    await ApiHelper.post("/api/review/reviewer", {email: reviewer.email});
                    this.$cookies.set('reviewTour', JSON.stringify({reviewTourDone: true}));
                    this.$store.state.reviewMode.review.reviewerDetails = reviewer;
                    await this.getReview();
                } catch (e) {
                    console.error('Failed to post reviewer data:', e);
                }
            },
            showLoginPrompt() {
                this.loaded = true;
                this.showLoginModal = true;
            },
            closeLoginModal() {
                this.showLoginModal = false;
            },
            closeOverviewModal() {
                this.showOverviewModal = false;
            },
            async getReview() {
                try {
                    let contentId = this.$store.state.reviewMode.reviewType === ReviewTypeEnum.PORTFOLIO ? this.$store.state.view.portfolio._id : this.$store.state.view.project._id
                    let reviewObject = {
                        reviewType: this.$store.state.reviewMode.reviewType,
                        reviewer: this.$store.state.reviewMode.review.reviewerDetails.email,
                        ownerId: this.$store.state.view.portfolio.ownerId,
                        contentId: contentId
                    }

                    let response = await ApiHelper.post("/api/review", reviewObject);
                    let review = {...response.data, 'reviewerDetails': this.$store.state.reviewMode.review.reviewerDetails }
                    this.$store.commit('updateReview', review);
                    this.$store.state.reviewMode.reviews = [review];
                    this.loaded = true;

                }
                catch(err) {
                    console.log('Error while fetching reviews', err);
                }
            }
        },
    }
</script>

<style global lang="scss">
    .review-wrapper::-webkit-scrollbar-track {
        background-color: transparent; /* Makes the track transparent */
    }
    .review-wrapper {
        scrollbar-color: #888 transparent; /* Thumb color and track color */
    }
</style>