<template>
    <AnimationWrapper
        :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
        :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
    >
        <template #animationContent>
            <div v-if="portfolio.description"
                 :class="['ufo--page-hero-text',
                 portfolio.templateSettings.hero && portfolio.templateSettings.hero.textAlign ? 'align-text-' + portfolio.templateSettings.hero.textAlign : '']"
                 v-on:scroll.native="portfolio.templateSettings.theme === 'profile_theme_nine' ? scrolledDown() : null"
            >
                <div v-if="user.description" class="ufo--page-hero-text">{{ refactorString(user.description) }}</div>
            </div>
        </template>
    </AnimationWrapper>
    <AnimationWrapper
        :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
        :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
    >
        <template #animationContent>
            <UserSocialLinks v-if="socialLinksAreInHero()" />
        </template>
    </AnimationWrapper>
</template>

<script>
    import AnimationWrapper from "../../../../common/components/AnimationWrapper.vue";
    import UserSocialLinks from "./UserSocialLinks.vue";
    import {SocialLinksLocationEnum} from "../../../../common/enums.js";

    export default {
        name: "UserDescription",
        components: {UserSocialLinks, AnimationWrapper},
        props: ['user'],
        methods: {
            refactorString(data) {
                return data.replace("\n", "<br />").replace('<a href', '<a rel="nofollow" href');
            },
            socialLinksAreInHero() {
                return this.portfolio.templateSettings.navigation.socialLinksLocation === SocialLinksLocationEnum.INLINE_ABOVE_TEXT
                    || this.portfolio.templateSettings.navigation.socialLinksLocation === SocialLinksLocationEnum.INLINE_BELOW_TEXT
            },
        },
        computed: {
            user() {
                return this.$store.state.view.user
            }
        }
    }
</script>