<template>
    <div v-if="user.name" class="profile-name" @click="navigateToHome()" :style="{cursor:'pointer'}">
        {{ user.name }}
    </div>
</template>

<script>
    export default {
        name: "UserName",
        computed: {
            user() {
                return this.$store.state.view.user
            },
            portfolio() {
                return this.$store.state.view.portfolio
            }
        },
        methods: {
            navigateToHome() {
                if(this.portfolio.ownDomainAddress) {
                    this.$router.push('/')
                }
                else {
                    this.$router.push('/' + this.portfolio.shortId)
                }
            }
        }
    }
</script>