<template>
    <div :ref="'comment' + index" :class="['comment', {edit:edit}]" @mouseenter="hovering = true" @mouseleave="hovering = false">
        <img class="profile-picture" alt="userimage" v-if="user.image && user.image.url" :src="user.image.url"/>
        <UserImagePlaceholder :name="user.name" :size="'small'" v-else />
        <div class="comment-details">
            <div class="comment-user-and-text">
                <div class="comment-user">
                    <div class="name">{{ user.name }}</div>
                    <div class="time">{{ DateTimeHelper.getMoment(comment.createdAt) }}</div>
                </div>
                <div class="comment-text">{{ comment.text }}</div>
            </div>
            <div v-if="comment.label" :class="['label', comment.label.toLowerCase()]">{{ comment.label }}</div>
        </div>
        <div class="edit-container-wrapper" v-if="edit">
            <Icon @click="show = !show" :icon-name="'dots'"/>
            <div class="edit-container" v-if="show">
                <div class="edit-row" @click="updateComment">
                    <Icon icon-name="edit" />
                    Edit
                </div>
                <div class="edit-row delete" @click="deleteComment">
                    <Icon icon-name="delete" />
                    Delete
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from "../../../common/Icon.vue";
    import UserImagePlaceholder from "../../../common/components/UserImagePlaceholder.vue";

    export default {
        name: "CommentComponent",
        props:['comment', 'updateComment', 'deleteComment', 'edit', 'index'],
        components: {
            UserImagePlaceholder,
            Icon
        },
        data() {
            return {
                show:false,
                hovering:false
            }
        },
        computed: {
            //We have to check who wrote the comment, basically just owner or not.
            //The owner only valid if we are checking followups but this is a more general approach
            user() {
                let owner = this.$store.state.reviewMode.receiver ? this.$store.state.user : this.$store.state.view.user

                if(this.comment.fromUserId) {
                    //We have a logged in user
                    if(owner && this.comment.fromUserId === owner._id) {
                        return {
                            name: owner.name,
                            image: owner.image
                        }
                    //If not owner find the reviewer in the reviews
                    }else {
                        let reviewers = this.$store.state.reviewMode.reviews.map(x => x.reviewerDetails);
                        let foundReviewer =  reviewers.find(reviewer => reviewer._id === this.comment.fromUserId);
                        if(foundReviewer) {
                            return foundReviewer
                        }
                        //Edge case when the comment component is viewed from the admin panel, this is always true in that case
                        else {
                            return this.$store.state.reviewMode.review.reviewerDetails
                        }
                    }
                }
                else {
                    return this.$store.state.reviewMode.review.reviewerDetails
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .comment {
        display: flex;
        padding: 24px;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        position: relative;

        .profile-picture {
            width: 36px;
            height: 36px;
            border-radius: 50%;
        }

        &.edit {
            &:hover {
                background-color: var(--asphalt90);
            }
        }

        .edit-container-wrapper {
            position: absolute;
            right: 17px;
            top: 15px;

            &:hover {
                cursor: pointer;
            }

            .edit-container {
                position: absolute;
                display: flex;
                padding: 8px 0;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                z-index: 4;

                border-radius: 8px;
                border: 1px solid var(--asphalt70);
                background: var(--asphalt80);

                right: -10px;

                svg {
                    width: 18px;
                    height: 18px;
                }

                .edit-row {
                    display: flex;
                    height: 30px;
                    padding: 8px 16px;
                    align-items: center;
                    gap: 6px;
                    align-self: stretch;

                    font: var(--editor-ui-b3-medium);
                    color: var(--asphalt30);

                    &:hover {
                        cursor: pointer;
                        
                        color: var(--asphalt10);
                        &.delete {
                            color: var(--error60);
                        }
                    }

                    &.delete {
                        color: var(--error50);
                    }
                }
            }
        }

        .comment-details {

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;

            .comment-user-and-text {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;

                .comment-user {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .name {
                        font: var(--editor-ui-b2-semibold);
                        color: var(--asphalt20);
                    }
                    .time {
                        font: var(--editor-ui-b3-regular);
                        color: var(--asphalt40);
                    }
                }
                .comment-text {
                    font: var(--editor-ui-b2-regular);
                    color: var(--asphalt20);
                    overflow-wrap: anywhere;
                    max-width: 300px;
                }
            }

            .label {
                padding: 4px 12px;
                font: var(--editor-ui-l-medium);
                color: var(--asphalt40);
                border-radius: 30px;
                border: 1px solid var(--asphalt80);

                &.design {
                    background-color: #362B3B;
                    color:#E6BEF9;
                }

                &.structure {
                    background-color: #373B2B;
                    color:#E3F9A6;
                }

                &.content {
                    background-color: #2B3A3B;
                    color:#B9F3F7;
                }

                &.imagery {
                    background-color: #3B302B;
                    color:#FECAA4;
                }
            }
        }

    }
</style>