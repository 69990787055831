<template>
    <main>
        <!-- get old theme layout here -->
        <div v-if="!templateSettings.theme || templateSettings.theme.includes('profile_')" class="ufo--portfolio-container">
            <div :class="[
                    'ufo--portfolio-page',
                    'profile-page',
                    'profile-subpage',
                    templateSettings.theme,
                    'profile--hp',
                    templateSettings.theme === 'profile_theme_default' && portfolio.about ? 'default-template' : ''
                ]"
                :style="[getTextStyles]"
            >
                <div v-if="templateSettings.theme === 'profile_theme_default' || (templateSettings.theme !== 'theme_one' && templateSettings.theme !== 'theme_two')" :class="['grid', 'ufo--page-hero', templateSettings.theme]">
                    <div :class="['ufo--page-hero-content', templateSettings.theme === 'theme_three' || templateSettings.theme === 'theme_seven' ? 'no-profile-picture' : 'has-picture', contentPosition]">
                        <PortfolioHeroHelper />
                    </div>
                </div>
                <div class="ufo--page-content ufo--home-page-content">
                    <GetHeroNavigation v-if="templateSettings.theme === 'profile_theme_default'" :show-home-button="templateSettings.theme === 'profile_theme_default'" />
                    <div :class="['ufo--about-page', 'ufo--about-page-' + templateSettings.theme]">
                        <div class="ufo--about-header"
                             v-if="templateSettings.theme !== 'profile_theme_default' &&
                                templateSettings.theme !== 'theme_three' &&
                                templateSettings.theme !== 'profile_theme_four' &&
                                templateSettings.theme !== 'theme_five' &&
                                templateSettings.theme !== 'theme_six' &&
                                 templateSettings.theme !== 'theme_eight'"
                        >
                            <div class="grid">
                                <div class="about-title">
                                    <div class="about-title-container">
                                        <h1 class="title">{{ portfolio.about.pageTitle }}</h1>
                                    </div>
                                </div>
                            <div v-if="templateSettings.theme !== 'profile_theme_four' && templateSettings.theme !== 'theme_seven' && templateSettings.theme !== 'profile_theme_nine'" class="back" @click="navigateToPage()">⟵ Back</div>
                            </div>
                        </div>
                        <div class="grid ufo--about-content">
                            <div v-if="portfolio.about.image.url" class="about-picture">
                                <img :src="portfolio.about.image.url">
                            </div>
                            <div :class="['about-text', !portfolio.about.image.url ? 'no-pic': '']" v-html="getText(portfolio.about.description)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PublicPageFooter />
        </div>
        <!-- get new, simpler theme layout here -->
        <div v-else :class="['ufo--portfolio-page', templateSettings.theme]" :style="[getTextStyles]">
            <PortfolioHeroHelper />
            <div :class="['ufo--about-page', 'ufo--about-page-' + templateSettings.theme]">
                <div v-if="templateSettings.theme === 'theme_one' || templateSettings.theme === 'theme_two'" class="ufo--about-header">
                    <div class="grid">
                        <div class="about-title">
                            <div class="about-title-container">
                                <h1 class="title">{{ portfolio.about.pageTitle }}</h1>
                            </div>
                        </div>
                        <div class="back" @click="navigateToPage()">⟵ Back</div>
                    </div>
                </div>
            </div>
            <div class="ufo--page-content ufo--home-page-content">
                <div class="ufo--page-sections">
                    <div class="project-wrapper">
                        <div id="section-0" class="ufo-sec ufo-sec--textandmedia flipped">
                            <div class="grid">
                                <div class="col-media medium">
                                    <div class="media-container display-mode--fit">
                                        <div class="media-box  ">
                                            <div class="image">
                                                <img loading="lazy" :src="portfolio.about.image.url" data-zoom="" alt="" class="medium-zoom-image">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-text">
                                    <div class="text-container" v-html="portfolio.about.description"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PublicPageFooter />
        </div>
    </main>
</template>

<script>
import PortfolioHeroHelper from "../../../view/portfolio/themes/_helper/PortfolioHeroHelper.vue";
import PublicPageFooter from "../../../view/portfolio/common/PublicPageFooter.vue";
import GetHeroNavigation from "../../portfolio/common/hero/GetHeroNavigation.vue";

export default {
    name: "AboutPage",
    components: {GetHeroNavigation, PublicPageFooter, PortfolioHeroHelper},
    props:['portfolio', 'projects', 'user', 'themeId'],
    mounted() {
        this.$store.state.view.activePage = 'about';
    },
    computed: {
        templateSettings() {
            return this.portfolio.templateSettings
        },
        getTextStyles() {
            return this.$store.getters.getAllTextStylesInCss;
        },
        contentPosition() {
            return this.templateSettings.theme === "profile_theme_nine"
                ? `align-content-to-${this.templateSettings.hero.alignment.vertical}`
                : null
        }
    },
    methods: {
        getText(text) {
            return text.replace('<a href', '<a rel="nofollow" href');
        },
        navigateToPage(page) {
            let currentLocation = location.href.replace(location.search, "").replace('/p/', '/');
            if(window.location.pathname.includes('/contact') || (window.location.pathname.includes('/about') || (window.location.pathname.includes('/p/')))
            ){
                window.location = currentLocation.substring(0, currentLocation.lastIndexOf('/')) + (page ? '/' + page : '');
            }
            else {
                window.location = currentLocation + (currentLocation[currentLocation.length - 1] === '/' ? '' : '/') + (page ? page : '');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .ufo--about-page-profile_theme_default {
        background-color: transparent;
        box-shadow: none;
    }
</style>