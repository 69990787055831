<template>
    <div :id="'section-' + section.index" class="ufo-sec ufo-sec--text">
        <ToggleGrid />
        <div class="grid">
            <div :class="['ufo-sec--content', boxAlignment]">
                <SectionText :section-text="section.text" />
                <CtaButton :section="section" :form-submit-button="false" :alignment="textAlign"/>
            </div>
        </div>
    </div>
</template>

<script>
    import SectionText from "./_helper/GetSectionText.vue";
    import CtaButton from "./_helper/CtaButton.vue";
    import ToggleGrid from "../../../common/components/ToggleGrid.vue";

    export default {
        name: "TextSection",
        components: { CtaButton, SectionText, ToggleGrid },
        props: ['section'],
        computed: {
            boxAlignment() {
                return this.section.style && this.section.style.boxAlign ? "align-box-" + this.section.style.boxAlign : ""
            },
            textAlign() {
                return this.section.text.style && this.section.text.style.textAlign ? "push-to-"+ this.section.text.style.textAlign : ""
            }
        }
    }
</script>