export const defaultCtaButtonStyle = {
    fillStyle: "solid",
    shape: "pill",
    stroke: null,
    align: "left",
}

export const ctaButtonStyle = {
    //Tesler
    theme_eighteen: {
    },
    //Aurora
    theme_seventeen: {
        fillStyle:"outlined",
        shape: "rounded",
        stroke: {
            strength: 2
        }
    },
    //Norman
    theme_sixteen: {
        fillStyle:"outlined",
        stroke: {
            strength: 1
        }
    },
    //Jakob
    theme_fourteen: {
    },
    //Nelson
    theme_thirteen: {
        fillStyle:"outlined",
        shape: "rounded",
        stroke: {
            strength: 2
        }
    },
    //Otis
    theme_twelve: {
        shape: "rounded"
    },
    //Bauhaus
    theme_eleven: {
        fillStyle:"outlined",
        shape: "rounded",
        stroke: {
            strength: 2
        }
    },
    //Orielle
    theme_ten: {
    },
    //Anthra
    profile_theme_nine: {
    },
    //Nominee
    theme_eight: {
        shape: 'rounded',
    },
    //Uncurled
    theme_seven: {
        fillStyle:"outlined",
        shape: 'square',
        stroke: {
            strength: 1
        }
    },
    //Poster
    theme_six: {

    },
    //Panorama
    theme_five: {
        shape: 'square'
    },
    //Cubic
    profile_theme_four: {
        shape: "square"
    },
    //Axis
    theme_three: {
    },
    //Alaine
    theme_two: {
    },
    //Twee
    theme_one: {
        shape: "square",
        fillStyle: "outlined",
        stroke: {strength: 1}
    },
    // Baze
    profile_theme_default: {
        fillStyle: "outlined",
        shape: "square",
        stroke: {strength: 2}
    },
}