<template>
    <div :id="'section-' + section.index"
         class="ufo-sec ufo-sec--header-main"
    >
        <ToggleGrid />
        <SectionBackgroundImage :section="section" />
        <div :class="['grid', 'ufo--hero-content-grid', section.media && section.media.displayMode ? section.media.displayMode : '']"
             :style="rowStyle"
        >
            <GetSiteNavigation :section="section" />
            <div :class="['text-container',
                        section.media ? 'has-image' : '',
                        section.flipped ? 'col-order-2' : '',
                        section.title.style.left === 0 ? 'h-left' : section.title.style.left === 100 ? 'h-right' : '',
                        section.title.style.top === 0 ? 'v-top' : section.title.style.top === 100 ? 'v-bottom' : '',
                        section.media && section.media.frame && section.media.frame.includes('mobile') ?' frame-mobile' :
                        section.media && section.media.frame && section.media.frame.includes('tablet') ? 'frame-tablet' :
                        section.media && section.media.frame && section.media.frame.includes('desktop') ? 'frame-desktop' : ''
                    ]"
                 :style="positionStyle">
                <AnimationWrapper
                    :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                    :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                >
                    <template #animationContent>
                        <div>
                            <div :class="section.title.style.textStyle ? section.title.style.textStyle :'h1'" v-html="fixText('title')"></div>
                            <div :class="section.subtitle.style.textStyle ? section.subtitle.style.textStyle :'h2'" v-html="fixText('subtitle')"></div>
                        </div>
                    </template>
                </AnimationWrapper>
            </div>
            <AnimationWrapper
                :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
            >
                <template #animationContent>
                    <div v-if="section.media"
                         :class="['image-container',
                          section.flipped ? 'col-order-1' : '',
                          section.title.style.top === 0 ? 'v-top' : section.title.style.top === 100 ? 'v-bottom' : '',
                          section.media.displayMode ? 'media-' + section.media.displayMode : '',
                          section.media.frame && section.media.frame.includes('mobile') ? 'frame-mobile' :
                          section.media.frame && section.media.frame.includes('tablet') ? 'frame-tablet' :
                          section.media.frame && section.media.frame.includes('desktop') ? 'frame-desktop' : ''
                       ]">
                        <div :class="['media-container', !section.media.displayMode && !section.media.frame ? 'display-mode--default' : '']">
                            <GetMediaContent :section="section" :default-caption="'Image alt tag'" :placeholder-image="'v2/gallery_sample/gallery-sample-tall-01.jpg'" />
                        </div>
                    </div>
                </template>
            </AnimationWrapper>
        </div>
    </div>
</template>

<script>

    import GetMediaContent from "./_helper/GetMediaContent.vue";
    import GetSiteNavigation from "./_helper/GetSiteNavigation.vue";
    import ToggleGrid from "../../../common/components/ToggleGrid.vue";
    import AnimationWrapper from "../../../common/components/AnimationWrapper.vue";
    import SectionBackgroundImage from "../../../sections/common/SectionBackgroundImage.vue";

    export default {
        name: "MainHeader",
        components: {
            SectionBackgroundImage,
            AnimationWrapper, GetSiteNavigation, GetMediaContent, ToggleGrid },
        props:['section'],
        methods: {
            fixText(data) {
                return this.section[data].text.replaceAll('\n', '<br/>')
            }
        },
        computed: {
            positionStyle() {
                return {
                    'text-align': this.section.title.style.textAlign
                }
            },
            rowStyle() {
                return {
                    '--rowSize': this.section.style && this.section.style.sectionHeight ? this.section.style.sectionHeight + 'px' : null
                }
            }
        }
    }
</script>