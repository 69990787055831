<template>
    <div class="ufo--portfolio-password-protected">
        <div class="icon"></div>
        <h1>Please enter the password to view my {{ type }}</h1>
        <form name="password_form">
            <div class="ufo-form--group">
                <InputField  v-model="password" name="password" type="password" autocomplete="off" placeholder="Enter Password…" required />
                <Cta @click="(evt) => checkPassowrd(evt)">Go!</Cta>
            </div>
            <div v-if="passwordError" class="password-error">
                <p>{{ passwordError }}</p>
            </div>
        </form>
    </div>
</template>

<script>
import ApiHelper from "../../../api/ApiHelper.js";
import Cta from "../../../common/components/CtaButton.vue";
import InputField from "../../../common/components/Input.vue";

export default {
    name: "PasswordProtection",
    components: {InputField, Cta},
    props: ['fetchUrl', 'setData', 'type'],
    data() {
        return {
            passwordError: false,
            password:'',
        }
    },

    methods: {
        async checkPassowrd(evt) {
            evt.preventDefault();
            let url = this.fetchUrl(this.$route)
            try {
                let res = await ApiHelper.get(url, {password: this.password});
                this.onSuccess(res.data)
            }
            catch (err) {
                this.onFailure()
            }

        },
        onFailure(err) {
            this.passwordError = 'Invalid password'
        },
        onSuccess(data) {
            this.$cookies.set(data.portfolio.shortId, this.password);
            if(data.portfolio.ownDomainAddress) {
                this.$cookies.set(data.portfolio.ownDomainAddress, this.password);
            }
            let projectIdArrary = data.relatedProjectIds.slice(0, -1).split(",");
            projectIdArrary.forEach((projectId) => {
                this.$cookies.set(projectId, this.password);
            })
            this.setData(null, data);
        }
    }
}
</script>

<style lang="scss" scoped>
.ufo--portfolio-password-protected {
    width: 100%;
    height: 100vh;
    background-color: rgba(240, 246, 251, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    .icon {
        width: 72px;
        height: 72px;
        margin-bottom: 36px;
        border: solid 1px #d1d9dd;
        border-radius: 50%;
        background: url("../../../../images/icon-lock-small-grey.svg") center center no-repeat;
        background-size: 20px 26px;
    }
    h1 {
        font-family: var(--primarySans);
        font-size: 36px;
        font-weight: 900;
        line-height: 1.3;
        text-align: center;
        color: #140a2f;
        margin: 0 0 36px;
        max-width: 500px;
    }
    @media (min-width: 768px) {
        h2 {
            font-size: 36px;
        }
    }
    form {
        width: 100%;
        max-width: 540px;
    }
    .ufo-form--group {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 24px;
        input {
            width: 100%;
            margin: 0 0 16px 0;
        }
        .ufo-cta {
            width: 100%;
            height: 46px;
        }

        @media (min-width: 450px) {
            display: flex;
            flex-direction: row;
            input {
                max-width: 448px;
                margin: 0 16px 0 0;
            }
            .ufo-cta {
                width: 78px;
            }
        }
    }
}
</style>