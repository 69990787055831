<template>
    <div :id="'section-' + section.index" class="ufo-sec ufo-sec--contactform">
        <ToggleGrid />
        <div class="grid">
            <template v-if="section.text">
                <div v-if="section.showMedia" :class="['col-media', section.flipped ? 'col-order-2' : '']">
                    <div class="media-container">
                        <div class="media-box">
                            <GetMediaContent :section="section" :default-caption="''" :placeholder-image="'empty-image-and-text-image.jpg'"/>
                        </div>
                    </div>
                </div>
                <div :class="['col-text', section.flipped ? 'col-order-1' : '', !section.showMedia ? 'full-w' : '']">
                    <div class="ufo--contact-form">
                        <SectionText v-if="section.showText" :section-text="section.text" />
                        <AnimationWrapper
                            :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                            :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                        >
                            <template #animationContent>
                                <form @submit="sendEmail" class="ufo--form" name="contact_form_section">
                                    <input type="hidden" name="toEmail" :value="user.contactEmail" />

                                    <label>Email</label>
                                    <input class="s-mail form-handler" name="senderEmail" type="text" required placeholder="Your email" />

                                    <label>Name</label>
                                    <input class="s-name form-handler" name="senderName" type="text" required placeholder="Your name" />

                                    <label>Message</label>
                                    <textarea name="message" type="text" class="form-handler" required placeholder="Write your message here..."></textarea>
                                    <CtaButton :form-submit-button="true" :section="section" :alignment="textAlign"/>
                                </form>
                            </template>
                        </AnimationWrapper>
                        <div class="ufo--form-status-message"></div>
                    </div>
                </div>
            </template>
            <div v-else class="ufo--contact-form">
                <AnimationWrapper
                    :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                    :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                >
                    <template #animationContent>
                        <form class="ufo--form" @submit="sendEmail" name="contact_form_section">
                            <div :class="'title ' + section.title.style.textStyle">
                                {{ section.title.text }}
                            </div>
                            <input type="hidden" name="toEmail" :value="user.contactEmail" />
                            <textarea name="message" type="text" class="form-handler" required placeholder="Write your message here..."></textarea>
                            <div :class="'subtitle ' + section.subtitle.style.textStyle">
                                {{ section.subtitle.text }}
                            </div>
                            <div class="sender">
                                <input class="s-name form-handler" name="senderName" type="text" required placeholder="Your name" />
                                <input class="s-mail form-handler" name="senderEmail" type="text" required placeholder="Your email" />
                            </div>
                            <CtaButton :section="section" :form-submit-button="true"/>

                            <!-- section.label field was killed on 28.06.2021. (only in sections added after this date) -->
                            <div v-if="section.label" :class="['um', section.label.style.textStyle]" v-html="section.label.text">
                            </div>
                        </form>
                    </template>
                </AnimationWrapper>
                <div class="ufo--form-status-message"></div>
            </div>
        </div>
    </div>
</template>

<script>
import CtaButton from "./_helper/CtaButton.vue";
import GetMediaContent from "./_helper/GetMediaContent.vue";
import SectionText from "./_helper/GetSectionText.vue";
import axios from "axios";
import ToggleGrid from "../../../common/components/ToggleGrid.vue";
import AnimationWrapper from "../../../common/components/AnimationWrapper.vue";

export default {
    name: "ContactForm",
    components: {AnimationWrapper, ToggleGrid, SectionText, GetMediaContent, CtaButton},
    props: ['section'],
    computed: {
        textAlign() {
            return this.section.text && this.section.text.style && this.section.text.style.textAlign ? "push-to-" + this.section.text.style.textAlign : ""
        },
        user() {
            return this.$store.state.view.user
        }
    },
    methods: {
        sendEmail(e) {
            e.preventDefault();
            const form = e.target;
            let parent = form.parentNode;

            setTimeout(() => {
                const fSm = parent.querySelector(".ufo--form-status-message");
                fSm.innerHTML = "Sending your message…";
                fSm.classList.add("active");
                parent.classList.toggle("message-sent");
            }, 250);

            axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            axios.post('/portfolio/contact', {
                toEmail: form["toEmail"].value,
                message: "Dear" + this.user.name.replaceAll("\n", " ") + "\n\n" + form["message"].value + "\n\n" + form["senderName"].value + "\n" + form["senderEmail"].value,
                senderName: form["senderName"].value,
                senderEmail: form["senderEmail"].value
            })
                .then(function (response) {
                    const fSm = parent.querySelector(".ufo--form-status-message");
                    fSm.innerHTML = "Message sent.";
                    setTimeout(function(){
                        fSm.classList.remove("active");
                        parent.classList.toggle("message-sent");
                    }, 2000);

                    form.reset();
                })
                .catch(function (error) {
                    //TODO: Notify user about error
                    const fSm = parent.querySelector(".ufo--form-status-message");
                    fSm.innerHTML = "Something went wrong. Try again.";
                    setTimeout(function(){
                        fSm.classList.remove("active");
                        parent.classList.toggle("message-sent");
                    }, 2000);
                    form.reset();
                });

            return false;
        }
    },
}
</script>