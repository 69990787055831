import { store } from "../FolioStore.js";
import UsersAPI from '../../api/users/UserAPI.js';
import {PortfolioActions} from "./PortfolioActions.js";
import {event} from "vue-gtag";
import ApiHelper from "../../api/ApiHelper.js";

export const UserActions = {
    // create user
    createUser: async function () {
        try {
            let response = await UsersAPI.createMyUser();
            store.state.user = response.data.user;

            await this.getUserSettings();

            event('New user login', {
                'event_category': 'Project editor',
                'event_label': `User: ${store.state.user._id}`
            });
        }
        catch (error) {
            if (error.response && error.response.status === 409) { // User already exist
                window.location.reload();
            }
        }
    },

    // get user
    getUser: async function () {
        try {
            let response = await UsersAPI.getMyUser();
            store.state.user = {...response.data.user, paymentMethods: response.data.userPaymentMethods};
            store.state.emergencyModeSettings = response.data.emergencyModeSettings;
            await UserActions.getUserSettings();
            UserActions.setUserDataInSupportWidget(store.state.user.name, store.state.user.email);

            UserActions.identifyWoopraUser();
        }
        catch(error) {
            if (error.response.status === 404) {
                await UserActions.createUser();
            }

            //keycloak user also deleted
            if (error.response.status === 403) {
                window.location.href = "/logout"
            }
        }
    },
    async getUserSettings() {
        try {
           let response = await UsersAPI.getUserSettings(store.state.user._id);
            store.state.userSettings = response.data;
            store.state.currentSubscriptionPlan = response.data.currentSubscriptionPlan;
            if(store.state.currentSubscriptionPlan !== 'Free' && store.state.userSettings.currentSubscription && store.state.userSettings.currentSubscription.status === "past_due") {
                store.state.activeModal = "CancelSubscriptionModal";
            }
        }
        catch(error) {
            console.log('failed to get user settings', error)
        }
    },

    deleteUserImage(imageUrl) {
        store.state.savingProject = true;
        if(imageUrl) {
            UsersAPI.deleteUserImage(store.state.user._id, imageUrl)
                .then(response =>{})
                .catch(response => {})
                .finally(() => {store.state.savingProject = false;})
        }
    },

    updateUserInDrip(dripEvent) {
        UsersAPI.updateUserInDrip(store.state.user._id, dripEvent)
            .then(response => {})
            .catch(error => {console.log(error)})
    },

    async refreshData(referral) {
        await UserActions.getUser();
        if(referral) {
            await UserActions.setUserAsReferred(referral, store.state.user)
        }
        await PortfolioActions.getPortfolios()
        await PortfolioActions.getAllCaseStudyPages()
        await PortfolioActions.getLatestNotifications()
        await PortfolioActions.getAllAvalibleFonts()
    },

    setUserDataInSupportWidget(visitorName, visitorEmail) {
        if(window.fwSettings) {
            FreshworksWidget('identify', 'ticketForm', {
                name: visitorName,
                email: visitorEmail
            });
        }
    },

    async setUserAsReferred(referrerId, user) {
        try {
            let response = await ApiHelper.post('/api/referral/' + referrerId, {user:user._id, email:user.email});
            store.state.userSettings.referredData = response.data;
        }
        catch(err) {
            console.log('Failed to set user as referred')
        }
    },

    identifyWoopraUser() {
        if(window.woopra) {
            window.woopra.identify({
                id: store.state.user._id,
                email: store.state.user.email,
                name: store.state.user.name
            });

            window.woopra.track("login", {url: location.href, title: document.title})

        }
        if(window._paq) {
            const matomo = window._paq
            matomo.push(['setUserId', store.state.user._id]);
            matomo.push(['trackPageView']);
            matomo.push(['enableLinkTracking']);
            matomo.push(['trackEvent', "Log in", store.state.user.email, store.state.user.name, store.state.user._id]);
        }
    }
}
