export const FontScaleSmall = Object.freeze({
    HERO_TITLE: 45,
    HERO_SUBTITLE: 32,
    PROJECT_TITLE: 22,
    H1: 32,
    H2: 28,
    H3: 24,
    H4: 20,
    HERO_PARAGRAPH: 18,
    PROJECT_SUBTITLE: 14,
    P1: 14,
    P2: 12,
    NAVIGATION: 14,
    BUTTON: 14,
    PAGE_TITLE: 32
})

export const FontScaleMedium = Object.freeze({
    HERO_TITLE: 60,
    HERO_SUBTITLE: 44,
    PROJECT_TITLE: 24,
    H1: 44,
    H2: 36,
    H3: 28,
    H4: 24,
    HERO_PARAGRAPH: 20,
    PROJECT_SUBTITLE: 16,
    P1: 16,
    P2: 14,
    NAVIGATION: 16,
    BUTTON: 16,
    PAGE_TITLE: 44
})

export const FontScaleLarge = Object.freeze({
    HERO_TITLE: 80,
    HERO_SUBTITLE: 56,
    PROJECT_TITLE: 32,
    H1: 56,
    H2: 44,
    H3: 36,
    H4: 28,
    HERO_PARAGRAPH: 22,
    PROJECT_SUBTITLE: 18,
    P1: 18,
    P2: 16,
    NAVIGATION: 18,
    BUTTON: 18,
    PAGE_TITLE: 56
})

export const FontScaleHuge = Object.freeze({
    HERO_TITLE: 112,
    HERO_SUBTITLE: 72,
    PROJECT_TITLE: 40,
    H1: 72,
    H2: 56,
    H3: 40,
    H4: 32
    // stops here, huge is not supported in paragraphs and navigation
})