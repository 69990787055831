<template>
    <div :class="['tab-bar', {'dark': darkScheme, 'hide-border': hideBorder, 'full-width': fullWidth}]">
        <slot></slot>
    </div>
</template>

<script>

import Icon from "../Icon.vue";

export default {
    name: 'tab-bar',
    props: {
        hideBorder: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        darkScheme: {
            type: Boolean,
            default: false
        },
    },
}
</script>

<style lang="scss" scoped>

.tab-bar {
    --tab-bar-border: var(--asphalt30);
    &.dark {
        --tab-bar-border: var(--asphalt80);
    }

    display: flex;
    align-items: flex-start;
    gap: 24px;
    border-bottom: 1px solid var(--tab-bar-border);

    &.hide-border {
        border-bottom: none;
    }
    &.full-width {
        justify-content: space-around;
        gap: 0;
        :deep(.tab-pill) {
            width: 100%;
        }
    }
}

</style>
