<template>
    <UserThemeCoverImage v-if="portfolio && portfolio.image.url"/>
    <UserImage v-else />
    <div class="profile-short-cv">
        <UserName />
        <UserJobTitle />
        <div class="profile-links">
            <div v-for="page in portfolioPages"
                :class="['ufo--site-nav-button',
                        page.type === 'contact' ? 'contact-button' : '',
                        page.type === 'contact' && checkSelected(page) ? 'selected' : '',
                        page.type === 'file' ? 'resume-button' : '']"
                 :data-text="page.type === 'contact' && page.name ? page.name : null"
            >
                <a v-if="page.type === 'contact'"
                   :href="'/p/' + !portfolio.ownDomainAddress ? portfolio.shortId : '' + '/' + page.shortId"
                   :class="['btn', checkSelected(page) ? 'active' : '']"
                >
                    {{page.name ? page.name : ''}}
                </a>
                <a v-if="page.type === 'file'"
                   :href="page.settings.link.url"
                   target="_blank"
                   rel="nofollow noopener"
                   class="btn">{{page.name}}</a>
            </div>
        </div>
    </div>
    <UserPortfolioDescription v-if="portfolio && portfolio.description"/>
    <UserDescription v-else />
</template>

<script>
    import UserPortfolioDescription from "../../common/user/UserPortfolioDescription.vue";
    import UserDescription from "../../common/user/UserDescription.vue";
    import UserName from "../../common/user/UserName.vue";
    import UserJobTitle from "../../common/user/UserJobTitle.vue";
    import UserThemeCoverImage from "../../common/user/UserThemeCoverImage.vue";
    import UserImage from "../../common/user/UserImage.vue";

    export default {
        name: "ThemeDefaultOld",
        components: {
            UserImage,
            UserThemeCoverImage,
            UserJobTitle,
            UserName,
            UserDescription,
            UserPortfolioDescription
        },
        computed: {
            portfolioPages() {
                return this.$store.state.view.portfolioPages
            },
            portfolio() {
                return this.$store.state.view.portfolio
            }
        },
        methods: {
            checkSelected(page) {
                return window.location.pathname.includes("/" + page.shortId)
            }
        }
    }
</script>