<template>
    <button
            :class="[
                'ufo-cta',
                `ufo-cta--${type}`,
                size,
                {'extension' : hasExtensionButton},
                {'tooltip-on' : !!tooltip},
                {'loading': loading},
                {'dark': darkScheme}]"
            :tooltip="tooltip"
            :tooltip-position="tooltipPosition"
    >
        <!-- button text or icon comes here -->
        <span class="button-icon">
            <div class="icon-container">
                <slot></slot>
            </div>
        </span>

        <div v-if="loading" class="loading-state">
            <InlineSvg :src="icon" :class="['ufo-cta--loader', size]" />
            <!-- <div>Saving</div> -->
        </div>
        <slot v-if="hasExtensionButton" name="extension"></slot>
    </button>
</template>

<script>
    import InlineSvg from "vue-inline-svg";
    //loading svgs
    import default_loader from "../../../images/saving-oval-blue.svg"
    import small_loader from "../../../images/saving-oval-blue-small.svg"
    import large_loader from "../../../images/saving-oval-blue-large.svg"

    export default {
        name: 'cta',
        components: {
            InlineSvg,
        },
        props: {
            type: {
                type: String,
                default: 'primary' // type options: primary, (primary subtle), secondary, tertiary, link, alert, floating, icon, subtle, (subtle) no-bg
            },
            size: {
                type: String,
                default: null // size options: small, large otherwise it is the default
            },
            tooltip: {
                type: String,
                default: null
            },
            tooltipPosition: {
                type: String,
                default: null
            },
            loading: {
                type: Boolean,
                default: false,
            },
            darkScheme: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            hasExtensionButton() {
                return !!this.$slots['extension']
            },
            icon() {
                if(this.size === 'small') {
                    return small_loader
                }
                else if(this.size === 'large') {
                    return large_loader
                }
                else {
                    return default_loader
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../styles/editor/components/editor-ui-buttons";
</style>