<template>
    <div
        class="profile-footer"
        v-if="oldFooterIsVisible"
    >
        <div class="grid">
            <div class="profile-footer-content">
                <a v-for="socialLink in filteredSocials"
                   :href="socialLink.url"
                   target="_blank"
                   rel="nofollow noopener"
                   :class="[templateSettings.theme !== 'profile_theme_eight' && templateSettings.theme !== 'theme_eight' && templateSettings.theme !== 'theme_thriteen' ? 'footer-url' : 'footer-icon']"
                >
                    <div v-if="templateSettings.theme !== 'profile_theme_eight' && templateSettings.theme !== 'theme_eight' && templateSettings.theme !== 'theme_thirteen'">
                        {{ socialLink.type }}
                    </div>
                    <i v-else :class="['fa', 'fa-' + socialLink.type.toLowerCase()]"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PublicPageFooter",
        computed: {
            portfolio() {
                return this.$store.state.view.portfolio
            },
            templateSettings() {
                return this.portfolio.templateSettings
            },
            user() {
                return this.$store.state.view.user
            },
            socialLinks() {
                if(this.user.socialLinks || this.portfolio.socialLinks) {
                    return this.user.socialLinks ? this.user.socialLinks : this.portfolio.socialLinks
                }
                else {
                    return []
                }
            },
            filteredSocials() {
                return this.socialLinks.filter(x => x.url !== '')
            },
            oldFooterIsVisible() {
                return (
                    !!this.$store.state.portfolio
                    && this.$store.state.portfolio.settings
                    && this.$store.state.portfolio.settings.showFooterOnPortfolio === null
                    && !!this.$store.state.portfolio.socialLinks
                    && this.$store.state.portfolio.socialLinks.length > 0
                    && this.$store.state.portfolio.socialLinks[0].url !== ''
                    && this.templateSettings.theme !== 'profile_theme_four'
                    && this.templateSettings.theme.indexOf('default') < 0
                    && this.templateSettings.theme !== 'profile_theme_nine'
                ) && (
                    this.templateSettings.theme === 'profile_theme_eight'
                    || this.templateSettings.theme === 'theme_eight'
                    || this.templateSettings.theme === 'theme_eleven'
                    || this.templateSettings.theme === 'theme_twelve'
                );
            },
        }
    }
</script>