const defaultImageSettings = {
    defaultUserImageUrl: null
}
export const defaultImages = {
    //Tesler
    theme_eighteen: {
        ...defaultImageSettings
    },
    //Aurora
    theme_seventeen: {
        ...defaultImageSettings
    },
    //Norman
    theme_sixteen: {
        ...defaultImageSettings,
        defaultUserImageUrl: '/template-default-images/user-images/theme_sixteen-user-image.webp'
    },
    //Jakob
    theme_fourteen: {
        ...defaultImageSettings
    },
    //Nelson
    theme_thirteen: {
        ...defaultImageSettings
    },
    //Otis
    theme_twelve: {
        ...defaultImageSettings
    },
    //Bauhaus
    theme_eleven: {
        ...defaultImageSettings
    },
    //Orielle
    theme_ten: {
        ...defaultImageSettings,
        defaultUserImageUrl: '/template-default-images/user-images/theme_ten-user-image.webp'
    },
    //Anthra
    profile_theme_nine: {
        ...defaultImageSettings,
        defaultUserImageUrl: '/template-default-images/user-images/theme_nine-user-image.webp'
    },
    //Nominee
    theme_eight: {
        ...defaultImageSettings
    },
    //Uncurled
    theme_seven: {
        ...defaultImageSettings
    },
    //Poster
    theme_six: {
        ...defaultImageSettings,
        defaultUserImageUrl: '/template-default-images/user-images/theme_six-user-image.webp'
    },
    //Panorama
    theme_five: {
        ...defaultImageSettings
    },
    //Cubic
    profile_theme_four: {
        ...defaultImageSettings,
        defaultUserImageUrl: '/template-default-images/user-images/theme_four-user-image.webp'
    },
    //Axis
    theme_three: {
        ...defaultImageSettings,
        defaultUserImageUrl: '/template-default-images/user-images/theme_three-user-image.webp'
    },
    //Alaine
    theme_two: {
        ...defaultImageSettings,
        defaultUserImageUrl: '/template-default-images/user-images/theme_two-user-image.webp'
    },
    //Twee
    theme_one: {
        ...defaultImageSettings
    },
    // Baze
    profile_theme_default: {
        ...defaultImageSettings,
        defaultUserImageUrl: '/template-default-images/user-images/theme_default-user-image.webp'
    },
}