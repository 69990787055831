
export function hexToHSL(hex) {
    let sliced_hex = hex.slice(0, 7);
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(sliced_hex);
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    r /= 255
    g /= 255
    b /= 255;
    let max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;
    if(max === min){
        h = s = 0; // achromatic
    }else{
        let d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max){
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }
    let HSL = {};
    HSL['h']=h;
    HSL['s']=s;
    HSL['l']=l;
    return HSL;
}

export function hslObjectToCSS(hsl) {
    // Convert normalized HSL values to CSS format
    const h = Math.round(hsl.h * 360); // Convert to degrees
    const s = Math.round(hsl.s * 100); // Convert to percentage
    const l = Math.round(hsl.l * 100); // Convert to percentage
    return `hsl(${h}, ${s}%, ${l}%)`;
}

export function adjustLightness(hsl, adjustment) {
    const changeInLightness = adjustment * 0.01;
    const adjustedObject = {...hsl}
    adjustedObject.l = Math.max(0, Math.min(1, hsl.l + changeInLightness));
    return adjustedObject;
}
export function hslToCSS(hsl) {
    return `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`;
}

export function blendColors(hexTopColor, hexBottomColor, topAlpha) {
    // Convert hex color to RGB
    function hexToRGB(hex) {
        hex = hex.replace(/^#/, '');
        if (hex.length === 3) {
            hex = hex.split('').map(h => h + h).join('');
        }
        return {
            r: parseInt(hex.substring(0, 2), 16),
            g: parseInt(hex.substring(2, 4), 16),
            b: parseInt(hex.substring(4, 6), 16)
        };
    }

    // Convert RGB to hex
    function rgbToHex(r, g, b) {
        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
    }

    // Get RGB values for both colors
    const colorA = hexToRGB(hexTopColor);
    const colorB = hexToRGB(hexBottomColor);

    // Calculate the resulting color
    const r = Math.round(colorA.r * topAlpha + colorB.r * (1 - topAlpha));
    const g = Math.round(colorA.g * topAlpha + colorB.g * (1 - topAlpha));
    const b = Math.round(colorA.b * topAlpha + colorB.b * (1 - topAlpha));

    // Convert the result to hex format
    return rgbToHex(r, g, b);
}

export function hexToRgba(hex, alpha) {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function generateSuggestedColors(colorStyles) {
    const bg = colorStyles.background.color;
    const h1 = colorStyles.h1.color;
    const buttonFill = colorStyles.buttonFill.color === 'transparent' ? colorStyles.buttonOutline.color : colorStyles.buttonFill.color;

    return {
        solid: {
            subtle: [blendColors(h1, bg, 0.08)],
            platform: [blendColors(h1, bg, 0.16)],
            accent: [blendColors(buttonFill, bg, 0.28)],
            accent_platform: [blendColors(buttonFill, bg, 0.48)],
            contrast: [blendColors(h1, bg, 0.72)]
        },
        gradient: {
            subtle: [bg, blendColors(h1, bg, 0.32)],
            platform: [blendColors(h1, bg, 0.16), blendColors(h1, bg, 0.64)],
            accent: [bg, buttonFill],
            accent_platform: [blendColors(buttonFill, bg, 0.24), blendColors(buttonFill, bg, 0.64)],
            contrast: [blendColors(h1, bg, 0.64), h1]
        }
    }
}