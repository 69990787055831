<template>
    <transition name="modal" @enter="init" appear>
        <div :class="['new-modal-wrapper', {'dark':darkScheme}]" v-if="show">
            <div class="new-modal-mask" @click="handleOutsideClick()"></div>
            <div :class="[
                    'new-modal-container',
                    `new-modal-container--${modalSize}`,
                ]"
                 :style="heightOverwrite ? {'height': heightOverwrite} : undefined"
            >
                <slot name="modal-header" @close="closeModal"></slot>
                <slot name="modal-body"></slot>
                <slot name="modal-footer"></slot>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    name: 'modal-wrapper',
    props: {
        show: {
            default: true,
            type: Boolean
        },
        modalSize: {
            default: 'medium', //  medium, large
            type: String
        },
        darkScheme: {
            default: false,
            type:Boolean
        },
        disableOutsideClick: {
            default: false,
            type: Boolean
        },
        heightOverwrite: {
            default: null,
            type: String
        }
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        handleOutsideClick() {
            if (!this.disableOutsideClick) {
                this.$emit('close');
            }
        },
        init() {
            this.$emit('init', true);
        },
    },
    provide() {
        return {
            modalSize: this.modalSize
        };
    }
}
</script>

<style lang="scss" scoped>


.new-modal-wrapper {

    --modal-title: var(--asphalt100);
    --modal-description: var(--asphalt60);
    --modal-border: var(--asphalt30);
    --modal-bg: var(--asphalt10);

    &.dark {
        --modal-title: var(--asphalt10);
        --modal-description: var(--asphalt40);
        --modal-border: var(--asphalt80);
        --modal-bg: var(--asphalt95);
    }


    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 30px;
    transition: opacity .3s ease;

    .new-modal-mask {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(88, 97, 101, 0.7);
    }
    .new-modal-container {
        width: 750px;
        max-width: 100%;
        max-height: 95vh;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 8px;
        box-shadow: var(--shadow-light-large);
        transition-property: transform;
        transition-duration: 150ms;
        transition-timing-function: ease-in-out;
        background-color: var(--modal-bg);

        &--small {
            width: 500px;
            height: auto;
        }
        &--medium {
            width: 640px;
            height: auto;
        }
        &--large {
            width: 880px;
            height: 640px;
        }
        &--fit-content {
            width: fit-content;
            height: fit-content;
            overscroll-behavior: none;
        }
    }
}

// modal open animation
.modal-enter-active,
.modal-leave-active {
    transition-property: opacity, transform;
    transition-duration: 150ms;
    transition-timing-function: ease;
}

.modal-enter-from,
.modal-leave-to {
    transform: scale(1.1);
}

</style>
