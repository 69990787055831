<template>
    <div :class="[isGenerated && 'generated-cover-container', 'image-container', setCustomClass]"
         @click="$emit('click')"
         :style="[getBackgroundColor]"
    >
        <div v-if="isGenerated && this.caseStudy.thumbnailData && this.layouts" class="background-color-container">
            <div :class="['thumbnail-preview', thumbnailSize]" :style="backgroundStyle">
                <SvgBackground
                    v-if="this.caseStudy.thumbnailData.background.style === 'gradient'"
                    class="background-image"
                    :style="{color: suggestedColorsUsed
                        ? suggestedColorOptions[this.caseStudy.thumbnailData.background.style.match(/^[^_]*/)[0]][this.caseStudy.thumbnailData.background.color][0]
                        : this.caseStudy.thumbnailData.background.color}"
                    :variant="this.caseStudy.thumbnailData.background.gradient"
                />
                <div ref="mockupsContainer" class="mockups-container" :class="[this.caseStudy.thumbnailData.layout.type, this.caseStudy.thumbnailData.layout.version, this.caseStudy.thumbnailData.deviceStyle.baseStyle, Object.values(this.caseStudy.thumbnailData.deviceStyle.settings), thumbnailSize, 'align-text-' + largeThumbnailTextPosition]" :style="{ height: dynamicHeight + 'px' }">
                    <div v-bind:key="index + '-mockup'" v-for="(mockup, index) in this.layouts[this.caseStudy.thumbnailData.layout.type][this.caseStudy.thumbnailData.layout.version].mockupTypes" :class="`mockup-container mockup-${index}`">

                        <img class="mockup-image" :class="[caseStudy.thumbnailData.deviceStyle.baseStyle+ '-' +mockup + '-image', mockup + '-image']" alt="mockup-user-image" :src="getMockupContentSrc(mockup, index)"/>

                        <InlineSvg :key="`mockup-${index}`" class="mockup-svg" :src="getMockupSrc(mockup)" />

                    </div>
                </div>
            </div>
        </div>

        <img v-else-if="!!getImageUrl" :src="getImageUrl" class="project-thumbnail" :alt="caseStudy.image.altTag">
    </div>
</template>

<script>

import InlineSvg from "vue-inline-svg";
import mobile_placeholder from "../../../images/thumbnail-generation/default-images/mobile-placeholder.png";
import desktop_placeholder from "../../../images/thumbnail-generation/default-images/desktop-placeholder.png";
import tablet_placeholder from "../../../images/thumbnail-generation/default-images/tablet-placeholder.png";
import monitor_placeholder from "../../../images/thumbnail-generation/default-images/monitor-placeholder.png";
import mobile_single_middle from "../../../images/thumbnail-generation/layouts/mobile/mobile_single_middle.svg";
import mobile_single_slided from "../../../images/thumbnail-generation/layouts/mobile/mobile_single_slided.svg";
import mobile_dual_middle from "../../../images/thumbnail-generation/layouts/mobile/mobile_dual_middle.svg";
import mobile_dual_slided from "../../../images/thumbnail-generation/layouts/mobile/mobile_dual_slided.svg";
import mobile_triple_middle from "../../../images/thumbnail-generation/layouts/mobile/mobile_triple_middle.svg";
import mobile_triple_slided from "../../../images/thumbnail-generation/layouts/mobile/mobile_triple_slided.svg";
import mobile_triple_middle_horizontal
    from "../../../images/thumbnail-generation/layouts/mobile/mobile_triple_middle_horizontal.svg";
import mobile_triple_slided_rotated
    from "../../../images/thumbnail-generation/layouts/mobile/mobile_triple_slided_rotated.svg";
import mobile_quadruple_slided from "../../../images/thumbnail-generation/layouts/mobile/mobile_quadruple_slided.svg";
import mobile_quadruple_slided_rotated
    from "../../../images/thumbnail-generation/layouts/mobile/mobile_quadruple_slided_rotated.svg";
import desktop_single_middle from "../../../images/thumbnail-generation/layouts/desktop/desktop_single_middle.svg";
import desktop_single_slided from "../../../images/thumbnail-generation/layouts/desktop/desktop_single_slided.svg";
import desktop_mobile_overlay from "../../../images/thumbnail-generation/layouts/desktop/desktop_mobile_overlay.svg";
import desktop_mobile_middle from "../../../images/thumbnail-generation/layouts/desktop/desktop_mobile_middle.svg";
import desktop_single_slided_big
    from "../../../images/thumbnail-generation/layouts/desktop/desktop_single_slided_big.svg";
import desktop_triple_variety from "../../../images/thumbnail-generation/layouts/desktop/desktop_triple_variety.svg";
import monitor_single_middle from "../../../images/thumbnail-generation/layouts/monitor/monitor_single_middle.svg";
import monitor_mobile_middle from "../../../images/thumbnail-generation/layouts/monitor/monitor_mobile_middle.svg";
import monitor_desktop_middle from "../../../images/thumbnail-generation/layouts/monitor/monitor_desktop_middle.svg";
import monitor_tablet_middle from "../../../images/thumbnail-generation/layouts/monitor/monitor_tablet_middle.svg";
import monitor_triple_variety from "../../../images/thumbnail-generation/layouts/monitor/monitor_triple_variety.svg";
import monitor_quadruple_variety
    from "../../../images/thumbnail-generation/layouts/monitor/monitor_quadruple_variety.svg";
import tablet_single_middle from "../../../images/thumbnail-generation/layouts/tablet/tablet_single_middle.svg";
import tablet_single_slided from "../../../images/thumbnail-generation/layouts/tablet/tablet_single_slided.svg";
import tablet_mobile_overlay from "../../../images/thumbnail-generation/layouts/tablet/tablet_mobile_overlay.svg";
import tablet_mobile_middle from "../../../images/thumbnail-generation/layouts/tablet/tablet_mobile_middle.svg";
import tablet_single_slided_big from "../../../images/thumbnail-generation/layouts/tablet/tablet_single_slided_big.svg";
import {mockups} from "../../../scripts/common/Mockups.js";
import device_style_no_device
    from "../../../images/thumbnail-generation/icons/device-styles/device_style_no-device.svg";
import device_style_semi_realistic
    from "../../../images/thumbnail-generation/icons/device-styles/device_style_semi-realistic.svg";
import device_style_simple from "../../../images/thumbnail-generation/icons/device-styles/device_style_simple.svg";
import device_style_outline from "../../../images/thumbnail-generation/icons/device-styles/device_style_outline.svg";
import device_style_browser from "../../../images/thumbnail-generation/icons/device-styles/device_style_browser.svg";
import SvgBackground from "../../../scripts/sections/projectGrid/SvgBackground.vue";
import {generateSuggestedColors} from "../../common/helpers.js";
import {SuggestedColorsEnum} from "../../common/enums.js";

export default {
    name: 'project-thumbnail',
    components: {SvgBackground, InlineSvg},
    props: {
        caseStudy: {
            default: null,
            type: Object,
        },
        forceSquared: {
            default: false, // Fix for 'Read more of my case studies' in Footer to only use squared thumbnails
            type: Boolean
        },
        isFirst: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            dynamicHeight: 0,
            resizeObserver: null,
            defaultImages: {
                mobile:mobile_placeholder,
                desktop:desktop_placeholder,
                tablet:tablet_placeholder,
                monitor:monitor_placeholder
            },
            deviceStyles: {
                no_device: {name:'No device', active:true, svg: device_style_no_device, avalibleSettings: [{type:'corner', name:'Corner radius'}]},
                semi_realistic: {name:'Semi-realistic', active:true, svg: device_style_semi_realistic, avalibleSettings: [{type:'color', name:'Device color'}]},
                simple: {name:'Simple', active:true, svg: device_style_simple, avalibleSettings: [{type:'color', name:'Device color'}]},
                outline: {name:'Outline', active:true, svg: device_style_outline, avalibleSettings: [{type:'color', name:'Device color'}]},
                browser: {name:'Browser', active:true, svg: device_style_browser, avalibleSettings: [{type:'color', name:'Device color'}, {type:'corner', name:'Corner radius'}]},
            },
            mockups: mockups,
            placeholder: mobile_placeholder,
            layouts: {
                mobile: {
                    single_middle: {type: 'mobile', svg: mobile_single_middle, mockupTypes: ['mobile']},
                    single_slided: {type: 'mobile', svg: mobile_single_slided, mockupTypes: ['mobile']},
                    dual_middle: {type: 'mobile', svg: mobile_dual_middle, mockupTypes: ['mobile', 'mobile']},
                    dual_slided: {type: 'mobile', svg: mobile_dual_slided, mockupTypes: ['mobile', 'mobile']},
                    triple_middle: {
                        type: 'mobile',
                        svg: mobile_triple_middle,
                        mockupTypes: ['mobile', 'mobile', 'mobile']
                    },
                    triple_slided: {
                        type: 'mobile',
                        svg: mobile_triple_slided,
                        mockupTypes: ['mobile', 'mobile', 'mobile']
                    },
                    //Second round
                    triple_middle_horizontal: {
                        type: 'mobile',
                        svg: mobile_triple_middle_horizontal,
                        mockupTypes: ['mobile', 'mobile', 'mobile']
                    },
                    triple_slided_rotated: {
                        type: 'mobile',
                        svg: mobile_triple_slided_rotated,
                        mockupTypes: ['mobile', 'mobile', 'mobile']
                    },
                    quadruple_slided: {
                        type: 'mobile',
                        svg: mobile_quadruple_slided,
                        mockupTypes: ['mobile', 'mobile', 'mobile', 'mobile']
                    },
                    quadruple_slided_rotated: {
                        type: 'mobile',
                        svg: mobile_quadruple_slided_rotated,
                        mockupTypes: ['mobile', 'mobile', 'mobile', 'mobile']
                    },

                },
                desktop: {
                    single_middle: {type: 'desktop', svg: desktop_single_middle, mockupTypes: ['desktop']},
                    single_slided: {type: 'desktop', svg: desktop_single_slided, mockupTypes: ['desktop']},
                    mobile_overlay: {
                        type: 'desktop',
                        svg: desktop_mobile_overlay,
                        mockupTypes: ['desktop', 'mobile']
                    },
                    mobile_middle: {
                        type: 'desktop',
                        svg: desktop_mobile_middle,
                        mockupTypes: ['desktop', 'mobile']
                    },
                    //Second round
                    single_slided_big: {type: 'desktop', svg: desktop_single_slided_big, mockupTypes: ['desktop']},
                    triple_variety: {
                        type: 'desktop',
                        svg: desktop_triple_variety,
                        mockupTypes: ['desktop', 'tablet', 'mobile']
                    }
                },
                monitor: {
                    single_middle: {type: 'monitor', svg: monitor_single_middle, mockupTypes: ['monitor']},
                    mobile_middle: {
                        type: 'monitor',
                        svg: monitor_mobile_middle,
                        mockupTypes: ['monitor', 'mobile']
                    },
                    desktop_middle: {
                        type: 'monitor',
                        svg: monitor_desktop_middle,
                        mockupTypes: ['monitor', 'desktop']
                    },
                    tablet_middle: {
                        type: 'monitor',
                        svg: monitor_tablet_middle,
                        mockupTypes: ['monitor', 'tablet']
                    },
                    triple_variety: {
                        type: 'monitor',
                        svg: monitor_triple_variety,
                        mockupTypes: ['monitor', 'tablet', 'mobile']
                    },
                    quadruple_variety: {
                        type: 'monitor',
                        svg: monitor_quadruple_variety,
                        mockupTypes: ['monitor', 'tablet', 'mobile', 'desktop']
                    }
                },
                tablet: {
                    single_middle: {type: 'tablet', svg: tablet_single_middle, mockupTypes: ['tablet']},
                    single_slided: {type: 'tablet', svg: tablet_single_slided, mockupTypes: ['tablet']},
                    mobile_overlay: {type: 'tablet', svg: tablet_mobile_overlay, mockupTypes: ['tablet', 'mobile']},
                    mobile_middle: {type: 'tablet', svg: tablet_mobile_middle, mockupTypes: ['tablet', 'mobile']},
                    //Second round
                    single_slided_big: {type: 'tablet', svg: tablet_single_slided_big, mockupTypes: ['tablet']},
                }
            },
        }
    },
    methods: {
        getMockupSrc(type) {

            let mockupsWithType = this.mockups[type][this.caseStudy.thumbnailData.deviceStyle.baseStyle];

            //TODO: this is an edge case for now as we don't have a mockup for this and we want to display it in a container still
            if(this.caseStudy.thumbnailData.deviceStyle.baseStyle === 'no_device') {
                return this.mockups[type].browser[1].svg;
            }

            let filteredForSettings = Object.values(mockupsWithType).filter(mockup => {
                let found = true;
                let avalibleSettings = this.deviceStyles[this.caseStudy.thumbnailData.deviceStyle.baseStyle].avalibleSettings.map(x => x.type);

                Object.entries(this.caseStudy.thumbnailData.deviceStyle.settings).forEach(([setting, value]) => {
                    if(avalibleSettings.includes(setting) && !(mockup.settings[setting] === value)) {
                        found = false;
                    }
                })
                return found
            })

            return filteredForSettings[0].svg
        },
        getMockupContentSrc(layoutType, imageNum) {
            if(this.caseStudy.thumbnailData.images[imageNum]) {
                if(this.caseStudy.thumbnailData.images[imageNum].cropped && this.caseStudy.thumbnailData.images[imageNum].cropped.url) {
                    return this.caseStudy.thumbnailData.images[imageNum].cropped.url
                }
                if(this.caseStudy.thumbnailData.images[imageNum].url) {
                    return this.caseStudy.thumbnailData.images[imageNum].url
                }
            }

            return this.defaultImages[layoutType]
        },

        // These 'calculateDynamicHeight' & 'resizeObserver' is needed because of a Firefox bug not calculating height correctly
        // if 'aspect-ratio' is set on parent and child has 'width: fit-content'
        // We need the height set in px after every thumbnail-layout change, generation and deletion as well as side panel open & close
        calculateDynamicHeight() {
            this.$nextTick(() => {
                if(this.$refs.mockupsContainer) {
                    // noinspection JSSuspiciousNameCombination
                    this.dynamicHeight = this.$refs.mockupsContainer.offsetWidth;
                }
            });
        },
        initResizeObserver() {
            this.$nextTick(() => {
                if (this.$refs.mockupsContainer) {
                    this.resizeObserver = new ResizeObserver(this.calculateDynamicHeight);
                    this.resizeObserver.observe(this.$refs.mockupsContainer);
                }
            });

        },
        destroyResizeObserver() {
            if (this.resizeObserver) {
                this.resizeObserver.disconnect();
                this.resizeObserver = null;
            }
        },
    },
    computed: {
        thumbnailSize() {
            // Fix for 'Read more of my case studies' in Footer to only use squared thumbnails
            if (this.forceSquared) return 'small';

            if(this.$store.state.portfolio.templateSettings.thumbnailLayout && this.$store.state.portfolio.templateSettings.thumbnailLayout.includes('mixed')) {
                if(this.$store.state.portfolio.templateSettings.thumbnailLayout.includes('large')) {
                    if(this.isFirst) {
                        return 'large';
                    } else {
                        return 'small';
                    }
                }
            } else {
                return this.$store.state.portfolio.templateSettings.tileDisplayMode ? this.$store.state.portfolio.templateSettings.tileDisplayMode.split('-')[0] : 'small';
            }

        },
        largeThumbnailTextPosition() {
            let templateSettings = this.$store.state.portfolio.templateSettings;
            if(templateSettings.tileDisplayMode === 'large' &&
                templateSettings.projectTitle.placement === 'on-thumbnail' &&
                templateSettings.projectTitle.visibility === 'visible') {
                return templateSettings.projectTitle.alignment.horizontal
            }
            else {
                return 'center'
            }
        },
        suggestedColorsUsed() {
            return this.caseStudy?.thumbnailData?.background?.color && !this.caseStudy.thumbnailData.background.color.startsWith('#') && Object.values(SuggestedColorsEnum).includes(this.caseStudy.thumbnailData.background.color)
        },
        suggestedColorOptions() {
            return generateSuggestedColors(this.colorStyles)
        },
        colorStyles() {
            return this.$store.state.portfolio.designSettings.colorStyles
        },
        backgroundStyle() {
            let style = {};

            if(this.caseStudy.thumbnailData.background.style === 'solid_color') {
                style['background-color'] = this.suggestedColorsUsed
                    ? this.suggestedColorOptions[this.caseStudy.thumbnailData.background.style.match(/^[^_]*/)[0]][this.caseStudy.thumbnailData.background.color][0]
                    : this.caseStudy.thumbnailData.background.color;
            }
            else if(this.caseStudy.thumbnailData.background.style === 'transparent') {
                style['background-color'] = 'transparent';
            }
            else if(this.caseStudy.thumbnailData.background.style === 'gradient') {
                style['background-color'] = this.suggestedColorsUsed
                    ? this.suggestedColorOptions[this.caseStudy.thumbnailData.background.style.match(/^[^_]*/)[0]][this.caseStudy.thumbnailData.background.color][1]
                    : this.caseStudy.thumbnailData.background.accentColor;
            }else {
                //Fallback
                style['background-color'] = '#FFFFFF';
            }
            return style;
        },
        isGenerated() {
            return this.caseStudy.settings.usingGeneratedThumbnail;
        },
        mainHeaderSection() {
            return this.caseStudy.sections.find(e => e.type === "MainHeader");
        },
        getBackgroundColor() {
            let bgColor = this.mainHeaderSection.backgroundColor
                ? this.mainHeaderSection.backgroundColor.startsWith("#")
                    ? this.mainHeaderSection.backgroundColor
                    : `#${this.mainHeaderSection.backgroundColor}`
                : null;

            return {backgroundColor: !this.caseStudy.image.url ? bgColor : null}
        },
        getImageUrl() {
            return this.caseStudy.image.croppedImageUrl ?
                this.caseStudy.image.croppedImageUrl :
                this.caseStudy.image.url
                    ? this.caseStudy.image.url
                    : !!this.mainHeaderSection.image.croppedImageUrl
                        ? this.mainHeaderSection.image.croppedImageUrl
                        : this.mainHeaderSection.image.url
                            ? this.$store.state.portfolio.templateSettings.theme === "theme_fifteen" ?
                                '/placeholder-images/template-15-thumbnail-' + this.caseStudy.order % 3  + '.svg'
                                :
                                this.mainHeaderSection.image.url
                            : !this.mainHeaderSection.backgroundColor
                                ? '/example_project_images/v2/empty-project-thumbnail.jpg'
                                : null
        },
        showOverlay() {
            return !!this.mainHeaderSection.overlay && this.mainHeaderSection.overlay.a > 0 && !this.caseStudy.image.url ;
        },
        showBlur() {
            return !!this.mainHeaderSection.blur && !this.caseStudy.image.url ;
        },
        setCustomClass() {
            return [this.showOverlay ? 'overlay' : null, this.showBlur ? 'blur' : null].toString().replace(',', ' ');
        },
        thumbnailHeight() {
            const aspectRatio = this.thumbnailSize === 'large' ? 2 : this.thumbnailSize === 'medium' ? 1.15 : 1

            return `${this.width / aspectRatio}px`
        }
    },
    mounted() {
        this.$nextTick(() => {
           if (this.isGenerated) {
               this.initResizeObserver();
               this.calculateDynamicHeight();
           }
        });

    },
    beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },
    watch: {
        thumbnailSize() {
            if (this.isGenerated) {
                this.calculateDynamicHeight();
            }
        },
        isGenerated(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.initResizeObserver();
                    this.calculateDynamicHeight();
                });
            } else {
                this.destroyResizeObserver();
            }
        },
        'caseStudy.thumbnailData': {
            handler() {
                if (this.isGenerated) {
                    this.$nextTick(() => {
                        this.initResizeObserver();
                        this.calculateDynamicHeight();
                    });
                }
            },
            deep:true
        }
    },
}

</script>

<style scoped lang="scss">

.generated-cover-container {
    grid-column: 1 / span 12;

    &.image-container {
        padding-top: 0% !important;
        height: 100% !important;
    }

    .background-color-container {

        width: 100%;

        .thumbnail-preview {
            position: relative;
            background-repeat: no-repeat;
            overflow: hidden;

            &.large {
                aspect-ratio: 2;
            }

            //Helper text
            .project-name {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-weight: 700;
                text-align: center;
                z-index: 2;
                width: 100%;
                height: 100%;
                padding: 25px;

                &.top {
                    justify-content: flex-start;
                }

                &.bottom {
                    justify-content: flex-end;
                }

                &.left {
                    align-items: flex-start;
                    text-align: left;
                }

                &.right {
                    align-items: flex-end;
                    text-align: right;
                }
            }

            .background-image {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }

            .mockups-container {
                z-index: 3;

                &.small {
                    width: 100%;
                    aspect-ratio: 1;
                }

                &.medium {
                    margin: auto;

                    width: 100%;
                    aspect-ratio: 1.15;

                    //Edge case, when mockup is on the side, we have to push to right

                    &.desktop {
                        &.single_slided {
                            margin-left: 50px;
                        }

                        &.mobile_middle {
                            margin-left: 50px;
                        }
                    }
                }

                &.large {
                    margin: auto;

                    height: 100%;
                    width: 50%;
                    aspect-ratio: 2;
                }

                position: relative;

                svg {
                    display: block;
                }

                .mockup-container {
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    position: absolute;
                    width: fit-content;
                    margin: auto;

                    .mockup-svg {
                        width: auto;
                        height: 100%;
                        position: relative;
                        z-index: 1;
                    }

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 100%;
                        max-height: 100%;

                    }
                }

                //default border radiuses
                .mobile-image {
                    border-top-right-radius: 11% 6%;
                    border-top-left-radius: 11% 6%;
                    border-bottom-left-radius: 11% 6%;
                    border-bottom-right-radius: 11% 6%;
                }

                .tablet-image {
                    border-top-right-radius: 4% 3%;
                    border-top-left-radius: 4% 3%;
                    border-bottom-left-radius: 4% 3%;
                    border-bottom-right-radius: 4% 3%;
                }

                //Base image widths and positions
                .mockup-container {

                    .simple-mobile-image {
                        height: 96%;
                        width: 90%;
                    }

                    .semi_realistic-mobile-image {
                        height: 96%;
                        width: 89%;
                    }

                    .outline-mobile-image {
                        height: 94%;
                        width: 88%;
                    }

                    .browser-mobile-image {
                        border-radius: 0;
                        height: 95.5%;
                        margin-top: 5%;
                    }

                    .simple-desktop-image {
                        height: 89%;
                        width: 78%;
                    }

                    .outline-desktop-image {
                        width: 81.5%;
                        height: 88.5%;
                        top: 48.2%;
                    }

                    .semi_realistic-desktop-image {
                        height: 89%;
                        width: 78%;
                    }

                    .browser-desktop-image {
                        border-radius: 0;
                        width: 99.9%;
                        height: 95%;
                        top: 52.5%;
                    }

                    .simple-tablet-image {
                        height: 94%;
                        width: 91%;
                    }

                    .outline-tablet-image {
                        height: 93.5%;
                        width: 90%;
                    }

                    .semi_realistic-tablet-image {
                        height: 94%;
                        width: 91%;
                    }

                    .browser-tablet-image {
                        border-radius: 0;
                        height: 95.5%;
                        top: 52%;
                        width: 99.5%;
                    }

                    //monitor
                    .simple-monitor-image {
                        top: 35%;
                        width: 96%;
                        height: 65%;
                    }

                    .outline-monitor-image {
                        top: 34.5%;
                        width: 95%;
                        height: 63.5%;
                    }

                    .semi_realistic-monitor-image {
                        top: 35%;
                        width: 96%;
                        height: 65%;
                    }

                    .browser-monitor-image {
                        top: 52.5%;
                        width: 99.9%;
                        height: 94.5%;
                    }

                    .no_device-tablet-image, .no_device-mobile-image, .no_device-desktop-image, .no_device-monitor-image {
                        width: 100%;
                        height: 100%;
                    }
                }

                //For no-device disable the border radius for all images
                &.no_device {
                    .mockup-svg {
                        visibility: hidden;
                    }

                    &.squared {
                        img {
                            border-radius: 0;
                        }
                    }

                    &.rounded {
                        img {
                            border-radius: 9px;
                        }
                    }
                }

                //settings
                &.rounded {
                    &.browser {
                        &.mobile {
                            img {
                                border-bottom-left-radius: 4px;
                                border-bottom-right-radius: 4px;
                            }
                        }

                        &.desktop {
                            img {
                                border-bottom-left-radius: 4px;
                                border-bottom-right-radius: 4px;
                            }
                        }

                        &.tablet {
                            img {
                                border-bottom-left-radius: 3px;
                                border-bottom-right-radius: 3px;
                            }
                        }

                        &.monitor {
                            img {
                                border-bottom-left-radius: 3px;
                                border-bottom-right-radius: 3px;
                            }
                        }
                    }
                }

                &.tablet .mockup-container {
                    height: 74%;
                }

                &.desktop .mockup-container {
                    height: 55%;
                }

                &.mobile .mockup-container {
                    height: 71%;
                }

                &.monitor {
                    .mockup-container {
                        height: 70%;
                    }

                    &.brower .mockup-container {
                        height: 50%;
                    }

                    &.no_device .mockup-container {
                        height: 50%;
                    }
                }

                &.small {
                    overflow: hidden;
                }

                &.mobile {
                    &.single_slided {
                        .mockup-container {
                            height: 119%;
                            top: 40%;
                        }

                    }

                    &.dual_middle {

                        .mockup-1 {
                            left: 40%;
                        }

                        .mockup-0 {
                            right: 40%;
                        }
                    }

                    &.dual_slided {

                        .mockup-1 {
                            bottom: 10%;
                            left: 40%;
                        }

                        .mockup-0 {
                            top: 10%;
                            right: 40%;
                        }
                    }

                    &.triple_middle {
                        .mockup-1 {
                            height: 47%;
                            right: 65%;
                        }

                        .mockup-2 {
                            height: 47%;
                            left: 65%;
                        }


                    }

                    &.triple_middle_horizontal {
                        .mockup-1 {
                            left: 73%;
                        }

                        .mockup-2 {
                            right: 73%;
                            left: unset;
                        }
                    }

                    &.triple_slided {
                        .mockup-0 {
                            right: 40%;
                        }

                        .mockup-1 {
                            left: 40%;
                            bottom: 77%;
                        }

                        .mockup-2 {
                            left: 40%;
                            top: 77%;
                        }

                    }

                    &.triple_slided_rotated {
                        .mockup-container {
                            transform: rotate(-30deg);
                        }

                        .mockup-0 {
                            right: 33%;
                            top: 20%;
                        }

                        .mockup-1 {
                            bottom: 87%;
                            left: -5%;
                        }

                        .mockup-2 {
                            left: 68%;
                            top: 43%;
                        }

                        &.browser,
                        &.no_device {
                            .mockup-1 {
                                bottom: 90%;
                                left: -10%;
                            }
                        }
                    }

                    &.quadruple_slided {
                        .mockup-0 {
                            right: 40%;
                            bottom: 60%;
                        }

                        .mockup-1 {
                            bottom: 90%;
                            left: 40%;
                        }

                        .mockup-2 {
                            left: 40%;
                            top: 60%;
                        }

                        .mockup-3 {
                            right: 40%;
                            top: 90%;
                        }

                        &.browser,
                        &.no_device {
                            .mockup-2 {
                                top: 65%;
                            }

                            .mockup-3 {
                                top: 95%;
                            }
                        }
                    }

                    &.quadruple_slided_rotated {
                        .mockup-container {
                            transform: rotate(-30deg);
                        }

                        .mockup-0 {
                            bottom: 68%;
                            right: 40%;
                        }

                        .mockup-1 {
                            bottom: 35%;
                            left: 66%;
                        }

                        .mockup-2 {
                            left: 35%;
                            top: 62%;
                        }

                        .mockup-3 {
                            left: unset;
                            right: 66%;
                            top: 40%;
                        }

                        &.browser,
                        &.no_device {
                            .mockup-0 {
                                bottom: 70%;
                                right: 41%;
                            }

                            .mockup-2 {
                                left: 36%;
                                top: 64%;
                            }
                        }
                    }
                }

                &.desktop {
                    &.single_slided {
                        .mockup-container {
                            height: 71%;
                        }

                        .mockup-0 {
                            left: 5%;
                        }

                        &.no_device, &.browser {
                            .mockup-0 {
                                left: 15%;
                            }
                        }
                    }

                    &.mobile_overlay {
                        .mockup-0 {
                            height: 55%;
                            right: 5%;
                        }

                        .mockup-1 {
                            img {
                                z-index: 1;
                            }

                            height: 48%;
                            left: 60%;
                            top: 20%;
                        }
                    }

                    &.mobile_middle {
                        .mockup-0 {
                            height: 65%;
                            left: 40%;
                        }

                        .mockup-1 {
                            height: 50%;
                            right: 50%;
                        }
                    }

                    &.single_slided_big {
                        .mockup-0 {
                            height: 90%;
                            left: 5%;
                            top: 25%;
                        }

                        &.browser {
                            .mockup-0 {
                                left: -8%;
                            }
                        }

                        &.no_device, &.browser {
                            .mockup-0 {
                                left: 15%;
                            }
                        }
                    }

                    &.triple_variety {
                        .mockup-0 {
                            height: 50%;
                            right: 10%;
                        }

                        .mockup-1 {
                            height: 45%;
                            z-index: 1;
                            left: 47%;
                            top: 17%;
                        }

                        .mockup-2 {
                            height: 35%;
                            top: 35%;
                            left: 74%;
                            z-index: 2;
                        }
                    }

                }

                &.tablet {
                    &.single_slided {
                        .mockup-container {
                            height: 107%;
                            top: 30%;
                        }
                    }

                    &.mobile_overlay {
                        .mockup-0 {
                            top: -7%;
                        }

                        .mockup-1 {
                            img {
                                z-index: 1;
                            }

                            height: 60%;
                            top: 28%;
                            left: 45%;
                        }
                    }

                    &.mobile_middle {
                        .mockup-0 {
                            left: 30%;
                        }

                        .mockup-1 {
                            right: 52%;
                            height: 50%;
                        }
                    }

                    &.single_slided_big {
                        .mockup-0 {
                            height: 140%;
                            left: 18%;
                            top: 65%;
                        }

                        &.brower {
                            .mockup-0 {
                                left: 5%
                            }
                        }

                        &.no_device {
                            .mockup-0 {
                                left: 15%;
                            }
                        }
                    }
                }

                &.monitor {
                    &.single_middle {
                        &.browser {
                            .mockup-0 {
                                height: 51%;
                            }
                        }
                    }

                    &.mobile_middle {
                        .mockup-0 {
                            height: 60%;
                            right: 15%;
                        }

                        .mockup-1 {
                            height: 36%;
                            z-index: 1;
                            left: 54%;
                            top: 30%;
                        }

                        &.no_device, &.browser {
                            .mockup-0 {
                                height: 45%;
                            }
                        }
                    }

                    &.tablet_middle {
                        .mockup-0 {
                            height: 60%;
                            right: 15%;
                        }

                        .mockup-1 {
                            height: 45%;
                            z-index: 1;
                            left: 55%;
                            top: 25%;
                        }

                        &.no_device, &.browser {
                            .mockup-0 {
                                height: 45%;
                            }
                        }
                    }

                    &.desktop_middle {
                        .mockup-0 {
                            height: 60%;
                            right: 15%;
                        }

                        .mockup-1 {
                            height: 31%;
                            z-index: 1;
                            left: 48%;
                            top: 34%;
                        }

                        &.no_device, &.browser {
                            .mockup-0 {
                                height: 45%;
                            }
                        }
                    }

                    &.triple_variety {
                        .mockup-0 {
                            height: 60%;
                            right: 15%;
                        }

                        .mockup-1 {
                            height: 45%;
                            z-index: 1;
                            left: 47%;
                            top: 25%;
                        }

                        .mockup-2 {
                            height: 36%;
                            top: 41%;
                            left: 74%;
                            z-index: 2;
                        }

                        &.no_device, &.browser {
                            .mockup-0 {
                                height: 45%;
                            }
                        }
                    }

                    &.quadruple_variety {
                        .mockup-0 {
                            height: 60%;
                        }

                        .mockup-1 {
                            height: 45%;
                            z-index: 1;
                            left: 55%;
                            top: 27%;
                        }

                        .mockup-2 {
                            height: 34%;
                            top: 42%;
                            left: 78%;
                            z-index: 2;
                        }

                        .mockup-3 {
                            height: 36%;
                            right: 40%;
                            top: 34%;
                            z-index: 2;
                        }

                        &.no_device, &.browser {
                            .mockup-0 {
                                height: 45%;
                            }
                        }
                    }
                }

                //size specific positions
                //Need to tweak for large a bit
                &.large {
                    //Align mockup based on text position in inside mockup
                    &.align-text-left {
                        margin-right: 0;

                        &.desktop {
                            &.single_slided {
                                margin-right: -10px;
                            }

                            &.single_slided_big {
                                margin-right: -25px;
                            }
                        }

                        &.tablet {
                            &.mobile_middle {
                                margin-right: 15px;
                            }

                            &.single_slided_big {
                                margin-right: -20px;
                            }
                        }

                    }

                    &.align-text-right {
                        margin-left: 0;

                        &.desktop {
                            &.mobile_middle {
                                transform: scaleX(-1);
                                .mockup-container {
                                    transform: scaleX(-1);
                                }
                            }

                            &.single_slided {
                                margin-left: -30px;

                            }

                            &.single_slided_big {
                                margin-left: -25px;
                            }
                        }

                        &.tablet {
                            &.mobile_middle {
                                transform: scaleX(-1);
                                margin-left: 15px;
                                .mockup-container {
                                    transform: scaleX(-1);
                                }
                            }

                            &.single_slided_big {
                                margin-left: -25px;
                            }
                        }
                    }

                    &.tablet {
                        &.single_slided_big {
                            .mockup-0 {
                                height: 140%;
                                left: 5%;
                                top: 65%;
                            }
                        }

                        &.mobile_middle {
                            .mockup-0 {
                                left: 40%;
                            }

                            .mockup-1 {
                                right: 50%;
                            }
                        }
                    }

                    &.desktop {
                        &.single_slided_big {
                            .mockup-0 {
                                height: 90%;
                                top: 25%;
                                left: -20%;
                            }
                        }

                        &.mobile_middle {
                            .mockup-0 {
                                left: 25%;
                            }

                            .mockup-1 {
                                right: 100%;
                            }
                        }
                    }

                    &.monitor {
                        &:not(.align-text-right):not(.align-text-left) {
                            &.quadruple_variety {
                                .mockup-0 {
                                    height: 60%;
                                }

                                .mockup-1 {
                                    left: 70%;
                                }

                                .mockup-2 {
                                    top: 45%;
                                    left: 92%;
                                }

                                .mockup-3 {
                                    left: unset;
                                    right: 66%;
                                }

                                &.no_device, &.browser {
                                    .mockup-0 {
                                        height: 45%;
                                    }
                                }
                            }
                        }
                    }
                }

                &.medium {
                    &.mobile {
                        &.triple_middle_horizontal {
                            .mockup-1 {
                                left: 78%;
                            }

                            .mockup-2 {
                                right: 78%;
                            }
                        }
                    }

                    &.desktop {
                        &.single_slided_big {
                            margin-left: 80px;
                        }

                        &.single_slided {
                            margin-left: 80px;
                        }
                    }

                    &.tablet {
                        &.single_slided_big {
                            margin-left: 80px;
                        }
                    }
                }
            }
        }
    }
}

</style>