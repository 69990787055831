import {
    NavigationLayoutEnum,
    NavigationTypeEnum,
    NavigationStyleEnum,
    NavigationButtonStyleEnum,
    SocialLinkTypeEnum,
    SocialLinkStyleEnum,
    SocialLinksLocationEnum
} from "../../common/enums.js";

export const defaultNavigationData = {
    type: NavigationTypeEnum.ABOVE,
    layout: NavigationLayoutEnum.SIMPLE_LEFT,
    style: NavigationStyleEnum.SIMPLE,
    fill: true,
    defaultDarkNav: true, // only used on front end to define the nav style in portfolio case studies
    socialLinksLocation: null,
    navigationItems: {
        ownerId: {
            visible: false
        },
        button: {
            style: NavigationButtonStyleEnum.BOLD
        },
        socialLink: {
            type: SocialLinkTypeEnum.TEXT,
            style: SocialLinkStyleEnum.SIMPLE,
            weight: null,
            upperCase: false
        },
    }
}

export const navigationData = {
    //Tesler
    theme_eighteen: {
        layout: NavigationLayoutEnum.SIMPLE_RIGHT,
        fill: false,
        navigationItems: {
            ...defaultNavigationData.navigationItems,
            ownerId: {
                visible: true
            }
        }
    },
    //Aurora
    theme_seventeen: {
        fill: false,
        type: NavigationTypeEnum.BELOW,
        layout: NavigationLayoutEnum.SIMPLE_MIDDLE,
        navigationItems: {
            ...defaultNavigationData.navigationItems,
            button: {
                style: NavigationButtonStyleEnum.UNDERLINE_REGULAR
            }
        }
    },
    //Norman
    theme_sixteen: {
        layout: NavigationLayoutEnum.SIMPLE_RIGHT,
        socialLinksLocation: SocialLinksLocationEnum.INLINE_BELOW_TEXT,
        navigationItems: {
            ...defaultNavigationData.navigationItems,
            button: {
                style: NavigationButtonStyleEnum.BOX_PILL
            },
            socialLink: {
                ...defaultNavigationData.navigationItems.socialLink,
                type: SocialLinkTypeEnum.ICON
            }
        }
    },
    //Jakob
    theme_fourteen: {
        layout: NavigationLayoutEnum.SIMPLE_RIGHT,
        navigationItems: {
            ...defaultNavigationData.navigationItems,
            ownerId: {
                visible: true
            }
        }
    },
    //Nelson
    theme_thirteen: {
        layout: NavigationLayoutEnum.SIMPLE_RIGHT,
        socialLinksLocation: SocialLinksLocationEnum.INLINE_STICK_TO_BOTTOM,
        navigationItems: {
            ...defaultNavigationData.navigationItems,
            ownerId: {
                visible: true
            },
            button: {
                style: NavigationButtonStyleEnum.UNDERLINE_SMALL
            },
            socialLink: {
                ...defaultNavigationData.navigationItems.socialLink,
                type: SocialLinkTypeEnum.ICON
            }
        }
    },
    //Otis
    theme_twelve: {
        layout: NavigationLayoutEnum.SIMPLE_LEFT
    },
    //Bauhaus
    theme_eleven: {
        layout: NavigationLayoutEnum.SIMPLE_MIDDLE,
        navigationItems: {
            ...defaultNavigationData.navigationItems,
            button: {
                style: NavigationButtonStyleEnum.UNDERLINE_SMALL
            }
        }
    },
    //Orielle
    theme_ten: {
        layout: NavigationLayoutEnum.SIDES,
        socialLinksLocation: SocialLinksLocationEnum.NAVBAR,
        fill: false,
        navigationItems: {
            ...defaultNavigationData.navigationItems,
            button: {
                ...defaultNavigationData.navigationItems.button,
                style: NavigationButtonStyleEnum.UNDERLINE_REGULAR
            },
            socialLink: {
                ...defaultNavigationData.navigationItems.socialLink,
                style: SocialLinkStyleEnum.UNDERLINE_REGULAR
            }
        }
    },
    //Anthra
    profile_theme_nine: {
        type: NavigationTypeEnum.BELOW,
        socialLinksLocation: SocialLinksLocationEnum.INLINE_ABOVE_TEXT,
        navigationItems: {
            ...defaultNavigationData.navigationItems,
            socialLink: {
                ...defaultNavigationData.navigationItems.socialLink,
                type: SocialLinkTypeEnum.ICON,
                style: SocialLinkStyleEnum.BOX_PILL
            }
        }
    },
    //Nominee
    theme_eight: {
        layout: NavigationLayoutEnum.SIMPLE_RIGHT,
        navigationItems: {
            ...defaultNavigationData.navigationItems,
            ownerId: {
                visible: true
            },
            button: {
                ...defaultNavigationData.navigationItems.button,
                style: NavigationButtonStyleEnum.UNDERLINE_REGULAR
            }
        }
    },
    //Uncurled
    theme_seven: {
        layout: NavigationLayoutEnum.LINE_MIDDLE,
        socialLinksLocation: SocialLinksLocationEnum.INLINE_BELOW_TEXT,
        navigationItems: {
            ...defaultNavigationData.navigationItems,
            button: {
                ...defaultNavigationData.navigationItems.button,
            },
            socialLink: {
                ...defaultNavigationData.navigationItems.socialLink,
                type: SocialLinkTypeEnum.ICON,
                style: SocialLinkStyleEnum.BOX_SHARP
            }
        }
    },
    //Poster
    theme_six: {
        layout: NavigationLayoutEnum.SIMPLE_MIDDLE,
        socialLinksLocation: SocialLinksLocationEnum.OFF_GRID,
        navigationItems: {
            ...defaultNavigationData.navigationItems,
            socialLink: {
                ...defaultNavigationData.navigationItems.socialLink,
                type: SocialLinkTypeEnum.ICON,
                style: SocialLinkStyleEnum.BOX_SHARP
            }
        }
    },
    //Panorama
    theme_five: {
        layout: NavigationLayoutEnum.SIDES,
        fill: false,
        socialLinksLocation: SocialLinksLocationEnum.NAVBAR,
    },
    //Cubic
    profile_theme_four: {
        socialLinksLocation: SocialLinksLocationEnum.INLINE_BELOW_TEXT,
        navigationItems: {
            ...defaultNavigationData.navigationItems,
            button: {
                ...defaultNavigationData.navigationItems.button
            },
            socialLink: {
                ...defaultNavigationData.navigationItems.socialLink,
                type: SocialLinkTypeEnum.ICON,
                style: SocialLinkStyleEnum.BOX_PILL
            }
        }
    },
    //Axis
    theme_three: {
        layout: NavigationLayoutEnum.MIDDLE,
        socialLinksLocation: SocialLinksLocationEnum.INLINE_BELOW_TEXT,
        navigationItems: {
            ...defaultNavigationData.navigationItems,
            button: {
                ...defaultNavigationData.navigationItems.button
            },
            socialLink: {
                ...defaultNavigationData.navigationItems.socialLink,
                type: SocialLinkTypeEnum.ICON,
                style: SocialLinkStyleEnum.BOX_PILL_FILL
            }
        }
    },
    //Alaine
    theme_two: {
        layout: NavigationLayoutEnum.SIMPLE_LEFT,
        type: NavigationTypeEnum.INLINE_BELOW_TEXT,
        socialLinksLocation: SocialLinksLocationEnum.NAVBAR,
        navigationItems: {
            ...defaultNavigationData.navigationItems,
            button: {
                ...defaultNavigationData.navigationItems.button,
                style: NavigationButtonStyleEnum.BOX_PILL_FILL
            },
            socialLink: {
                ...defaultNavigationData.navigationItems.socialLink,
                type: SocialLinkTypeEnum.ICON,
                style: SocialLinkStyleEnum.BOX_PILL_FILL
            }
        }
    },
    //Twee
    theme_one: {
        layout: NavigationLayoutEnum.SIMPLE_LEFT,
        type: NavigationTypeEnum.INLINE_BELOW_TEXT,
        socialLinksLocation: SocialLinksLocationEnum.NAVBAR,
        navigationItems: {
            ...defaultNavigationData.navigationItems,
            button: {
                ...defaultNavigationData.navigationItems.button,
                style: NavigationButtonStyleEnum.BOX_SHARP
            },
            socialLink: {
                ...defaultNavigationData.navigationItems.socialLink,
                type: SocialLinkTypeEnum.ICON,
                style: SocialLinkStyleEnum.BOX_SHARP
            }
        }
    },
    // Baze
    profile_theme_default: {
        socialLinksLocation: SocialLinksLocationEnum.INLINE_BELOW_TEXT,
        navigationItems: {
            ...defaultNavigationData.navigationItems,
            button: {
                ...defaultNavigationData.navigationItems.button,
                style: NavigationButtonStyleEnum.UNDERLINE_REGULAR
            },
            socialLink: {
                ...defaultNavigationData.navigationItems.socialLink,
                type: SocialLinkTypeEnum.ICON,
                style: SocialLinkStyleEnum.BOX_PILL
            }
        }
    },
}

