<template>
    <div v-if="section.media.type === 'Image'" class="image">
        <img
            ref="img"
            @load="imageLoaded ? imageLoaded($refs.img) : null"
            loading="lazy"
            :src="getImageUrl"
            :data-zoom="setZoom"
            :data-zoomable="setZoom"
            :alt="section.media.altTag ? altTag : defaultCaption"
            :width="section.media.width"
            :height="section.media.height"
        />

    </div>
    <div v-else-if="section.media.type === 'Video'" :class="['embed', 'embed-type-' + section.media.type.toLowerCase(),  'noHeightValue']">
        <iframe v-if="section.media.url" :src="section.media.url" loading="lazy" allowfullscreen="false" frameborder="0"></iframe>
    </div>
    <!-- TODO: container issue -->
    <div v-else-if="section.media.type === 'Prototype' || section.media.type === 'Media'">
        <div v-if="section.media.height" :class="['embed', 'embed-type-' + section.media.type.toLowerCase()]">
            <iframe v-if="section.media.url" :src="section.media.url" loading="lazy" :height="section.media.height" :width="'100%'" frameborder="0" allowTransparency="true" allowfullscreen="false"></iframe>
        </div>
        <div v-else :class="['embed', 'embed-type-' + section.media.type.toLowerCase(), 'noWidthValue', 'noHeightValue']">
            <iframe v-if="section.media.url" :src="section.media.url" loading="lazy" allowTransparency="true" allowfullscreen="false" frameborder="0"></iframe>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PlaceMediaContent",
        props:['section', 'defaultCaption', 'placeholderImage'],
        computed: {
            setZoom() {
                return this.section.type !== 'MainHeader'
                    && (!this.section.media.displayMode || this.section.media.displayMode && this.section.media.displayMode !== 'full')
                    && (!this.section.media.frame || this.section.media.frame && this.section.media.frame === 'no-mockup')
            },
            altTag() {
                return this.section.media.altTag.replace('\"', '&quot;')
            },
            getImageUrl() {
                return this.section.media.url ? this.section.media.croppedImageUrl ? this.section.media.croppedImageUrl : this.section.media.url : "/example_project_images/" + this.placeholderImage
            },
        },
        methods: {
            imageLoaded(img) {
                if (this.setZoom && !!img && !!img.dataset.zoomable && img.naturalWidth > img.width) {
                    this.$store.state.zoom.attach(img);
                } else {
                    this.$store.state.zoom.detach(img);
                }
                this.$store.commit("recalculateScrollTriggers");
            }}
    }
</script>