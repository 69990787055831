<template>
    <div class="error-wrapper">
        <div class="error">
            <div :class="'error-picto error-' + error.code"></div>
            <div class="error-message">{{error.title}}</div>
            <div class="error-exp">{{error.message}}</div>
            <a href="/" class="btn--error">Go to homepage</a>
        </div>
    </div>
</template>

<script>
export default {
    name: "ErrorPage",
    props:['error']
}
</script>