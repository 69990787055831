export const defaultThumbnailData = {
    layout: {
        type:'mobile',
        version:'single_middle',
    },
    deviceStyle:{
        baseStyle:'semi_realistic',
        settings: {
            corner:'rounded',
            color:'dark',
            position:'portrait'
        }
    },
    background: {
        style: 'solid_color',
        color: '#F6F6F6',
        accentColor: '#FFFFFF00',
        gradient:'radial_1'
    },
    images: [
        {url:null, cropped:{url:null, coordinates:null }},
        {url:null, cropped:{url:null, coordinates:null }},
        {url:null, cropped:{url:null, coordinates:null }},
        {url:null, cropped:{url:null, coordinates:null }}
    ],
}

export const thumbnailDesignData = {
    //Tesler
    theme_eighteen: {
        background: {
            color:'#FFFFFF1A'
        }
    },
    //Aurora
    theme_seventeen: {
        background: {
            color:'#0000001A'
        }
    },
    //Norman
    theme_sixteen: {
        background: {
            color: '#0000001A',
        },
    },
    //Jakob
    theme_fourteen: {
        background: {
            color:'#0000001A'
        }
    },
    //Nelson
    theme_thirteen: {
        background: {
            color:'#0000001A'
        }
    },
    //Otis
    theme_twelve: {
        background: {
            color:'#0000001A'
        }
    },
    //Bauhaus
    theme_eleven: {
        background: {
            color:'#0000001A'
        }
    },
    //Orielle
    theme_ten: {
        background: {
            color:'#FFFFFF1A'
        }
    },
    //Anthra
    profile_theme_nine: {
        background: {
            color:'#0000001A'
        }
    },
    //Nominee
    theme_eight: {
        background: {
            color:'#FFFFFF1A'
        }
    },
    //Uncurled
    theme_seven: {
        background: {
            color:'#0000001A'
        }
    },
    //Poster
    theme_six: {
        background: {
            color:'#0000001A'
        }
    },
    //Panorama
    theme_five: {
        background: {
            color:'#FFFFFF1A'
        }
    },
    //Cubic
    profile_theme_four: {
        background: {
            color:'#0000001A'
        }
    },
    //Axis
    theme_three: {
        background: {
            color:'#0000001A'
        }
    },
    //Alaine
    theme_two: {
        background: {
            color:'#0000001A'
        }
    },
    //Twee
    theme_one: {
        background: {
            color:'#0000001A'
        }
    },
    // Baze
    profile_theme_default: {
        background: {
            color:'#0000001A'
        }
    },

}