<template>
    <div :id="'section-' + section.index" class="ufo-sec ufo-sec--navigation" :style="[colors]">
        <ToggleGrid />
        <div ref="processBar"
             :class="['sticky-header', {sticked : stuck}, {'sticked-hidden' : hideStuckVersion}, {'show-dd' : showMobileProcessSteps}, {dark:portfolioIsDark}]"
             id="sticky-header"
             :data-start="setStartSection"
             :data-end="setEndSection"
        >
            <div class="grid">
                <div class="navigation-mobile-display">
                    <div class="navigation-step-display nav-step checked"></div>
                    <div class="navigation-dropdown-toggle" @click="toggleProcessSteps()">
                        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.60958 0.687671C8.78209 0.47204 9.09674 0.437079 9.31237 0.609584C9.528 0.782089 9.56296 1.09674 9.39045 1.31237L5.39045 5.31237C5.19029 5.56257 4.80975 5.56257 4.60958 5.31237L0.609584 1.31237C0.437079 1.09674 0.47204 0.782089 0.687671 0.609584C0.903302 0.437079 1.21795 0.47204 1.39045 0.687671L5.00002 4.19963L8.60958 0.687671Z" fill="var(--section-h2-color, #ffffff)"/>
                        </svg>
                    </div>
                </div>
                <div ref="processSteps" :class="['navigation-steps', section.navigationItems.length > 4 ? 'long' : '']" :style="{'--checkedStepColor': $store.getters.getProjectColor('accent')}">
                    <template v-for="(step, index) in section.navigationItems">
                        <AnimationWrapper
                            :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                            :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                            :delay-animation="index ? index * 0.1 : 0"
                        >
                            <template #animationContent>
                                <div class="nav-step" :key="`step-${step.sectionIndex}`">
                                    <div class="nav-step-content">
                                        <a :data-href="step.sectionIndex ? '#section-' + step.sectionIndex :  '#section-' + section.index"
                                           :href="step.sectionIndex ? '#section-' + step.sectionIndex :  '#section-' + section.index"
                                           @click="(evt) => scrollToItem(evt, step.sectionIndex ? '#section-' + step.sectionIndex :  '#section-' + section.index)"
                                           class="step-link"
                                        >
                                            <div class="step-number">{{ index + 1 }}</div>
                                            <div class="step-name">{{ step.name }}</div>
                                        </a>
                                    </div>
                                </div>
                            </template>
                        </AnimationWrapper>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ToggleGrid from "../../../common/components/ToggleGrid.vue";
import AnimationWrapper from "../../../common/components/AnimationWrapper.vue";
import {hexToHSL} from "../../../common/helpers.js";
import {PageTypesEnum} from "../../../common/enums.js";

export default {
    name: "NavigationSection",
    components: {AnimationWrapper, ToggleGrid },
    props: ['section'],
    data() {
        return {
            stuck: false,
            hideStuckVersion: false,
            showMobileProcessSteps: false
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        scrollToItem(e, href) {
            let duration = 300;
            let fps = 60;

            e.preventDefault();
            if(this.stuck) {
                this.showMobileProcessSteps = false;
            }

            let target = document.querySelector(href);
            let scrollDistanceFixer = this.stuck ? 68 : this.$refs.processBar.offsetHeight + 68;

            let element = target, targetOffset = 0;
            while (element && element !== document.body) {
                targetOffset += element.offsetTop;
                element = element.offsetParent;
            }
            let currentOffset = window.pageYOffset || document.body.scrollTop;
            let difference = Math.abs(currentOffset - targetOffset);
            if (!difference) return;
            let frames = parseInt(duration / 1000 * fps);
            let tick = duration / frames;
            let perFrame = difference / frames;
            let direction = (targetOffset > currentOffset) ? 1 : -1;
            let timer = setInterval(() => {
                currentOffset = window.pageYOffset || document.body.scrollTop;
                difference = Math.abs(currentOffset - targetOffset + scrollDistanceFixer);
                if (difference < perFrame) {
                    scrollBy(0, difference * direction);
                    clearInterval(timer);
                    return;
                }
                scrollBy(0, perFrame * direction);
                if (window.pageYOffset + window.innerHeight === document.body.scrollHeight) clearInterval(timer);
            }, tick);

            // TODO if Safari support smooth scroll behavior change the scroll control to this
            // let targetPosition = target.getBoundingClientRect().top + window.scrollY - scrollDistanceFixer;
            // window.scrollTo({
            //     top: targetPosition,
            //     left: 0,
            //     behavior: 'smooth'
            // })
        },
        handleScroll() {
            // sticky header
            var lastStep = 'section-'+this.$refs.processBar.getAttribute('data-end'),
                startY = this.$refs.processBar.parentNode.getBoundingClientRect().top,  // stick starts here
                endY = this.$refs.processBar.getAttribute('data-end') ? document.getElementById(lastStep).getBoundingClientRect().top + document.getElementById(lastStep).getBoundingClientRect().height - 70 : 0; // stick ends here

            if (startY >= 0) {
                this.stuck = false;
            } else if (endY <= 0) {
                this.hideStuckVersion = true;
                this.showMobileProcessSteps = false;
            } else if (startY <= 0) {
                this.hideStuckVersion = false;
                this.stuck = true;
                for (var k = 0; k < this.$refs.processSteps.querySelectorAll('.nav-step').length; k++) {
                    var currentStickyStep = this.$refs.processSteps.querySelectorAll('.nav-step')[k],
                        stepAnchor = document.getElementById(currentStickyStep.querySelector('.step-link').getAttribute('href').split('#')[1]),
                        stepY = stepAnchor ? stepAnchor.getBoundingClientRect().y : window.innerHeight + 1;
                    ( stepY <= 70 ) ? currentStickyStep.classList.add('checked') : currentStickyStep.classList.remove('checked');
                }
                this.$refs.processBar.querySelector('.navigation-step-display').innerHTML = Array.from(this.$refs.processBar.querySelectorAll('.nav-step.checked')).pop().innerHTML;
            }
            this.$store.commit("recalculateScrollTriggers");
        },
        toggleProcessSteps() {
            this.showMobileProcessSteps = !this.showMobileProcessSteps
        },
    },
    computed: {
        project() {
            return this.$store.state.view.project;
        },
        colors() {
            return { '--checkedStepColor': this.project.designSettings.colorStyles.navigationHover.color };
        },
        setStartSection() {
            if(this.section.navigationItems) {
                return this.section.navigationItems.find(e => e.sectionIndex) ? this.section.navigationItems.find(e => e.sectionIndex).sectionIndex : 1;
            }
        },
        setEndSection() {
            if(this.section.navigationItems) {
                const reversed = JSON.parse(JSON.stringify(this.section.navigationItems)).reverse();
                return reversed.find(e => e.sectionIndex) ? reversed.find(e => e.sectionIndex).sectionIndex : null;
            }
        },
        portfolioIsDark() {
            return hexToHSL(this.colorStyles.background.color).l < 0.2;
        },
        colorStyles() {
            return this.isCaseStudy && !this.usingGlobalColorStyles ?
                this.$store.state.view.project.designSettings.colorStyles :
                this.$store.state.view.portfolio.designSettings.colorStyles;
        },
        isCaseStudy() {
            return this.$store.state.project && this.$store.state.project.type === PageTypesEnum.CASE_STUDY;
        },
        usingGlobalColorStyles() {
            return this.$store.state.view.project.type === PageTypesEnum.CASE_STUDY && this.$store.state.view.project.designSettings.globalColorStyles;
        },
    }
}
</script>