<template>
    <ToggleGrid/>
    <UserName />
    <div v-if="checkPath" class="profile-hero">
        <UserThemeCoverImage />
    </div>
    <GetHeroNavigation :show-home-button="true" />
    <div v-if="checkPath" class="profile-bio">
        <UserPortfolioDescription />
    </div>
</template>

<script>
    import UserName from "../../common/user/UserName.vue";
    import UserThemeCoverImage from "../../common/user/UserThemeCoverImage.vue";
    import UserPortfolioDescription from "../../common/user/UserPortfolioDescription.vue";
    import ToggleGrid from "../../../../common/components/ToggleGrid.vue";
    import GetHeroNavigation from "../../common/hero/GetHeroNavigation.vue";

    export default {
        name: "ProfileThemeFive",
        components: {
            GetHeroNavigation,
            ToggleGrid,
            UserPortfolioDescription,
            UserThemeCoverImage,
            UserName
        },
        computed: {
            portfolio() {
              return this.$store.state.view.portfolio
            },
            templateSettings() {
              return this.$store.state.view.portfolio.templateSettings
            },
            checkPath() {
                return !window.location.pathname.includes('/p/') &&
                    !window.location.pathname.includes('/about') &&
                    !window.location.pathname.includes('/contact')
            },
        }
    }
</script>