import autosize from 'autosize';

export default {
    getApiKey() {
        return 'AIzaSyACuy0HND-c__u3dCpcuIWhpnxw0IfOOoo';
    },
    downloadFonts(fontStyles) {
        if (fontStyles) {
            if(fontStyles.headline !== fontStyles.text) {
                for (let key in fontStyles) {
                    //let url = this.getFontURL(fontStyles[key], key === 'headline' ? [700] : [400,700]);
                    if(key !== 'id') this.getFontURL(fontStyles[key], [400, 700]);
                }
            } else {
                this.getFontURL(fontStyles.headline);
            }
        }
    },
    downloadSingleFonts(textStyles, projectEditor) {
        if(textStyles) {
            textStyles.forEach( (elem, index) => {
                if(elem.font) {
                    this.getFontURL(elem.font, [400, 700, 800, 900]);
                }
                if(projectEditor && index === textStyles.length - 1) {
                    setTimeout(() => {
                        autosize(document.querySelectorAll('textarea'));
                        setTimeout(() => { autosize.update(document.querySelectorAll('textarea')) }, 10);
                    }, 300);
                }
            })
        }
    },
    getFontURL(font, variants) {
        let url = 'https://fonts.googleapis.com/css2?family=';
        url += font.replace(/ /g, '+');
        //url += ":".concat(variants[0]);
        url += ":wght@400;700;800;900&display=swap";
        //for (let i = 1; i < variants.length; i += 1) {
        //    url += ",".concat(variants[i]);
        //}
        this.creatLinkElement(url);
    },
    //generate url with google css2 API endpoint
    generateCss2FontUrl(variants) {
        let italic = "";
        let attributes = "";
        //if any variant have italic, add it to the string
        if(variants.map(x => x.includes('italic')).length) {
            italic +='ital,'
        }

        //mapping for font types
        const avalibleFontTypesMapping = {
            "light":'300',
            "regular":'400',
            "medium":'500',
            "semibold":'600',
            "bold":'700',
            "heavy":'900'
        }

        let axisA = "";
        let axisB = "";

        //Map on avalible types, and put values to touple in order.
        //Example: 0,200;1,200 - means italic + italic 200
        Object.entries(avalibleFontTypesMapping).forEach(([key, value]) => {
            let variant = variants.find(x => x.includes(key));
            if(variant) {
                if(italic.includes("ital")) {
                    axisA += "0," + value + ";"
                    axisB += "1," + value + ";"
                }
                else {
                    axisA += value + ";";
                }
            }
        })
        attributes += axisA
        attributes += axisB

        //build string
        return `:${italic}wght@${attributes.slice(0, attributes.length-1)}`
    },
    //generate url for specific font
    getFontUrlWithParams(fontName, variants) {
        let url = `https://fonts.googleapis.com/css2?family=${fontName.replace(/ /g, '+')}${this.generateCss2FontUrl(variants)}&display=swap`;
        this.creatLinkElement(url)
    },
    creatLinkElement(url) {
        if(!!document.querySelector(`link[href="${url}"]`)) return;

        let preLoad = document.createElement('link');
        preLoad.rel="preload"
        preLoad.href=url
        preLoad.as="style"
        preLoad.crossOrigin="anonymous"
        document.head.appendChild(preLoad);

        let link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = url;
        document.head.appendChild(link);
    },
    getFontPickerOptions(type) {
        const globalOptions = {
            limit: 1009,
            variants: ['regular'],
            sort: 'popularity'
        }
        const fontPickers = {
            // group types
            headings: {
                activeFont: null,
                options: {
                    name: 'headings',
                    ...globalOptions
                }
            },
            paragraphs: {
                activeFont: null,
                options: {
                    name: 'paragraphs',
                    ...globalOptions
                }
            },
            navigationAndButtons: {
                activeFont: null,
                options: {
                    name: 'navigationAndButtons',
                    ...globalOptions
                }
            },
            // single types
            heroTitle: {
                activeFont: null,
                options: {
                    name: 'heroTitle',
                    ...globalOptions
                }
            },
            heroSubtitle: {
                activeFont: null,
                options: {
                    name: 'heroSubtitle',
                    ...globalOptions
                }
            },
            h1: {
                activeFont: null,
                options: {
                    name: 'h1',
                    ...globalOptions
                }
            },
            h2: {
                activeFont: null,
                options: {
                    name: 'h2',
                    ...globalOptions
                }
            },
            h3: {
                activeFont: null,
                options: {
                    name: 'h3',
                    ...globalOptions
                }
            },
            h4: {
                activeFont: null,
                options: {
                    name: 'h4',
                    ...globalOptions
                }
            },
            heroParagraph: {
                activeFont: null,
                options: {
                    name: 'heroParagraph',
                    ...globalOptions
                }
            },
            p1: {
                activeFont: null,
                options: {
                    name: 'p1',
                    ...globalOptions
                }
            },
            p2: {
                activeFont: null,
                options: {
                    name: 'p2',
                    ...globalOptions
                }
            },
            projectTitle: {
                activeFont: null,
                options: {
                    name: 'projectTitle',
                    ...globalOptions
                }
            },
            projectSubtitle: {
                activeFont: null,
                options: {
                    name: 'projectSubtitle',
                    ...globalOptions
                }
            },
            navigation: {
                activeFont: null,
                options: {
                    name: 'navigation',
                    ...globalOptions
                }
            },
            button: {
                activeFont: null,
                options: {
                    name: 'button',
                    ...globalOptions
                }
            },
            pageTitle: {
                activeFont: null,
                options: {
                    name: 'pageTitle',
                    ...globalOptions
                }
            }
        };
        return fontPickers[type];
    },
    getDefaultFonts() {
        for (let i = 0; i < this.getFontPairs().length; i += 1) {
            this.downloadFonts(this.getFontPairs()[i]);
        }
    },
    getFontPairs() {
        const fontPairs = [
            {id: 1, headline: "Poppins", text: "Open Sans"},
            {id: 2, headline: "Montserrat", text: "Montserrat"},
            {id: 3, headline: "Ubuntu", text: "Open Sans"},
            {id: 4, headline: "Fira Sans", text: "Open Sans"},
            {id: 5, headline: "Playfair Display", text: "Fauna One"},
            {id: 6, headline: "Yeseva One", text: "Work Sans"},
            {id: 7, headline: "Arapey", text: "Mulish"},
            {id: 8, headline: "Rozha One", text: "Questrial"},
            {id: 9, headline: "Oswald", text: "Questrial"},
            {id: 10, headline: "Alfa Slab One", text: "Gentium Basic"},
            {id: 11, headline: "Source Sans Pro", text: "PT Serif"},
            {id: 12, headline: "Merriweather", text: "Mulish"},
            {id: 13, headline: "Rubik", text: "Roboto"},
            {id: 14, headline: "Oswald", text: "Old Standard TT"},
            {id: 15, headline: "Playfair Display", text: "Open Sans"},
            {id: 16, headline: "Kreon", text: "Open Sans"},
            {id: 17, headline: "Work Sans", text: "Lora"},
            {id: 18, headline: "Montserrat", text: "Quattrocento"},
            {id: 19, headline: "Spectral", text: "Karla"},
            {id: 20, headline: "Montserrat", text: "Noto Serif"},
            {id: 21, headline: "Raleway", text: "Mulish"},
            {id: 22, headline: "Gothic A1", text: "Gothic A1"},
            {id: 23, headline: "Poppins", text: "IBM Plex Mono"},
        ];
        return fontPairs;
    }
}

