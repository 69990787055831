<template>
    <transition name="modal" @enter="init" appear>
        <div class="modal-wrapper" v-if="show">
            <div class="modal-mask" @click="closeModal()"></div>
            <div :class="[
                {light:light},
                'modal-container',
                `modal-container--${modalSize}`,
                {[`modal-container--${customClass}`] : !!customClass}
                ]"
            >
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'modal-frame',
        inheritAttrs:false,
        props: {
            show: {
                default: false,
                type: Boolean
            },
            modalSize: {
                default: 'mid',
                type: String
            },
            customClass: {
                default: null,
                type: String
            },
            light: {
                default: false,
                type: Boolean
            },
            disableOutsideClick: {
                default: false,
                type: Boolean
            },
        },
        methods: {
            init() {
                this.$emit('init', true);
            },
            closeModal() {
                if(!this.disableOutsideClick) {
                    this.$emit('close', true);
                }
            }
        }
    }
</script>
