<template>
    <div v-if="user.image.url && imageLayoutIsOverflow" class="fake-cut"></div>
    <div v-if="user.image.url && imageLayoutIsOverflow" class="fake-cut-line"></div>
    <div v-if="templateSettings.showProfilePicture && user.image.url" :class="['profile-picture', {'display-overflow' : imageLayoutIsOverflow}]">
        <img :src="user.image.url">
    </div>
</template>

<script>
    export default {
        name: "UserImage",
        computed: {
            templateSettings() {
                return this.$store.state.view.portfolio.templateSettings
            },
            user() {
                return this.$store.state.view.user
            },
            imageLayoutIsOverflow() {
                return this.templateSettings.showProfilePicture &&
                    this.templateSettings.theme.includes('profile_theme_default')
                    && this.templateSettings.hero
                    && this.templateSettings.hero.sidebarSettings
                    && this.templateSettings.hero.sidebarSettings.imageLayout === 'overflow'
            }
        }
    }
</script>