<template>
    <RouterView v-slot="{ Component }">
        <component
            :is="Component"
        />
    </RouterView>
</template>

<script>
    export default {
        name: "App",
        mounted() {
            this._keyListener = function(e) {
                if (e.code === "KeyG" && e.shiftKey && e.ctrlKey) {
                    e.preventDefault();
                    this.$store.state.displayToggleGrid = !this.$store.state.displayToggleGrid;
                }
            }
            document.addEventListener('keydown', this._keyListener.bind(this));
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this._keyListener);
        },
    }
</script>