<template>
    <div v-if="imageLayoutIsOverflow" class="fake-cut"></div>
    <div v-if="imageLayoutIsOverflow" class="fake-cut-line"></div>
    <div v-if="templateSettings.showProfilePicture && portfolio.image.url" :class="['profile-cover', 'profile-picture', {'display-overflow' : imageLayoutIsOverflow}]">
        <AnimationWrapper
            :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
            :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
            :delay-animation="0.2"
        >
            <template #animationContent>
                <img :src="portfolio.image.croppedImageUrl ? portfolio.image.croppedImageUrl : portfolio.image.url">
            </template>
        </AnimationWrapper>
    </div>
</template>

<script>
    import AnimationWrapper from "../../../../common/components/AnimationWrapper.vue";

    export default {
        name: "UserThemeCoverImage",
        components: {AnimationWrapper},
        computed: {
            portfolio() {
                return this.$store.state.view.portfolio
            },
            templateSettings() {
                return this.portfolio.templateSettings
            },
            imageLayoutIsOverflow() {
                return this.templateSettings.theme.includes('profile_theme_default')
                    && this.templateSettings.hero
                    && this.templateSettings.hero.sidebarSettings
                    && this.templateSettings.hero.sidebarSettings.imageLayout === 'overflow'
            }
        }
    }
</script>