<template>
    <!-- TODO: :onscroll= "portfolio.templateSettings.theme === 'profile_theme_nine' ? scrolledDown() : null" -->
    <AnimationWrapper
        :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
        :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
    >
        <template #animationContent>
            <div v-if="portfolio.description"
                 :class="['ufo--page-hero-text',
                 textAlignment()]"
                v-on:scroll.native="portfolio.templateSettings.theme === 'profile_theme_nine' ? scrolledDown() : null"
            >
                <span v-if="checkString(portfolio.description)" v-html="portfolio.description"></span>
                <span v-else v-html="refactorString(portfolio.description)"></span>
            </div>
            <UserSocialLinks v-if="socialLinksAreInHero()" />
        </template>
    </AnimationWrapper>
</template>

<script>
import AnimationWrapper from "../../../../common/components/AnimationWrapper.vue";
import UserSocialLinks from "../../common/user/UserSocialLinks.vue";
import {SocialLinksLocationEnum} from "../../../../common/enums.js";

export default {
    name: "UserPortfolioDescription",
    components: {UserSocialLinks, AnimationWrapper},
    computed: {
        portfolio() {
            return this.$store.state.view.portfolio
        },
    },
    methods: {
        refactorString(data) {
            return data.replace("\n", "<br />").replace('<a href', '<a rel="nofollow" href');
        },
        checkString(data) {
            return data.includes('rel="noopener noreferrer nofollow')
        },
        scrolledDown() {
            const e = document.querySelector('.ufo--page-hero-text');
            e.classList.toggle('scrollable', e.scrollTop !== (e.scrollHeight - e.offsetHeight))
        },
        socialLinksAreInHero() {
            return this.portfolio.templateSettings.navigation.socialLinksLocation === SocialLinksLocationEnum.INLINE_ABOVE_TEXT
                || this.portfolio.templateSettings.navigation.socialLinksLocation === SocialLinksLocationEnum.INLINE_BELOW_TEXT
        },
        textAlignment() {
            return !!this.portfolio.templateSettings.hero && !!this.portfolio.templateSettings.hero.textAlign
                ? `align-text-${this.portfolio.templateSettings.hero.textAlign}`
                : null
        },
    }
}
</script>

<style lang="scss" scoped>
.anim-wrapper {
    display: flex;
    flex-direction: column;
}
</style>