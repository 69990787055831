<template>
    <GetHeroNavigation :show-home-button="true" />
    <UserThemeCoverImage v-if="portfolio && portfolio.image.url && templateSettings.showProfilePicture"/>
    <UserImage v-else />
    <div class="profile-short-cv">
        <UserName />
        <UserJobTitle />
        <div class="profile-short-cv">
            <UserPortfolioDescription v-if="portfolio && portfolio.description && isHome" />
            <UserDescription v-else-if="isHome"/>
        </div>
    </div>
</template>

<script>
    import UserDescription from "../../common/user/UserDescription.vue";
    import UserPortfolioDescription from "../../common/user/UserPortfolioDescription.vue";
    import UserName from "../../common/user/UserName.vue";
    import UserJobTitle from "../../common/user/UserJobTitle.vue";
    import UserThemeCoverImage from "../../common/user/UserThemeCoverImage.vue";
    import UserImage from "../../common/user/UserImage.vue";
    import GetHeroNavigation from "../../common/hero/GetHeroNavigation.vue";

    export default {
        name: "ThemeThreeOld",
        components: {
            GetHeroNavigation,
            UserImage,
            UserThemeCoverImage,
            UserJobTitle,
            UserName,
            UserDescription,
            UserPortfolioDescription
        },
        computed: {
            portfolio() {
                return this.$store.state.view.portfolio
            },
            templateSettings() {
                return this.$store.state.view.portfolio.templateSettings
            },
            isHome() {
                return this.$store.state.view.project.type === 'home'
            },
        }
    }
</script>