<template>
    <div v-if="showActionButtons" class="ufo-sepa--buttons">

        <!-- secondary cta button // cancel button -->
        <Cta :type="'secondary'"
             dark-scheme
             @click="secondaryAction()"
        >
            Cancel
        </Cta>

        <!-- primary cta button // save/update button -->
        <Cta :disabled="!settings.allowSave"
             dark-scheme
             @click="primaryAction()"
        >
            {{settings.saveButton}}
        </Cta>

    </div>
</template>

<script>
    import Cta from "../../components/CtaButton.vue";

    export default {
        name: 'panel-footer',
        components: {Cta},
        computed: {
            settings() {
                return this.$store.state.sidePanelSettings;
            },
            showActionButtons() {
                return !this.settings.hideActionButtons
            }
        },
        methods: {
            // emit events to parent
            primaryAction() {
                this.$store.state.sidePanelSettings.activeModal = null;
                this.$emit('primaryAction');
            },
            secondaryAction() {
                this.$store.state.sidePanelSettings.activeModal = null;
                this.$emit('secondaryAction');
            },
            back() {
                this.$emit('back');
            },
            close() {
                this.$emit('close');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .ufo-sepa--buttons {
        --bgColor: var(--asphalt10);
        border-top: 1px solid var(--asphalt80);
    }
</style>