<template>
    <div v-if="section.galleryItems.length"
         :id="'section-' + section.index"
         class="ufo-sec ufo-sec--gallery"
    >
        <ToggleGrid />
        <SectionBackgroundImage :section="section" v-if="section.layout !== 'slider'" />
        <div class="grid">
            <div :class="['image-gallery',
            section.layout !== 'grid' ? section.layout : '',
            section.layout + '-gallery',
            'glide--ltr',
            'glide--' + section.layout,
            'glide--swipeable',
            section.galleryItems[0].media.frame && (section.galleryItems[0].media.frame.includes('mobile') || section.galleryItems[0].media.frame.includes('tablet')) ? 'mockups-in-gallery ' + section.galleryItems[0].media.frame + '-mockup' : '',
            section.galleryItems[0].media.frame && section.galleryItems[0].media.frame.includes('desktop') ? 'desktop-mockup' : '']"
            >
                <div v-if="section.layout === 'carousel'" :class="section.layout">
                    <AnimationWrapper
                        :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                        :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                    >
                        <template #animationContent>
                            <div ref="glideGallery" class="carousel">
                                <div class="carousel__track" data-glide-el="track">
                                    <ul class="carousel__slides">
                                        <li v-bind:key="galleryItem" v-for="galleryItem in section.galleryItems" class="carousel__slide">
                                            <GetGalleryMediaContent :gallery-item="galleryItem" :gallery-layout="section.layout" />
                                        </li>
                                    </ul>
                                </div>
                                <div class="carousel__arrows" data-glide-el="controls">
                                    <button class="carousel__arrow carousel__arrow--left" data-glide-dir="<">prev</button>
                                    <button class="carousel__arrow carousel__arrow--right" data-glide-dir=">">next</button>
                                </div>
                            </div>
                        </template>
                    </AnimationWrapper>
                </div>
                <div v-else-if="section.layout === 'slider'" class="slider-grid-container">
                    <AnimationWrapper
                        :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                        :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                    >
                        <template #animationContent>
                            <div ref="glideGallery" class="carousel slider">
                                <SectionBackgroundImage :section="section" />
                                <div class="slider__track" data-glide-el="track">
                                    <ul class="carousel__slides carousel__slides--slider">
                                        <li v-bind:key="galleryItem" v-for="galleryItem in section.galleryItems" class="carousel__slide carousel__slide--slider">
                                            <GetGalleryMediaContent :gallery-item="galleryItem" :gallery-layout="section.layout" />
                                            <div v-if="galleryItem.media.caption && galleryItem.media.caption.length > 0" class="caption-box">
                                                <div class="caption-box">
                                                    <div class="media-caption">
                                                        <div class="text" v-html="mediaCaption(galleryItem)"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="caption-box-empty"></div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="carousel__arrows" data-glide-el="controls">
                                    <button class="carousel__arrow carousel__arrow--left" data-glide-dir="<">prev</button>
                                    <button class="carousel__arrow carousel__arrow--right" data-glide-dir=">">next</button>
                                </div>
                                <div class="glide__bullets" data-glide-el="controls[nav]">
                                    <button v-bind:key="galleryItem" v-for="(galleryItem, index) in section.galleryItems" class="glide__bullet" :data-glide-dir="'='+index">
                                        <img :src="galleryItem.media.croppedImageUrl ? galleryItem.media.croppedImageUrl : galleryItem.media.url" alt="Screen design displayed in a mockup" width="32" height="32">
                                    </button>
                                </div>
                            </div>
                        </template>
                    </AnimationWrapper>
                </div>
                <div v-else :class="section.layout + '-gallery'">
                    <div :class="[ section.layout !== 'grid' ? section.layout : '',
                                    section.layout === 'grid' ? 'gallery-columns-'+ section.columns : '',
                                    section.galleryItems[0].media.frame && (section.galleryItems[0].media.frame === 'mobile' || section.galleryItems[0].media.frame === 'tablet') ? 'mockups-in-gallery' : '']"
                    >
                        <GetGalleryMediaContent v-for="(galleryItem, index) in section.galleryItems" :index="index" :gallery-item="galleryItem" :gallery-layout="section.layout" />

                        <template v-if="section.layout === 'tilted' && section.galleryItems.length % 4 !== 0" >
                            <GetGalleryMediaContent v-for="galleryItem in galleryItemsforTilted" :gallery-item="galleryItem" :gallery-layout="section.layout" />
                        </template>
                    </div>
                    <template v-if="section.layout === 'tilted'">
                        <div v-bind:key="ghost" v-for="ghost in ghostGalleries" :class="['gallery-ghost', ghost, 'tilted']">
                            <GetGalleryMediaContent v-for="galleryItem in section.galleryItems" :gallery-item="galleryItem" :gallery-layout="section.layout" />
                            <template v-if="section.layout === 'tilted' && section.galleryItems.length % 4 !== 0" >
                                <GetGalleryMediaContent v-for="galleryItem in galleryItemsforTilted" :gallery-item="galleryItem" :gallery-layout="section.layout" />
                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GetGalleryMediaContent from "./_helper/GetGalleryMediaContent.vue";
    import Glide from "@glidejs/glide";

    import ToggleGrid from "../../../common/components/ToggleGrid.vue";
    import AnimationWrapper from "../../../common/components/AnimationWrapper.vue";
    import SectionBackgroundImage from "../../../sections/common/SectionBackgroundImage.vue";

    export default {
        name: "GallerySection",
        components: {SectionBackgroundImage, AnimationWrapper, GetGalleryMediaContent, ToggleGrid},
        props:['section'],
        data() {
          return {
              glideGallery: null,
              defaultCarouselConfig: {
                  type: 'carousel',
                  focusAt: 'center',
                  gap: 30,
              },
              defaultSliderConfig: {
                  type: 'slider',
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  animationDuration: 300,
                  focusAt: 'center'
              },
              activeBullet: 0,
          }
        },
        mounted() {
            if(this.section.layout === 'carousel' || this.section.layout === 'slider') {
                this.mountGallery();
            }
        },
        methods: {
            mountGallery() {
                if(this.section.galleryItems.length < 1) return;
                if(this.section.layout === 'carousel') {
                    const imageNumber = !!this.section.galleryItems[0].media.frame && this.section.galleryItems[0].media.frame.includes('desktop') ? 1 : 3;
                    this.initGlideGallery({
                        ...this.defaultCarouselConfig,
                        perView: imageNumber,
                    });
                } else if (this.section.layout === 'slider') {
                    this.initGlideGallery(this.defaultSliderConfig)
                }
            },
            mediaCaption(galleryItem) {
                if(galleryItem.media.caption.includes("rel=\"noopener noreferrer nofollow\"")) {
                    return galleryItem.media.caption
                }
                else {
                    return galleryItem.media.caption.replace("\n", "<br />").replace("<a href=", "<a rel=\"noopener noreferrer nofollow\" href=")
                }
            },
            initGlideGallery(galleryConfig) {
                this.glideGallery = new Glide(
                    this.$refs.glideGallery,
                    galleryConfig
                ).mount();

                this.$nextTick(() => {
                    this.glideGallery.update({ startAt: 0 });
                });
            },
        },
        computed: {
            numberOfGhostImages() {
                //TODO: volt egy .abs a végén
                return (4 - this.section.galleryItems.length % 4)
            },
            ghostGalleries() {
                return ['before-one', 'before-two', 'before-three', 'after-one', 'after-two', 'after-three', 'top-one', 'top-two', 'bottom-one', 'bottom-two']
            },
            galleryItemsforTilted() {
                return this.section.galleryItems.filter((item, index) => index < this.numberOfGhostImages)
            }
        }
    }
</script>