<template>
    <ModalWrapper dark-scheme :show="show" modal-size="small" @close="$emit('close')">
        <template #modal-header>
            <ModalHeader dark-scheme :title="title" />
        </template>
        <template #modal-body>
            <ModalBody>
                <div class="confirmation-modal">
                    <slot name="content"></slot>
                </div>
            </ModalBody>
        </template>
        <template #modal-footer>
            <ModalFooter>
                <cta dark-scheme type="secondary" @click="$emit('close')">Cancel</cta>
                <cta dark-scheme type="alert" @click="emitPrimaryAction()"><slot name="primaryButtonLabel">Delete</slot></cta>
            </ModalFooter>
        </template>
    </ModalWrapper>

</template>

<script>

    import Cta from "./components/CtaButton.vue";
    import ModalWrapper from "../sections/modals/_ModalWrapper.vue";
    import ModalHeader from "../sections/modals/_ModalHeader.vue";
    import ModalBody from "../sections/modals/_ModalBody.vue";
    import ModalFooter from "../sections/modals/_ModalFooter.vue";

    export default {
        name: 'confirmation-modal',
        components: {
            ModalFooter,
            ModalBody,
            ModalHeader,
            ModalWrapper,
            Cta,
        },
        props: ['show', 'modal-size', 'title'],
        methods: {
            emitPrimaryAction() {
                this.$emit('action', true);
            },
            closeModal() {
                this.$emit('close', true);
            }
        }
    }
</script>
