<template>
    <div class="media-box">
        <div v-if="columnMedia.frame && columnMedia.frame !== 'no-mockup'" :class="'mockup--' + columnMedia.frame">
            <div class="ufo-mockup--display">
                <div class="ufo-mockup--screen">
                    <div class="ufo-mockup--scroller">
                        <PlaceColumnMediaContent :column-media="columnMedia" :placeholder-image="placeholderImage"/>
                    </div>
                </div>
                <div class="ufo-mockup--frame">
                    <img :src="'/mockups/mockup--' + columnMedia.frame.replace('--no-shadow', '').replace('--shadow', '') + '.svg'">
                </div>
            </div>
        </div>
        <PlaceColumnMediaContent v-else :column-media="columnMedia" :placeholder-image="placeholderImage" />
    </div>
</template>

<script>
    import PlaceColumnMediaContent from "./PlaceColumnMediaContent.vue";

    export default {
        name: "GetColumnMediaContent",
        props: ['columnMedia',  'placeholderImage'],
        components: {
            PlaceColumnMediaContent
        },
        data() {
            return {
                setZoom:true
            }
        }
    }
</script>