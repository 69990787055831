const defaultFooterSettings = {
    showLine:true,
    textAlign:'left',
    socialIcons:false
}

export const footerSettings = {
    //Tesler
    theme_eighteen: {
        ...defaultFooterSettings,
        showLine:false,
        socialIcons:true,
    },
    //Aurora
    theme_seventeen: {
        ...defaultFooterSettings,
        showLine:false,
        textAlign:'center',
    },
    //Norman
    theme_sixteen: {
        ...defaultFooterSettings
    },
    //Jakob
    theme_fourteen: {
        ...defaultFooterSettings
    },
    //Nelson
    theme_thirteen: {
        ...defaultFooterSettings
    },
    //Otis
    theme_twelve: {
        ...defaultFooterSettings
    },
    //Bauhaus
    theme_eleven: {
        ...defaultFooterSettings
    },
    //Orielle
    theme_ten: {
        ...defaultFooterSettings
    },
    //Anthra
    profile_theme_nine: {
        ...defaultFooterSettings
    },
    //Nominee
    theme_eight: {
        ...defaultFooterSettings
    },
    //Uncurled
    theme_seven: {
        ...defaultFooterSettings
    },
    //Poster
    theme_six: {
        ...defaultFooterSettings
    },
    //Panorama
    theme_five: {
        ...defaultFooterSettings
    },
    //Cubic
    profile_theme_four: {
        ...defaultFooterSettings
    },
    //Axis
    theme_three: {
        ...defaultFooterSettings
    },
    //Alaine
    theme_two: {
        ...defaultFooterSettings
    },
    //Twee
    theme_one: {
        ...defaultFooterSettings
    },
    // Baze
    profile_theme_default: {
        ...defaultFooterSettings
    },
}
