import VueGtag from "vue-gtag";
import App from "./App.vue";

import { createHead } from '@unhead/vue'
import { createApp } from 'vue'

import VueCookies from 'vue-cookies'

import DateTimeHelper from './common/DateTimeHelper.js';
import GoogleFontsHelper from './common/GoogleFontsHelper.js';
import DropzoneHelper from './common/DropzoneHelper.js';
import DefaultTextStylesHelper from './common/templateDefaults/DefaultTextStylesHelper.js';
import UrlHelper from './common/UrlHelper.js';
import mitt from 'mitt';
//scss
import '../styles/main.scss';

//routers
import MainRouter from "./router/Router.js";

//store
import { store } from "./store/FolioStore.js";
import axios from "axios";
import {PortfolioActions} from "./store/actions/PortfolioActions.js";

//App
export let app = null;
export let router = null;

//Global event emitter
const emitter = mitt();

app = createApp(App);
router = MainRouter;
app.use(router)
app.use(store);
app.config.globalProperties.$store = store;
app.config.globalProperties.$actions = PortfolioActions;
app.config.globalProperties.emitter = emitter;
app.mount('#app');


//for injectin seo meta
const head = createHead();
app.use(head)

app.use(VueGtag, {
    includes: [
        //TODO: move out from here this is horrible.
        {id: process.env.NODE_ENV === "production" ? "G-SWXNNMMKPQ" : "G-TWMGEGKZCK"}
    ],
    config: {
        id:document.querySelector('meta[name="google-analytics-id"]').getAttribute('content')
    }
})

//Cookie handling
app.use(VueCookies, { expires: '7d'})

app.config.globalProperties.GoogleFontsHelper = GoogleFontsHelper;
app.config.globalProperties.UrlHelper = UrlHelper;
app.config.globalProperties.DateTimeHelper = DateTimeHelper;
app.config.globalProperties.DropzoneHelper = DropzoneHelper;
app.config.globalProperties.DefaultTextStylesHelper = DefaultTextStylesHelper;
app.config.globalProperties.IsProduction = (process.env.NODE_ENV === 'production');

//stripe
const stripeKey = process.env.STRIPE_KEY_LIVE ? process.env.NODE_ENV === "production" ? process.env.STRIPE_KEY_LIVE : process.env.STRIPE_KEY_TEST : "pk_test_MJ8ele2xTDW4ASuXbRG1LPls0072k3oy96"
export const stripe = !!window.Stripe ? Stripe(stripeKey) : null;

//axios
axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
