<template>
    <div class="comment-input">
        <div class="input-row">
            <EditorContent :editor="editor"
                           :class="'ufo--text-area-comment'"
            />
            <Icon :class="{disabled:!this.currentComment.text, active:this.currentComment.text && this.currentComment.text.length}" icon-name="send" @click="addNewComment"></Icon>
        </div>
        <div v-if="inputFocused || (currentComment.text && currentComment.text.length)" class="labels-row">
            <div @click="() => updateCommentLabel(label.name)"
                 v-bind:key="label.name"
                 @mousedown.prevent
                 :class="['label', {selected:currentComment.label === label.name}, label.name.toLowerCase()]" v-for="label in labels"
            >
                {{label.name}}
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from "../../../common/Icon.vue";
    import { Editor, EditorContent } from "@tiptap/vue-3";
    import StarterKit from "@tiptap/starter-kit";
    import Placeholder from "@tiptap/extension-placeholder";

    export default {
        name: "CommentInput",
        props: ['currentComment', 'addComment', 'updateCommentLabel'],
        components: {
            Icon,
            EditorContent
        },
        data() {
            return {
                inputFocused:false,
                labels: [
                    {name:'Design'},
                    {name:'Structure'},
                    {name:'Content'},
                    {name:'Imagery'},
                ],
                editor:null,
                typeInterval:null,
                inactivityInterval:null,
            }
        },
        mounted() {
            this.editor = new Editor({
                content: this.currentComment.text,
                extensions: [
                    StarterKit,
                    Placeholder.configure({
                        placeholder: "Type your comment here",
                        showOnlyWhenEditable: true,
                        showOnlyCurrent: true
                    }),
                ],
                onUpdate: ({ getJSON, getHTML }) => {
                    this.applyTextEdit(this.editor.getText());
                },
                onBlur: () => {
                    this.inputFocused = false;
                },
                onFocus: () => {
                    this.inputFocused = true;
                }
            })
        },
        methods: {
            applyTextEdit(newText) {
                this.currentComment.text = newText;
            },
            addNewComment() {
                this.editor.commands.clearContent();
                this.addComment();
            }
        }
    }
</script>

<style scoped lang="scss">
    .comment-input {
        display: flex;
        flex-direction: column;
        width: 100%;

        .input-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            padding: 24px;
            align-self: stretch;

            .ufo--text-area-comment {
                width: 100%;
                font:var(--editor-ui-b1-semibold);
                color:var(--asphalt10);
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
                flex: 1 0 0;
                align-self: stretch;
                overflow: auto;

            }

            svg {
                width: 24px;
                height: 24px;
                color: var(--asphalt50);
                align-self: self-end;

                &.active {
                    color: var(--asphalt10);
                }

                &:not(.disabled):hover {
                    cursor: pointer;
                    color: var(--asphalt10);
                }
            }
        }

        .labels-row {
            display: flex;
            padding: 12px 24px 24px 24px;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;

            .label {
                padding: 8px 12px;
                font: var(--editor-ui-l-medium);
                color: var(--asphalt40);
                border-radius: 30px;
                border: 1px solid var(--asphalt80);

                &:hover {
                    cursor: pointer;
                    color: var(--asphalt10);
                }

                &.selected {
                    &.design {
                        background-color: #362B3B;
                        color:#E6BEF9;
                    }

                    &.structure {
                        background-color: #373B2B;
                        color:#E3F9A6;
                    }

                    &.content {
                        background-color: #2B3A3B;
                        color:#B9F3F7;
                    }

                    &.imagery {
                        background-color: #3B302B;
                        color:#FECAA4;
                    }
                }
            }
        }
    }
</style>