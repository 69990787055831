import ApiHelper from '../ApiHelper.js';

const ProjectsApiUrl = "/api/projects/";

// Project object:
// var project = {
//     _id: String,
//     ownerId: String,
//     image: Image,
//     name: String,
//     draft: Boolean,
//     sections: Array[Section]
// };

function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
}

function updateImage(url, base64image, fileName, path) {
    const blob = dataURItoBlob(base64image);
    const data = new FormData();
    data.append('imageFile', blob, fileName);
    data.append('path', path);
    return ApiHelper.postMultipart(url, data);
}

function updateImageFromBlob(url, blob, fileName, path, filesToDelete) {
    const data = new FormData();
    data.append('imageFile', blob, fileName);
    data.append('path', path);
    data.append('filesToDelete', filesToDelete)
    return ApiHelper.postMultipart(url, data);
}

export default {

    getProject: function (projectId) {
        return ApiHelper.get(ProjectsApiUrl + projectId);
    },

    getProjectByShortId(collectionShortId, projectShortId) {
        return ApiHelper.get(`${ProjectsApiUrl}getByShortId/${collectionShortId}/${projectShortId}`);
    },

    getAllProject: function (userId, basicInfo) {
        return ApiHelper.getWithParams(ProjectsApiUrl + "all",
            {
                userId: userId,
                basicInfo: basicInfo
            });
    },

    getProjectSettingsById(projectId) {
        return ApiHelper.get(`${ProjectsApiUrl}${projectId}/settings`);
    },

    updateDesignSettingsInProjects(userId, collectionId, textStyles) {
        return ApiHelper.put(`${ProjectsApiUrl}all/${userId}/${collectionId}/designSettings`, textStyles);
    },

    updateNavButtonStyle(userId, collectionId, buttonStyle) {
        return ApiHelper.put(`${ProjectsApiUrl}all/${userId}/${collectionId}/buttonStyle`, buttonStyle);
    },

    createProject: function (userId, portfolioId, type, order, name, link, suggestions, shortCaseStudy, designSettings, defaultCaseStudyValues, thumbnailData) {
        return ApiHelper.post(ProjectsApiUrl, {
            ownerId: userId,
            portfolioId:  portfolioId,
            type: type,
            order: order,
            name: name,
            link: link,
            suggestions: suggestions,
            shortCaseStudy: shortCaseStudy,
            designSettings: designSettings,
            defaultCaseStudyValues: defaultCaseStudyValues,
            thumbnailData:thumbnailData
        });
    },

    updateProjectByShortId(collectionShortId, projectShortId, name, link) {
        return ApiHelper.post(`${ProjectsApiUrl}${collectionShortId}/${projectShortId}/update`, {
            name: name,
            link: link
        })
    },

    getPortfolioPages(collectionId) {
        return ApiHelper.get(`${ProjectsApiUrl}pages/${collectionId}`)
    },

    getCaseStudyPages(collectionId) {
        return ApiHelper.get(`${ProjectsApiUrl}pages/${collectionId}/caseStudy`)
    },

    updatePortfolioPagesOrder(collectionId, pages) {
        return ApiHelper.put(`${ProjectsApiUrl}pages/${collectionId}/order`, pages)
    },

    updateCaseStudyPagesOrder(collectionId, caseStudies) {
        return ApiHelper.put(`${ProjectsApiUrl}pages/${collectionId}/order/caseStudies`, caseStudies)
    },

    duplicateProject: function (projectId) {
        return ApiHelper.post(ProjectsApiUrl + projectId + "/duplicate", null);
    },

    deleteProject: function (projectId) {
        return ApiHelper.delete(ProjectsApiUrl + projectId, null);
    },

    uploadGeneratedThumbnail: function (userId, projectId, base64image) {
        return updateImage("/api/image/project/upload/" + projectId + "/GeneratedThumbnail", base64image, 'GeneratedThumbnail.png', userId + "/" + projectId);
    },

    uploadThumbnailImage: function (userId, projectId, base64image, fileName) {
        return updateImage("/api/image/project/upload/" + projectId + "/ThumbnailImage", base64image, fileName, userId + "/" + projectId);
    },

    uploadCroppedImage: function (userId, projectId, blob, imageType, fileType, imageToDelete) {
        return updateImageFromBlob("/api/image/project/upload/" + projectId + "/" + imageType, blob, 'croppedImage.' + fileType, userId + "/" + projectId, [imageToDelete]);
    },

    updateProjectImage: function (projectId, imageUrl, croppedImageUrl, altTag) {
        return ApiHelper.put(ProjectsApiUrl + projectId + "/image", {url: imageUrl, croppedImageUrl: croppedImageUrl, altTag: altTag});
    },

    deleteProjectImage: function (projectId, imageUrl, croppedImageUrl) {
        return ApiHelper.delete("/api/image/project/delete/" + projectId, {url: imageUrl, croppedImageUrl: croppedImageUrl});
    },

    updateProjectSocialImage: function (projectId, base64image) {
        return updateImage(ProjectsApiUrl + projectId + "/socialImage", base64image, 'GeneratedSocialThumbnail.jpg');
    },

    setProjectDraft: function (projectId, draft) {
        return ApiHelper.put(ProjectsApiUrl + projectId + "/draft", {draft: draft});
    },

    archiveProject(projectId) {
        return ApiHelper.put(ProjectsApiUrl + projectId + "/archive");
    },

    setProjectSettings: function (projectId, projectSettings) {
        return ApiHelper.put(ProjectsApiUrl + projectId + "/settings", projectSettings);
    },

    updateProjectDesignSettings: function (projectId, designSettings) {
        return ApiHelper.put(ProjectsApiUrl + projectId + "/designSettings", designSettings);
    },

    setProjectNameAndSubtitle: function (projectId, name, subtitle) {
        return ApiHelper.put(ProjectsApiUrl + projectId + "/nameAndSubtitle", {name: name, subtitle: subtitle});
    },

    setProjectPassword: function (projectId, password) {
        return ApiHelper.put(ProjectsApiUrl + projectId + "/password", {password: password});
    },

    addSection: function (projectId, sectionBody) {
        return ApiHelper.post(ProjectsApiUrl + projectId + "/section", sectionBody);
    },

    addSuggestionSection: function (projectId, sectionIndex, suggestionType) {
        return ApiHelper.post(ProjectsApiUrl + projectId + "/section/suggestion", {
            index: sectionIndex,
            suggestionType: suggestionType
        });
    },

    updateSection: function (projectId, section) {
        return ApiHelper.put(ProjectsApiUrl + projectId + "/section", section);
    },

    updateSections: function (projectId, sections) {
        return ApiHelper.put(ProjectsApiUrl + projectId + "/sections", sections);
    },

    reorderSections: function (projectId, reorderSectionBody) {
        return ApiHelper.put(ProjectsApiUrl + projectId + "/sections/reorder", reorderSectionBody);
    },

    archiveSection(projectId, sectionIndex) {
        return ApiHelper.put(ProjectsApiUrl + projectId + "/section/archive/" + sectionIndex , sectionIndex);
    },

    deleteSection: function (projectId, section) {
        return ApiHelper.delete(ProjectsApiUrl + projectId + "/section", section);
    },

    deleteGalleryItem: function (projectId, sectionIndex, item) {
        return ApiHelper.delete(ProjectsApiUrl + projectId + "/gallerySection/" + sectionIndex, item);
    },

    updateProjectUrl: function(projectId, urlSlug) {
        return ApiHelper.put(ProjectsApiUrl + "updateUrl/" + projectId + "/" + urlSlug);
    },

    setProjectGlobalSpacing(project) {
        return ApiHelper.put(`${ProjectsApiUrl}setGlobalSpacing`, project)
    },

    updateProjectThumbnailData(projectId, thumbnailData) {
        return ApiHelper.put(ProjectsApiUrl + projectId + "/thumbnail-data", thumbnailData)
    },

    uploadCroppedThumbnailImage(blob, projectId, imageType, fileType, path, filesToDelete) {
        console.log(fileType);
        return updateImageFromBlob(`/api/image/project/upload/${projectId}/ThumbnailImage`, blob, fileType, path, filesToDelete)
    }
}
