<template>
    <div :id="'section-' + section.index" class="ufo-sec ufo-sec--logowall">
        <ToggleGrid />
        <div class="grid">
            <div class="ufo--columns-wrapper">
                <div :class="['ufo--columns', 'ufo--columns-' + section.logos.length]">
                    <div v-bind:key="logo" v-for="(logo, index) in section.logos" class="ufo--column">
                        <div :class="['column-content', isUnified, unifiedColor]"
                             :style="{'--density': section.logoStyle && section.logoStyle.density ? section.logoStyle.density : null}">
                            <ColumnImageHelper :index="index" :section="section" :columnMedia="logo" :placeholder-image="`${isCaseStudy ? '' : 'portfolio-'}empty-columns-0${index+1}.${isCaseStudy ? 'jpg' : 'png'}`"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ColumnImageHelper from "./_helper/ColumnImageHelper.vue";
    import ToggleGrid from "../../../common/components/ToggleGrid.vue";

    export default {
        name: "LogoWall",
        components: { ColumnImageHelper, ToggleGrid },
        props:['section'],
        computed: {
            designSettings() {
                return this.$store.getters.isCaseStudy && !this.$store.getters.usingGlobalColorStyles
                    ? this.$store.state.project.designSettings
                    : this.$store.state.portfolio.designSettings
            },
            pageBgColor() {
                return this.designSettings.colorStyles.background.color;
            },
            isUnified() {
                return !!this.section.logoStyle && this.section.logoStyle.unified ?
                    this.pageBgColor.toLowerCase() === '#ffffff' ?
                        'unified' :
                        !this.$store.state.darkMode ?
                            'unified unified--light' :
                            'unified unified--dark' :
                    null;
            },
            unifiedColor() {
                return !!this.section.logoStyle && this.section.logoStyle.filterColor === "neutral" ? "neutral" : "accent" ;
            },
            isCaseStudy() {
                return this.$store.getters.isCaseStudy
            }
        }
    }
</script>