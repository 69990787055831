<template>
    <ToggleGrid/>
    <GetHeroNavigation :show-home-button="true" />
    <UserThemeCoverImage v-if="portfolio && portfolio.image.url"/>
    <UserImage v-else-if="isHome" />
    <div class="profile-short-cv">
        <UserPortfolioDescription v-if="portfolio && portfolio.description && isHome" />
        <UserDescription v-else-if="isHome" />
    </div>
</template>

<script>
import UserPortfolioDescription from "../../common/user/UserPortfolioDescription.vue";
import UserDescription from "../../common/user/UserDescription.vue";
import UserThemeCoverImage from "../../common/user/UserThemeCoverImage.vue";
import UserImage from "../../common/user/UserImage.vue";
import ToggleGrid from "../../../../common/components/ToggleGrid.vue";
import GetHeroNavigation from "../../common/hero/GetHeroNavigation.vue";

export default {
    name: "ProfileThemeThree",
    components: {
        GetHeroNavigation,
        ToggleGrid,
        UserImage,
        UserThemeCoverImage,
        UserDescription,
        UserPortfolioDescription
    },
    computed: {
        portfolio() {
          return this.$store.state.view.portfolio
        },
        isHome() {
            return this.$store.state.view.project.type === 'home'
        },
    }
}
</script>

<style scoped>

</style>