<template>
    <template v-if="loaded">
        <ReviewMode v-if="review">
            <component :is="currentComponent" v-bind="currentProps" />
        </ReviewMode>
        <component v-else :is="currentComponent" v-bind="currentProps" />
    </template>
</template>

<script>
import PortfolioPage from "./Portfolio.vue";
import ApiHelper from "../../api/ApiHelper.js";
import {checkFonts} from "../../settingsEditor/_helpers/fontLoaders.js";
import PasswordProtection from "./common/PasswordProtection.vue";
import ErrorPage from "../common/ErrorPage.vue";
import GoogleFontsHelper from "../../common/GoogleFontsHelper.js";
import ReviewMode from "../../view/review/ReviewMode.vue";

export default {
    name: "PortfolioWrapper",
    components: {
        ReviewMode,
        PasswordProtection,
        PortfolioPage,
        ErrorPage
    },
    //These coming from the IndexWrapper, as we actualy getting the data for portfolio in index view, for quicker render
    props:['ownDomainError', 'ownDomainPassword', 'ownDomainLoaded', 'review'],
    data() {
        return {
            password: false,
            error: false,
            loaded:false
        }
    },
    mounted() {
        if(this.ownDomainError) {
            this.error = true;
        }
        if(this.ownDomainPassword) {
            this.password = true;
        }
        if(this.ownDomainLoaded && !this.loaded) {
            this.loaded = true;
        }
    },
    //This runs if user don't have owndomain adress
    async beforeRouteEnter(to, from, next) {
        try {
            let url = '/data'
            let portfolioId = to.params.portfolioId;
            let shortId = to.params.shortId

            if(shortId) {
                url += '/shortid/' + shortId
            }
            else if(portfolioId) {
                url += "/portfolio/" + portfolioId
            }
            else {
                url += '/portfolio'
            }

            let res = await ApiHelper.get(url);

            next(vm => vm.setData(res.status, res.data))
        }
        catch(err) {
            next(vm => vm.setData(err, null))
        }
    },
    methods: {
        setData(err, data) {
            if(err && !data) {
                if(err.response.status === 404) {
                    this.error = true;
                    this.loaded = true;
                    return;
                }

                this.password = true;
                this.loaded = true;
                return;
            }
            else {
                this.error = false;
                this.password = false;
            }

            //Load fonts
            if(!this.$store.state.view.fontsLoaded) {
                checkFonts(data.fonts)
                //load legacy fonts
                let legacyFonts = data.fontNames.filter((fontName) => !data.fonts.map(x => x.name).includes(fontName))
                legacyFonts.forEach((name) =>{
                    GoogleFontsHelper.getFontURL(name);
                })
                this.$store.state.view.fontsLoaded = true;
            }

            this.password = false;

            let viewData = {
                portfolio:data.portfolio,
                project:data.project,
                user:data.user,
                portfolioPages:data.portfolioPages,
                activePage:data.project.shortId,
                footerProjects:data.footerProjects,
                caseStudies:data.caseStudies,
                hasDraftCasestudies:data.hasDraftCasestudies,
                loggedInUserId:data.loggedInUserId,
                emergencyModeSettings:data.emergencyModeSettings
            }

            this.$store.commit('updateView', viewData);

            const target = document.querySelector("body");

            if(data.pageType === 'project') {
                target.style.backgroundColor = data.project.designSettings.colorStyles.background.color;
            }
            else {
                target.style.backgroundColor = data.portfolio.designSettings.colorStyles.background.color;
            }

            if(!data.portfolio.templateSettings.theme.includes('profile_')) {
                try {
                    import(`../../../styles/_${data.portfolio.templateSettings.theme.replaceAll('_', '-').replace('profile_', '')}-base.scss`);
                }
                catch (e) {
                    console.log('failed to import scss')
                }
            }

            //TODO: figure this ourt
            this.$store.state.portfolio = data.portfolio
            this.$store.state.project = data.project

            this.loaded = true;
        },
        fetchUrl(to) {
            let url = '/data'
            let portfolioId = to.params.portfolioId;
            let shortId = to.params.shortId

            if(shortId) {
                url += '/shortid/' + shortId
            }
            else if(portfolioId) {
                url += "/portfolio/" + portfolioId
            }
            else {
                url += '/portfolio'
            }

            if(this.ownDomainPassword) {
                return '/data/ownDomain';
            }

            return url;
        }
    },
    computed: {
        currentComponent() {
            if (this.$store.state.view.portfolio && !this.password && (this.loaded || this.ownDomainLoaded)) {
                return 'PortfolioPage';
            } else if (this.password) {
                return 'PasswordProtection';
            } else if (this.error) {
                return 'ErrorPage';
            }
            return null;
        },
        currentProps() {
            if (this.password) {
                return {
                    setData: this.setData,
                    fetchUrl: this.fetchUrl,
                    type: 'portfolio'
                };
            } else if (this.error) {
                return {
                    error: {
                        code: 404,
                        title: 'This page doesn’t exist',
                        message: 'We can’t seem to find the page you are looking for'
                    }
                };
            }
            return {};
        }
    }
}
</script>