<template>
    <div class="comments-tab">
        <div class="page-group" v-bind:key="page.shortId" v-for="page in availablePages">
            <div @click="() => openPageGroup(page.shortId)" class="page-details">
                <div class="page-name">{{page.name}} ({{commentsLengthByPage(page.shortId).length}})</div>
                <Icon :icon-name="openedPage === page.shortId ? 'chevron-up' : 'chevron-down'" />
            </div>
            <div class="comments-container" v-if="openedPage === page.shortId">
                <CommentComponent
                    v-bind:key="comment"
                    v-for="comment in commentsLengthByPage(page.shortId)"
                    :comment="comment"
                    :edit="false"
                    @click="() => openComment(comment)"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from "../../../../common/Icon.vue"
    import CommentComponent from "../Comment.vue";

    export default {
        name: "CommentsComponent",
        components: {
            CommentComponent,
            Icon
        },
        data() {
            return {
                openedPage:'',
            }
        },
        methods: {
            openPageGroup(group) {
                if(this.openedPage === group) {
                    this.openedPage = '';
                }
                else {
                    this.openedPage = group;
                }
            },
            commentsLengthByPage(page) {
                let commentsWithPage = this.$store.state.reviewMode.review.sectionReactions.map(sectionReaction =>
                    sectionReaction.comments.map(comment => {
                        return {...comment, page: sectionReaction.pageData.shortId, sectionData:sectionReaction.sectionId}
                    })
                ).flat();

                return commentsWithPage.filter(comment => comment.page === page);
            },
            openComment(comment) {
                let timeout = 300;
                let currentPage = window.location.pathname;
                let isHomePage =  !window.location.pathname.includes('/p/');
                let portfolioId = this.$store.state.view.portfolio.shortId
                let url = this.receiverMode ? '/myreviews/' : '/review/';

                if(!currentPage.includes(comment.page)) {
                    if(comment.page === 'home') {
                        if(!isHomePage) {
                            //home page
                            timeout = 1000;
                            this.$router.push(url + portfolioId);
                        }
                    }
                    else {
                        timeout = 1500;
                        this.$router.push(url + 'p/' + portfolioId + '/' + comment.page);
                    }
                }

                setTimeout(() => {
                    const element = document.getElementById(comment.sectionData);
                    this.emitter.emit("open-comment", comment.sectionData);

                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                    }
                }, timeout)
            }
        },
        computed: {
            availablePages() {
                let uniquePageData = [];
                const uniqueIds = new Set();

                this.$store.state.reviewMode.review.sectionReactions.forEach(section => {
                    if (!uniqueIds.has(section.pageData.shortId) && section.comments.length) {
                        uniqueIds.add(section.pageData.shortId);
                        uniquePageData.push(section.pageData);
                    }
                });

                if(uniquePageData.length) {
                    this.openedPage = uniquePageData[0].shortId
                }
                return uniquePageData
            },
            receiverMode() {
                return this.$store.state.reviewMode.receiver;
            }
        }
    }
</script>

<style scoped lang="scss">
    .comments-tab {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        overscroll-behavior: contain;

        ::-webkit-scrollbar-track {
            background-color: transparent; /* Makes the track transparent */
        }

        scrollbar-color: #888 transparent; /* Thumb color and track color */

        .page-group {
            .page-details {
                display: flex;
                padding: 20px 24px;
                justify-content: space-between;
                border-bottom: 1px solid var(--asphalt80);

                .page-name {
                    color: var(--asphalt30);
                    font: var(--editor-ui-b2-regular);
                }

                svg {
                    width: 24px;
                    height: 24px;
                    color: var(--asphalt40);
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }
        .comments-container {
            border-bottom: 1px solid var(--asphalt80);
            .comment {
                &:hover {
                    cursor: pointer;
                    background-color: var(--asphalt90);
                }
            }
        }
    }
</style>