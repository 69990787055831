<template>
    <div class="tour-modal-wrapper" v-if="showTour">
        <div :class="['overlay', 'step-' + step]"></div>
        <div class="tour-container" :class="['step-' +step]">
            <div class="text-container">
                <div class="title-row">{{ steps[step].title }}</div>
                <div class="description-row">{{ steps[step].description }}</div>
            </div>
            <div class="pwt-actions">
                <div class="page-number">{{ step + 1 }} of 3</div>
                <div class="buttons">
                    <CtaButton v-if="step === 0" dark-scheme type="secondary" size="small" @click="closeTips()">Skip tour</CtaButton>
                    <CtaButton v-if="step > 0" dark-scheme type="secondary" size="small" @click="back()">Back</CtaButton>
                    <CtaButton dark-scheme @click="nextTip()" :size="'small'">{{ step === 2 ? 'Got it!' : 'Next' }}</CtaButton>
                </div>
            </div>
            <Icon class="review-arrow" :icon-name="'review-arrow'"/>
        </div>
        <div v-if="step === 1" class="example-review-fabs">
            <Fab :icon="'reaction'"></Fab>
            <Fab :icon="'comment'"></Fab>
        </div>
        <div v-if="step === 0" class="example-overview">
            <div>Overview</div>
        </div>
    </div>
</template>

<script>
import CtaButton from "../../../common/components/CtaButton.vue";
import Icon from "../../../common/Icon.vue";
import Fab from "../../../common/components/Fab.vue";

export default {
        name: "TourModal",
        components: {
            Fab,
            CtaButton,
            Icon
        },
        data() {
            return {
                show:false,
                step:0,
                steps:[
                    {title:'Give general feedback', description:'Write an overview of the whole portfolio and offer general feedback.'},
                    {title:'Add comments & reactions', description:'Give feedback to specific sections by adding comments.'},
                    {title:'Submit review', description:'Send your complete review to the portfolio’s owner.'}
                ]
            }
        },
        mounted() {
            let hasCookie = this.$cookies.get('reviewTour');
            if(!hasCookie) {
                this.show = true;
            }
        },
        methods: {
            closeTips() {
                this.$cookies.set('reviewTour', JSON.stringify({reviewTourDone:true}));
                this.show = false;
            },
            nextTip() {
                if(this.step < 2) {
                    this.step += 1;
                }
                else {
                    this.closeTips()
                }
            },
            back() {
                if(this.step > 0) {
                    this.step -= 1;
                }
            }
        },
        computed: {
            showTour() {
                return this.show && this.$store.state.reviewMode.displayUI
            }
        }
    }
</script>

<style scoped lang="scss">
    .tour-modal-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 104;
        width: 100%;
        height: 100%;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            background-color: rgba(18, 24, 35, 0.45);

            &.step-0 {

            }

            &.step-2 {
                top: 60px;
            }
        }
    }

    .example-review-fabs {
        position: absolute;
        right: 25px;
        top: 170px;
        display: flex;
        gap: 12px;
    }

    .example-overview {
        display: flex;
        flex-direction: row;
        padding: 20px 20px 20px 24px;
        justify-content: space-between;

        color: var(--asphalt10);
        font: var(--editor-ui-b1-semibold);

        position: fixed;
        bottom: 24px;
        left: 24px;
        z-index: 3;
        width: 420px;

        border-radius: 12px;
        border: 1px solid var(--asphalt90);
        background: var(--asphalt95);
        box-shadow: 0px 287px 80px 0px rgba(0, 0, 0, 0.00), 0px 183px 73px 0px rgba(0, 0, 0, 0.01), 0px 103px 62px 0px rgba(0, 0, 0, 0.05), 0px 46px 46px 0px rgba(0, 0, 0, 0.08), 0px 11px 25px 0px rgba(0, 0, 0, 0.09);

    }

    .tour-container {

        display: flex;
        width: 360px;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 24px;

        border-radius: 12px;
        background: var(--asphalt95);
        box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.40), 0 32px 40px 0 rgba(0, 0, 0, 0.32);
        
        position: fixed;
        z-index: 10;

        .review-arrow {
            position:absolute;
        }

        &.step-0 {
            bottom: 119px;
            left: 54px;

            .review-arrow {
                bottom: -18px;
                transform: rotate(90deg);
            }
        }

        &.step-1 {
            top: 105px;
            right: 153px;

            .review-arrow {
                top: 75px;
                right: -11px;
            }
        }

        &.step-2 {
            top: 85px;
            right: 38px;

            .review-arrow {
                top: -18px;
                right: 64px;
                transform: rotate(-90deg);
            }
        }

        .text-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 13px;
            text-align: center;

            .title-row {
                color: var(--asphalt10);
                font: var(--editor-ui-h3);
            }

            .description-row {
                color: var(--asphalt30);
                font: var(--editor-ui-b2-regular);
            }
        }

        .pwt-actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            width: 100%;
            .buttons {
                display: flex;
                gap: 8px;
            }

            .page-number {
                font: var(--editor-ui-b1-regular);
                color: var(--asphalt60)
            }

            .ufo-cta {
                &.small {
                    height: 30px;
                    border-radius: 999px;
                    padding: 0 1rem;
                }
            }
            .ufo-cta--link {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
</style>