<template>
    <div class="social-links">
        <a v-for="link in getLinks" :href="link.url" target="_blank" rel="nofollow noopener" :class="link.type.toLowerCase()">
            <i v-if="portfolioTemplate !== 'profile_theme_five'" :class="'fa fa-' + link.type.toLowerCase()"></i>
            <div v-else>{{link.type}}</div>
        </a>
    </div>
</template>

<script>
export default {
    name: "getSiteSocialLinks",
    props: [],
    computed: {
        portfolio() {
            return this.$store.state.view.portfolio
        },
        user() {
            return this.$store.state.view.user
        },
        socialLinks() {
            return this.user.socialLinks ? this.user.socialLinks : this.portfolio.socialLinks
        },
        getLinks() {
            return this.socialLinks.filter(link => link.url !== "" && link.type !== "")
        },
        portfolioTemplate() {
            return this.portfolio.templateSettings.theme.replace("_old", "")
        }
    }
}
</script>