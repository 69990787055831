<template>
    <div class="review-wrapper" v-if="loaded">
        <ReviewNav :reviewMode="false" />
        <slot></slot>
        <Overview :reviewMode="false" :is-empty="emptyState" />
    </div>
</template>

<script>
import ReviewNav from "./components/ReviewNavigation.vue";
import Overview from "./components/Overview.vue";
import UsersAPI from "../../api/users/UserAPI.js";
import ReviewAPI from "../../api/review/ReviewAPI.js";
import defaultLogoImage from "../../../images/ill-logo-volt-circle.png";

export default {
        name: "ReceiverMode",
        components: {
            ReviewNav,
            Overview
        },
        data() {
            return {
                reviews:null,
                emptyState:false,
                loaded:false
            }
        },
        mounted() {
            this.checkUser();
        },
        methods: {
            async checkUser() {
                try {
                    let response = await UsersAPI.getMyUser();
                    //There were an authenticated uxfolio user in the cookies.
                    this.$store.state.user = response.data.user;
                    this.$store.state.reviewMode.review.receiver = true;
                    await this.getReviews();
                }
                catch(e) {
                    window.location.replace(window.location.origin);
                    //TODO: we have to navigate them back to somewhere
                }
            },
            async getReviews() {
                try {
                    let response = await ReviewAPI.getReviewsByContentId(this.$store.state.portfolio._id);
                    this.reviews = response.data;
                    if(!response.data.length) {
                        this.emptyState = true;
                        this.setEmptyState();
                    }
                    else {
                        const reviewerId = this.$route.query.reviewer;
                        const defaultReview = response.data[0];
                        const selectedReview = response.data.find(review => review.reviewerDetails._id === reviewerId);

                        this.$store.state.reviewMode.review = selectedReview || defaultReview;
                        this.$store.state.reviewMode.reviews = response.data;
                    }
                    this.loaded = true;
                }
                catch(e) {
                    console.log('e', e)
                }
            },
            setEmptyState() {
                let exampleReview = {
                    followUps: [],
                    overview: {
                        positiveAspects: '<p>This is the place for what works in your portfolio: <ul><li>look & feel</li><li>information architecture</li><li>clarity of explanations</li></p>',
                        negativeAspects: '<p>This is the place for things that could be improved: <ul><li>look & feel</li><li>information architecture</li><li>clarity of explanations</li></p>',
                    },
                    reviewerDetails: {
                        name:'Example Reviewer',
                        jobTitle:'Portfolio Expert @UXfolio',
                        email:'example@uxfol.io',
                        image: {
                            url:defaultLogoImage
                        }
                    },
                    sectionReactions: [
                        {
                            sectionId:"portfolio-hero",
                            reaction:"&#128150",
                            comments:[
                                {
                                    text:"I love your font choice!",
                                    label:"Design",
                                    createdAt: Date.now()
                                }
                            ],
                            pageData:{
                                shortId:"home",
                                name:"Home"
                            }
                        }
                    ],
                };
                this.$store.state.reviewMode.review = exampleReview;
                this.$store.state.reviewMode.reviews = [exampleReview];
            }
        },
    }
</script>