function getPublicUrlWith(shortId, portfolioId, portfolioName, projectId, projectName) {
    let url = "/";
    if(shortId) {
        url += shortId;
    } else if(projectId) {
        url += ("project/" + (portfolioId ? portfolioId.slice(-8) : "") + projectId.slice(-8) + "/" + projectName.replace(/[^A-Za-z0-9]/g, '-'));
    } else {
        url += ("portfolio/" + portfolioId.slice(-8) + "/" + portfolioName.replace(/[^A-Za-z0-9]/g, '-'));
    }
    return url;
}

function getEditorUrl(portfolioId, projectShortId) {
    let url = "/";
    if(projectShortId) {
        url += ("p/" + projectShortId);
    } else {
        url += ("home/portfolios/" + portfolioId);
    }
    return url;
}

export default {
    getUrlForShortId(shortId) {
        return getPublicUrlWith(shortId, null, null, null, null);
    },

    getUrlForPortfolio(portfolioId, portfolioName) {
        return getPublicUrlWith(null, portfolioId, portfolioName, null, null);
    },

    getUrlForProject(projectId, projectName, portfolioId) {
        return getPublicUrlWith(null, portfolioId, null, projectId, projectName);
    },

    getUrlForProjectWithPortfolio(portfolioId, projectId, projectName) {
        return getPublicUrlWith(null, portfolioId, null, projectId, projectName);
    },

    getProjectEditorUrl(projectId) {
        return getEditorUrl(null, projectId);
    },

    getPortfolioEditorUrl(portfolioId) {
        return getEditorUrl(portfolioId, null);
    },

    getProjectReviewUrl(projectId, messageThreadId) {
        return this.getProjectEditorUrl(projectId) + "/reviews?review=" + messageThreadId;
    }
}
