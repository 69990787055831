<template>
    <RouterView v-if="portfolio && templateSettings" :portfolio="portfolio" :projects="projects" :user="user" :themeId="theme"/>
</template>

<script>
    import ApiHelper from "../../api/ApiHelper.js";
    import { getDefaultThemeStyles } from "../../common/templateDefaults/DefaultThemeStylesHelper.js";
    import { checkFonts } from "../../settingsEditor/_helpers/fontLoaders.js";

    export default {
        name: "TemplatePreview",
        data() {
            return {
                portfolio: {
                    templateSettings: null
                },
                fonts: [],
                portfolioPages: [],
                projects: {},
                user: {
                    name:'',
                    contactEmail:''
                }
            }
        },
        beforeMount() {
            this.getData();
        },
        watch: {
            theme() {
                this.getData()
            },
        },
        methods: {
            async getData() {
                try {
                    let res = await ApiHelper.get('/data/templates/' + this.theme);
                    let template = getDefaultThemeStyles('').find(template => template.title.toLowerCase() === this.theme);
                    this.portfolio = {
                        ...res.data.portfolio,
                        templateSettings: {
                            ...res.data.portfolio.templateSettings,
                            ctaStyle: template.settings.ctaStyle,
                            projectTitle: template.settings.projectTitle,
                            tileDisplayMode: template.settings.tileDisplayMode,
                            thumbnailLayout: template.settings.thumbnailLayout,
                            navigation: template ? template.settings.navigation : {}
                        },
                        designSettings: template.designSettings
                    }
                    this.fonts = res.data.fonts
                    this.portfolioPages = res.data.portfolioPages
                    this.projects = res.data.projects
                    this.user = res.data.user

                    //Set background on body
                    const target = document.querySelector("body");
                    target.style.backgroundColor = this.portfolio.designSettings.colorStyles.background.color;

                    //For global
                    let viewData = {
                        user: res.data.user,
                        portfolio: this.portfolio,
                        portfolioPages: this.portfolioPages
                    }
                    this.$store.commit('updateView', viewData);
                    this.$store.state.portfolio = this.portfolio

                    checkFonts(res.data.fonts)
                    if(!res.data.portfolio.templateSettings.theme.includes('profile_')) {
                        import(`../../../styles/_${res.data.portfolio.templateSettings.theme.replaceAll('_', '-')}-base.scss`);
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        },
        computed: {
            templateSettings() {
                return this.portfolio.templateSettings
            },
            theme() {
                return this.$route.params.templateId
            }
        }
    }
</script>

<style lang="scss">
    .template-preview-container{
        width: 1040px;
        height: 100%;
    }
</style>