<template>
    <div class="image">
        <img v-if="section && section.type === 'LogoWall' && columnMedia.url.startsWith('/example_project_images/logo-') && $store.state.darkMode"
             ref="img"
             @load="imageLoaded ? imageLoaded($refs.img) : null"
             loading="lazy"
             :src="columnMedia.url.replace('.png', '-white.png')"
             :data-zoomable="true"
             :alt="columnMedia.altTag ? columnMedia.altTag : 'Image alt tag'">

        <img v-else
             ref="img"
             @load="imageLoaded ? imageLoaded($refs.img) : null"
             loading="lazy"
             :src="columnMedia.croppedImageUrl ? columnMedia.croppedImageUrl : columnMedia.url ? columnMedia.url : '/example_project_images/' + placeholderImage"
             :data-zoomable="true"
             :alt="columnMedia.altTag ? columnMedia.altTag : 'Image alt tag'"
        >
    </div>
</template>

<script>
    import {hexToHSL} from "../../../../common/helpers.js";

    export default {
        name: "PlaceColumnMediaContent",
        props: ['section', 'columnMedia', 'placeholderImage'],
        mounted() {
            if(this.section && this.section.type === 'LogoWall' && !this.$store.state.darkMode) {
                this.$store.state.darkMode = hexToHSL(this.designSettings.colorStyles.background.color).l < 0.2;
            }
        },
        methods: {
            imageLoaded(img) {
                if (!!img && !!img.dataset.zoomable && img.naturalWidth > img.width) {
                    this.$store.state.zoom.attach(img);
                } else {
                    this.$store.state.zoom.detach(img);
                }
                this.$store.commit("recalculateScrollTriggers");
            }
        },
        computed: {
            designSettings() {
                return this.$store.getters.isCaseStudy && !this.$store.getters.usingGlobalColorStyles
                    ? this.$store.state.project.designSettings
                    : this.$store.state.portfolio.designSettings
            },
        }
    }
</script>