<template>
    <div class="follow-up-container">
        <div v-if="review.followUps.length" class="follow-ups">
            <CommentComponent
                v-bind:key="followup"
                v-for="followup in review.followUps"
                :comment="followup"
                :edit="false"
            />
        </div>
        <div v-else class="empty-state">
            <img rel="preload" alt="text-commnet" src="../../../../../images/illustrations/message.svg" />
            <div class="text-container">
                <div class="title-text">No follow-up messages yet</div>
                <div class="subtitle-text">Write a follow-up message to your reviewer and we will notify them about it</div>
            </div>
        </div>
        <div class="input-row" v-if="!isEmpty">
            <EditorContent :editor="editor"
                           :class="'ufo--text-area-comment'"
            />
            <Icon :class="{disabled:!this.text}" icon-name="send" @click="sendFollowup"></Icon>
        </div>
    </div>
</template>

<script>
    import Icon from "../../../../common/Icon.vue";
    import { Editor, EditorContent } from "@tiptap/vue-3";
    import StarterKit from "@tiptap/starter-kit";
    import Placeholder from "@tiptap/extension-placeholder";
    import ApiHelper from "../../../../api/ApiHelper.js";
    import CommentComponent from "../../components/Comment.vue";

    export default {
        name: "FollowUps",
        props:['isEmpty'],
        components: {
            CommentComponent,
            Icon,
            EditorContent
        },
        data() {
            return {
                text:'',
                editor:null,
                inputFocused:false
            }
        },
        mounted() {

            this.editor = new Editor({
                content: this.text,
                extensions: [
                    StarterKit,
                    Placeholder.configure({
                        placeholder: "Type your comment here",
                        showOnlyWhenEditable: true,
                        showOnlyCurrent: true
                    }),
                ],
                onUpdate: ({ getJSON, getHTML }) => {
                    this.applyTextEdit(this.editor.getText());
                },
                onBlur: () => {
                    this.inputFocused = false;
                },
                onFocus: () => {
                    this.inputFocused = true;
                }
            })
        },
        methods: {
            async sendFollowup() {
                this.$store.state.reviewMode.review.followUps.push({
                    createdAt:Date.now(),
                    fromUserId:this.loggedInUser._id,
                    text:this.text
                });

                let url = '/api/review/' + this.$store.state.reviewMode.review._id + "/" + this.$store.state.reviewMode.review.contentId;
                if(!this.receiverMode) {
                    url += "?followup=reviewer"
                }else {
                    url += "?followup=owner"
                }
                await ApiHelper.post(url, this.$store.state.reviewMode.review);
                this.text = '';
                this.editor.commands.clearContent();
            },
            applyTextEdit(newText) {
                this.text = newText;
            },
        },
        computed: {
            review() {
                return this.$store.state.reviewMode.review;
            },
            loggedInUser() {
                return this.$store.state.reviewMode.receiver ? this.$store.state.user : this.$store.state.reviewMode.review.reviewerDetails;
            },
            receiverMode() {
                return this.$store.state.reviewMode.receiver;
            },
        }
    }
</script>

<style scoped lang="scss">
    .follow-up-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        .follow-ups {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 0;
            align-self: stretch;
            overflow: auto;

            ::-webkit-scrollbar-track {
                background-color: transparent; /* Makes the track transparent */
            }

            scrollbar-color: #888 transparent; /* Thumb color and track color */
        }

        .empty-state {
            display: flex;
            padding: 80px 40px 40px 40px;
            flex-direction: column;
            align-items: center;
            gap: 24px;

            img {
                width: 150px;
                height: 150px;
            }

            .text-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 8px;

                .title-text {
                    color: var(--asphalt10);
                    font: var(--editor-ui-b1-semibold);
                }

                .subtitle-text {
                    text-align: center;
                    color: var(--asphalt30);
                    font: var(--editor-ui-b2-regular);
                }
            }
        }

        .input-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            padding: 24px;
            align-self: stretch;
            border-top: 1px solid var(--Asphalt-80, #333);
            max-height: 150px;
            overflow: auto;

            .ufo--text-area-comment {
                width: 100%;
                font:var(--editor-ui-b1-semibold);
                color:var(--asphalt10);
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
                flex: 1 0 0;
                align-self: stretch;
                overflow: auto;
            }

            svg {
                width: 24px;
                height: 24px;
                color: var(--asphalt50);
                align-self: self-end;

                &:not(.disabled):hover {
                    cursor: pointer;
                    color: var(--asphalt10);
                }
            }
        }
    }
</style>