<template>
    <main>
        <div v-if="emergencyModeSettings && emergencyModeSettings.emergencyMode && emergencyModeSettings.viewModeEmergencyBanner" class="alert-banner-container">
            <div class="banner-content-container">
                <div class="banner-text-container">
                    <div class="info-icon">
                        <Icon :icon-name="'info'" />
                    </div>
                    <div class="message">
                        {{ emergencyModeSettings.viewModeEmergencyBannerText }}
                    </div>
                </div>
            </div>
        </div>

        <!-- OLD LAYOUT -->
        <div :class="['ufo--portfolio-page',
                    'profile-page',
                    templateSettings.theme ? templateSettings.theme : 'profile_theme_default_old',
                    templateSettings.showProfilePicture ? 'profile--hp' : '']"
             v-if="!templateSettings.theme || templateSettings.theme.includes('profile_')"
             :style="[ getTextStyles ]"
        >
            <div class="grid ufo--page-hero" :style="[ sidebarBackgroundColor ]"
                @mouseenter="hover = -1" @mouseleave="hover = null"
            >
                <div :class="['ufo--page-hero-content', templateSettings.showProfilePicture && (portfolio.image.url || user.image.url && !portfolio.image.url) ? 'has-picture' : 'no-profile-picture', contentPosition]">
                    <PortfolioHeroHelper />
                </div>
            </div>
            <div class="ufo--page-content ufo--home-page-content">
                <GetHeroNavigation v-if=" !templateSettings.theme || templateSettings.theme && templateSettings.theme.includes('profile_theme_default')" :show-home-button="true" />
                <div class="ufo--page-sections">
                    <SectionLoader :sections="project.sections" :default-image-alt="''"/>
                </div>
            </div>
            <PublicPageFooter />
        </div>
        <!-- NEW LAYOUT -->
        <div v-else :class="['ufo--portfolio-page', templateSettings.theme]" :style="[ getTextStyles ]">
            <PortfolioHeroHelper />
            <div class="ufo--page-content ufo--home-page-content">
                <div :class="['ufo--page-sections', project.sections[0].type === 'ProjectGrid' ? 'pg-first' : '']">
                    <div class="projects-list project-wrapper">
                        <SectionLoader :sections="project.sections" :default-image-alt="''"/>
                    </div>
                </div>
            </div>
            <PublicPageFooter />
        </div>
    </main>
</template>

<script>
import {useHead} from '@unhead/vue'
import PortfolioHeroHelper from "../portfolio/themes/_helper/PortfolioHeroHelper.vue";
import PortfolioFooter from "../portfolio/common/PortfolioFooter.vue";
import SectionLoader from "../portfolio/sections/SectionLoader.vue";
import GetHeroNavigation from "../portfolio/common/hero/GetHeroNavigation.vue";
import PublicPageFooter from "./common/PublicPageFooter.vue";
import Icon from "../../common/Icon.vue";
import ReviewFabs from "../review/components/reviewer/ReviewFabs.vue";

let myPageHead = null;

    export default {
        name: "PortfolioPage",
        components: {
            ReviewFabs,
            GetHeroNavigation,
            SectionLoader,
            PortfolioHeroHelper,
            PublicPageFooter,
            Icon
        },
        data() {
            return {
                hover:null,
            }
        },
        mounted() {
            let head = {
                meta:[],
                script: []
            }

            if(document.title !==  this.portfolio.name) {
                head.title = this.portfolio.name;
            }

            if(this.portfolio.settings && this.portfolio.settings.searchEngineHidden) {
                head.meta.push({name:'robots', content:'noindex'})
            }
            myPageHead = useHead(head)
        },
        beforeUpdate() {
            myPageHead.patch({
                title: this.portfolio.name
            })
        },
        unmounted() {
            myPageHead.dispose()
        },
        computed: {
            portfolio() {
                return this.$store.state.view.portfolio;
            },
            user() {
                return this.$store.state.view.user;
            },
            project() {
                return this.$store.state.view.project;
            },
            templateSettings() {
                return this.portfolio.templateSettings;
            },
            portfolioPages() {
                return this.$store.state.view.portfolioPages;
            },
            emergencyModeSettings() {
                return this.$store.state.view.emergencyModeSettings;
            },
            getUrl() {
                return this.project.type === 'home' ?
                    "https://" + (this.portfolio.ownDomainAddress ? this.portfolio.ownDomainAddress : "uxfol.io/") + this.portfolio.shortId :
                    "https://" + (this.portfolio.ownDomainAddress ? this.portfolio.ownDomainAddress : "uxfol.io") + "/p/" + this.portfolio.shortId + "/" + this.project.shortId
            },
            getTextStyles() {
                return this.$store.getters.getAllTextStylesInCss;
            },
            sidebarBackgroundColor() {
                return {
                    '--sidebarBgColor': this.portfolio.templateSettings.hero && this.portfolio.templateSettings.hero.sidebarSettings
                        ? this.portfolio.templateSettings.hero.sidebarSettings.backgroundColor
                        : null
                }
            },
            footerDisabledInThemes() {
                return ['theme_four', 'profile_theme_four', 'theme_nine', 'profile_theme_nine', 'theme_default', 'profile_theme_default']
            },
            portfolioTemplate() {
                return this.portfolio.templateSettings.theme.replace("_old", "");
            },
            isFooterEnabled() {
                let avalibleInCaseStudy = this.project.type === 'caseStudy' && this.portfolio.settings.showFooterOnProjects && this.portfolio.portfolioFooter
                let avalibleInPublicPage = this.project.type !== 'caseStudy' && this.portfolio.settings.showFooterOnPortfolio && !this.footerDisabledInThemes.includes(this.portfolioTemplate) && this.portfolio.portfolioFooter;
                return avalibleInCaseStudy || avalibleInPublicPage
            },
            contentPosition() {
                return this.templateSettings.theme === "profile_theme_nine"
                    ? `align-content-to-${this.templateSettings.hero.alignment.vertical}`
                    : null
            }
        }
    }
</script>

<style lang="scss" global>
.alert-banner-container {
    min-height: 56px;
    width: 100%;
    background-color: var(--volt);
    display: flex;
    justify-content: center;

    @media (max-width: 769px) {
        min-height: 56px;
    }

    @media (max-width: 640px) {
        min-height: 70px;
    }

    .banner-content-container {
        @media (max-width: 769px) {
            padding: 0 62px;
        }

        @media (max-width: 640px) {
            padding: 0;
        }

        .banner-text-container {
            padding: 16px;
            display: flex;

            @media (max-width: 640px) {
                padding: 12px 6px;
            }

            .info-icon {
                @media (max-width: 640px) {
                    font-size: 12px;
                }

                svg {
                    height: 20px;
                    color: var(--asphalt100)
                }
            }

            .message {
                margin-left: 8px;
                color: var(--asphalt100);
                font-family: var(--primarySans);
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */
                letter-spacing: 0.16px;

                @media (max-width: 769px) {
                    font-size: 9px;
                }
            }
        }
    }
}
</style>