<template>
    <AnimationWrapper
        :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
        :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
    >
        <template #animationContent>
            <div v-if="section.media.frame && section.media.frame !== 'no-mockup'" class="media-box">
                <div v-if="section.media.frame === 'mobile' || section.media.frame === 'tablet' || section.media.frame === 'desktop'" :class="'mockup--' + section.media.frame">
                    <div class="mockup-display">
                        <div class="mockup-image">
                            <PlaceMediaContent :section="section" :default-caption="defaultCaption" :placeholder-image="placeholderImage" />
                        </div>
                    </div>
                    <div v-if="section.media.frame.includes('desktop')" class="desktop-body"></div>
                </div>
                <div v-else :class="'mockup--' + section.media.frame">
                    <div class="ufo-mockup--display">
                        <div class="ufo-mockup--screen">
                            <div class="ufo-mockup--scroller">
                                <PlaceMediaContent :section="section" :default-caption="defaultCaption" :placeholder-image="placeholderImage" />
                            </div>
                        </div>
                        <div class="ufo-mockup--frame">
                            <img :src="'/mockups/mockup--' + section.media.frame.replace('--no-shadow', '').replace('--shadow', '') + '.svg'">
                        </div>
                    </div>
                </div>
            </div>
            <div v-else :class="['media-box', section.media.type === 'Video' || section.media.type === 'Prototype' || section.media.type === 'Media' ? 'embed-content' : '']">
                <PlaceMediaContent :section="section" :default-caption="defaultCaption" :placeholder-image="placeholderImage" />
            </div>
        </template>
    </AnimationWrapper>
</template>

<script>
    import PlaceMediaContent from "./PlaceMediaContent.vue";
    import AnimationWrapper from "../../../../common/components/AnimationWrapper.vue";

    export default {
        name: "GetMediaContent",
        components: {AnimationWrapper, PlaceMediaContent},
        props: ['section', 'defaultCaption', 'placeholderImage']
    }

</script>