<template>
    <svg v-if="variant === 'linear_1'" :class="svgClass" width="368" height="368" preserveAspectRatio="none" viewBox="0 0 368 368" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="368" height="368" :fill="`url(#${uniqueId})`"/>
        <defs>
            <linearGradient :id="uniqueId" x1="184" y1="4.11272e-06" x2="199.861" y2="367.315" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EDF3FE" stop-opacity="0"/>
                <stop offset="1" stop-color="currentColor"/>
            </linearGradient>
        </defs>
    </svg>
    <svg v-else-if="variant === 'linear_2'" :class="svgClass" width="368" height="368" preserveAspectRatio="none" viewBox="0 0 368 368" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="368" height="368" :fill="`url(#${uniqueId})`"/>
        <defs>
            <linearGradient :id="uniqueId" x1="368" y1="0" x2="16.5458" y2="383.176" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EDF3FE" stop-opacity="0"/>
                <stop offset="1" stop-color="currentColor"/>
            </linearGradient>
        </defs>
    </svg>
    <svg v-else-if="variant === 'linear_3'" :class="svgClass" width="368" height="368" preserveAspectRatio="none" viewBox="0 0 368 368" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="368" height="368" :fill="`url(#${uniqueId})`"/>
        <defs>
            <linearGradient :id="uniqueId" x1="0" y1="0" x2="383.176" y2="351.454" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EDF3FE" stop-opacity="0"/>
                <stop offset="1" stop-color="currentColor"/>
            </linearGradient>
        </defs>
    </svg>
    <svg v-else :class="svgClass" width="368" height="368" viewBox="0 0 368 368" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g :filter="`url(#${uniqueId})`">
            <circle cx="184" cy="184" r="85" fill="currentColor"/>
        </g>
        <defs>
            <filter :id="uniqueId" x="-31" y="-31" width="430" height="430" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="65"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1660_164104"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1660_164104" result="shape"/>
                <feGaussianBlur stdDeviation="45" result="effect2_foregroundBlur_1660_164104"/>
            </filter>
        </defs>
    </svg>
</template>

<script>
export default {
    props: {
        variant: {
            type: String,
            default: 'radial_1'
        },
        svgClass: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            uniqueId: ''
        };
    },
    created() {
        this.uniqueId = `paint0_linear_${this.generateUniqueId()}`;
    },
    methods: {
        generateUniqueId() {
            // Generate a unique string identifier
            return Math.random().toString(36).substring(2, 9);
        }
    }
};
</script>