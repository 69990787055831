<template>
    <div :id="'section-' + section.index"
         :class="['ufo-sec', 'ufo-sec--media', 'image', section.media.frame && section.media.frame !== 'no-mockup' ? 'mockup-on' : '']">
        <ToggleGrid />
        <div class="grid">
            <div :class="['media-container',
                          'display-mode--' + (section.media.displayMode ? section.media.displayMode : 'default'),
                          (section.media.type === 'Video' || section.media.type === 'Prototype' || section.media.type === 'Media') ? 'embed-container' : '']"
                 :style="{'padding-top': section.style && section.style.paddingTop ? 0 : null,
                        'padding-bottom': section.style && section.style.paddingBottom ?  0 : null
            }">
                <GetMediaContent :section="section" :default-caption="projectTitle" :placeholder-image="'empty-big-image.jpg'" />
                <GetMediaCaption :caption="section.media.caption"/>
            </div>
        </div>
    </div>
</template>

<script>
    import GetMediaContent from "./_helper/GetMediaContent.vue";
    import GetMediaCaption from "./_helper/GetMediaCaption.vue";
    import ToggleGrid from "../../../common/components/ToggleGrid.vue";

    export default {
        name: "MediaSection",
        components: { GetMediaCaption, GetMediaContent, ToggleGrid },
        props:['section', 'projectTitle']
    }
</script>