<template>
    <div class="receiver-overview-container">
        <div class="review-selector" v-if="receiverMode">
            <UserSelectDropdown
                v-if="selectedReviewer"
                :selected="selectedReviewer"
                :options="reviews.map(x => x.reviewerDetails)"
                @click="updateSelectedReview($event)"
                dark-scheme
            />
        </div>
        <TabBar dark-scheme class="tab-bar">
            <TabItem
                v-for="tab in overViewTabs"
                :key="tab.key"
                :label="tab.name"
                :active="tab.key === activeTab"
                @click="changeTab(tab.key)"
                dark-scheme

            />
        </TabBar>
        <div  v-if="activeTab === 'overview'" class="overview-tab">
            <OverviewField :is-input="false" :fieldToUpdate="'positiveAspects'" :title="'Positive aspects'" icon="positive-aspect" />
            <OverviewField :is-input="false" :fieldToUpdate="'negativeAspects'" :title="'Areas of improvement'" icon="areas-of-improvement" />
        </div>
        <CommentsComponent v-if="activeTab === 'comments'" />
        <FollowUps :isEmpty="this.isEmpty" v-if="activeTab === 'followup'" />

        <div v-if="isEmpty && activeTab !== 'followup'" class="empty-state-overlay">
            <div class="text-wrapper">
                <div class="title-text">Wait! This is just an example review</div>
                <div class="subtitle-text">Get real reviews by sharing your review link with mentors and fellow designers.</div>
            </div>
            <CtaButton :size="'small'" dark-scheme :type="'primary'" @click="copyLink">
                <div class="text-with-icon">
                    <Icon :icon-name="'link'" />
                    {{ copied ? 'Copied!' : 'Copy review link' }}
                </div>
            </CtaButton>
        </div>
    </div>
</template>

<script>
import CtaButton from "../../../../common/components/CtaButton.vue";
import TabBar from "../../../../common/components/TabBar.vue";
import TabItem from "../../../../common/components/TabItem.vue";
import FollowUps from "./OverviewFollowUps.vue";
import OverviewField from "../OverviewField.vue";
import CommentsComponent from "./OverviewComments.vue";
import UserSelectDropdown from "../../../../common/components/UserSelectDropdown.vue";
import Icon from "../../../../common/Icon.vue";
import ApiHelper from "../../../../api/ApiHelper.js";

export default {
        name: "ReceiverOverview",
        props:['isEmpty'],
        components: {
            CommentsComponent,
            FollowUps,
            TabItem,
            TabBar,
            CtaButton,
            OverviewField,
            UserSelectDropdown,
            Icon
        },
        data() {
            return {
                overViewTabs:[
                    {name:'Overview', key:'overview'},
                    {name:'Comments', key:'comments'},
                    {name:'Follow-up', key:'followup'}
                ],
                activeTab:'overview',
                selectedReviewer:null,
                copied:false
            }
        },
        mounted() {
            this.selectedReviewer = this.reviewer;
            this.setRead();
            if(this.$route.query.followup) {
                this.activeTab = 'followup'
            }
        },
        methods: {
            async copyLink(){
                try {
                    let reviewUrl = window.location.origin + "/review/" + this.$store.state.portfolio.shortId;
                    await navigator.clipboard.writeText(reviewUrl);
                    this.copied = true;
                    setTimeout(() => {
                        this.copied = false;
                    }, 2000)
                } catch($e) {
                    //TODO: failure
                }
            },
            changeTab(tab) {
                this.activeTab = tab
            },
            updateSelectedReview(reviewer) {
                this.selectedReviewer = reviewer;
                this.$store.state.reviewMode.review = this.$store.state.reviewMode.reviews.find(review => review.reviewer === reviewer.email);
                this.setRead();
            },
            async setRead() {
                if(!this.$store.state.reviewMode.review.read) {
                    this.$store.state.reviewMode.review.read = true;
                    if(this.$store.state.reviewMode.review._id && this.$store.state.reviewMode.review.contentId) {
                        let url = '/api/review/' + this.$store.state.reviewMode.review._id + "/" + this.$store.state.reviewMode.review.contentId;
                        try {
                            await ApiHelper.post(url, this.$store.state.reviewMode.review);
                        }
                        catch (e) {
                            console.log('error while setting read', e)
                        }
                    }
                }
            }
        },
        computed: {
            reviews() {
                return this.$store.state.reviewMode.reviews
            },
            reviewer() {
                return this.$store.state.reviewMode.review.reviewerDetails
            },
            //This is only here. We use the receiver mode for the submitted mode as well, but have to disable the picker for the submitted mode
            receiverMode() {
                return this.$store.state.reviewMode.receiver;
            }
        }
    }
</script>

<style scoped lang="scss">
    .receiver-overview-container {
        height: 100%;
        display: flex;
        flex-direction: column;

        .review-selector {
            padding: 24px;

            .user-option {
                display: flex;
                justify-content: space-between;
                align-items: center;
                svg {
                    color: var(--asphalt10);
                }
                .user-value {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    img {
                        width: 36px;
                        height: 36px;
                        border-radius: 36px;
                    }

                    .placeholder-image {
                        background-color: var(--asphalt30);
                        text-align: center;
                        width: 44px;
                        height: 44px;
                        border-radius: 36px;

                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        p {
                            font: var(--editor-ui-b2-semibold);
                            color: var(--asphalt70);
                            margin: 0;
                        }
                    }

                    .user-details {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 2px;

                        .user-name {
                            font: var(--editor-ui-b2-medium);
                            color: var(--asphalt20);
                        }

                        .user-job-title {
                            font: var(--editor-ui-b3-medium);
                            color: var(--asphalt40);
                        }
                    }
                }
            }
        }

        .tab-bar {
            padding: 0 24px;
        }

        .overview-tab {
            display: flex;
            padding: 24px;
            flex-direction: column;
            align-items: flex-start;
            gap: 40px;
            align-self: stretch;
            overflow-x: hidden;
            overflow-y: auto;
            overscroll-behavior: contain;
            overflow-wrap: anywhere;

            ::-webkit-scrollbar-track {
                background-color: transparent; /* Makes the track transparent */
            }

            scrollbar-color: #888 transparent; /* Thumb color and track color */

            .overviews {
                padding: 24px;
                flex-direction: column;
                align-items: flex-start;
                gap: 40px;
            }
        }

        .empty-state-overlay {
            display: flex;
            height: 360px;
            padding: 60px 24px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 24px;
            align-self: stretch;
            border-radius: 12px;
            background: linear-gradient(180deg, rgba(25, 25, 25, 0.00) 0%, #191919 12%);
            position: absolute;
            bottom: 0;

            :deep(button) {
                min-width: 160px;

                .button-icon {
                    display: unset;
                }
            }

            .text-with-icon {
                display: flex;
                flex-direction: row;
                gap: 6px;
                align-items: center;

                svg {
                    width: 24px;
                    height: 24px;
                }
            }

            .text-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 12px;

                .title-text {
                    font:var(--editor-ui-h3);
                    color: var(--asphalt10);
                }

                .subtitle-text {
                    text-align: center;
                    font:var(--editor-ui-b2-regular);
                    color: var(--asphalt30);
                }
            }
        }

    }
</style>