export const SpaceGroteskAndWorkSans = Object.freeze({
    ID: 1,
    FONTS: {
        HEADING: "Space Grotesk",
        PARAGRAPH_AND_NAV:"Work Sans",
    },
    WEIGHTS: {
        HEADING: 500,
        PARAGRAPH: 400,
        NAVIGATION: 400,
        BUTTON: 600
    }
})

export const GambettaAndInter = Object.freeze({
    ID: 2,
    FONTS: {
        HEADING: "Gambetta",
        PARAGRAPH_AND_NAV: "Inter",
    },
    WEIGHTS: {
        HEADING: 400,
        PARAGRAPH: 400,
        NAVIGATION: 400,
        BUTTON: 600
    }
})

export const AlberSansOnly = Object.freeze({
    ID: 3,
    FONTS: {
        HEADING: "Albert Sans",
        PARAGRAPH_AND_NAV: "Albert Sans",
    },
    WEIGHTS: {
        HEADING: 400,
        PARAGRAPH: 400,
        NAVIGATION: 400,
        BUTTON: 600
    }
})

export const InterOnly = Object.freeze({
    ID: 4,
    FONTS: {
        HEADING: "Inter",
        PARAGRAPH_AND_NAV: "Inter",
    },
    WEIGHTS: {
        HEADING: 700,
        PARAGRAPH: 400,
        NAVIGATION: 400,
        BUTTON: 600
    }
})

export const OutfitAndPoppins = Object.freeze({
    ID: 5,
    FONTS: {
        HEADING: "Outfit",
        PARAGRAPH_AND_NAV: "Poppins",
    },
    WEIGHTS: {
        HEADING: 400,
        PARAGRAPH: 400,
        NAVIGATION: 400,
        BUTTON: 600
    }
})

export const StixTwoTextAndSwitzer = Object.freeze({
    ID: 6,
    FONTS: {
        HEADING: "STIX Two Text",
        PARAGRAPH_AND_NAV: "Switzer",
    },
    WEIGHTS: {
        HEADING: 500,
        PARAGRAPH: 400,
        NAVIGATION: 400,
        BUTTON: 600
    }
})

export const SoraAndWorkSans = Object.freeze({
    ID: 7,
    FONTS: {
        HEADING: "Sora",
        PARAGRAPH_AND_NAV: "Work Sans",
    },
    WEIGHTS: {
        HEADING: 300,
        PARAGRAPH: 400,
        NAVIGATION: 400,
        BUTTON: 600
    }
})

export const SatoshiOnly = Object.freeze({
    ID: 8,
    FONTS: {
        HEADING: "Satoshi",
        PARAGRAPH_AND_NAV: "Satoshi",
    },
    WEIGHTS: {
        HEADING: 500,
        PARAGRAPH: 400,
        NAVIGATION: 400,
        BUTTON: 600
    }
})

export const DMSansOnly = Object.freeze({
    ID: 9,
    FONTS: {
        HEADING: "DM Sans",
        PARAGRAPH_AND_NAV: "DM Sans",
    },
    WEIGHTS: {
        HEADING: 400,
        PARAGRAPH: 400,
        NAVIGATION: 400,
        BUTTON: 600
    }
})

export const GeneralSansAndGambetta = Object.freeze({
    ID: 10,
    FONTS: {
        HEADING: "General Sans",
        PARAGRAPH_AND_NAV: "Gambetta",
    },
    WEIGHTS: {
        HEADING: 600,
        PARAGRAPH: 400,
        NAVIGATION: 400,
        BUTTON: 600
    }
})

export const RoundoAndKarla = Object.freeze({
    ID: 11,
    FONTS: {
        HEADING: "Roundo",
        PARAGRAPH_AND_NAV: "Karla",
    },
    WEIGHTS: {
        HEADING: 500,
        PARAGRAPH: 400,
        NAVIGATION: 400,
        BUTTON: 600
    }
})

export const CaprasimoAndDmMono = Object.freeze({
    ID: 12,
    FONTS: {
        HEADING: "Caprasimo",
        PARAGRAPH_AND_NAV: "DM Mono",
    },
    WEIGHTS: {
        HEADING: 400,
        PARAGRAPH: 400,
        NAVIGATION: 400,
        BUTTON: 600
    }
})

export const ClashDisplayAndInter = Object.freeze({
    ID: 13,
    FONTS: {
        HEADING: "ClashDisplay",
        PARAGRAPH_AND_NAV: "Inter",
    },
    WEIGHTS: {
        HEADING: 600,
        PARAGRAPH: 400,
        NAVIGATION: 400,
        BUTTON: 600
    }
})

export const BricolageGroteskAndKarla = Object.freeze({
    ID: 14,
    FONTS: {
        HEADING: "Bricolage Grotesque",
        PARAGRAPH_AND_NAV: "Karla",
    },
    WEIGHTS: {
        HEADING: 700,
        PARAGRAPH: 400,
        NAVIGATION: 400,
        BUTTON: 600
    }
})

export const SpaceMonoAndSpaceGrotesk = Object.freeze({
    ID: 16,
    FONTS: {
        HEADING: "Space Mono",
        PARAGRAPH_AND_NAV: "Space Grotesk",
    },
    WEIGHTS: {
        HEADING: 400,
        PARAGRAPH: 400,
        NAVIGATION: 400,
        BUTTON: 600
    }
})

export const IbmPlexMonoAndIbmPlexSans = Object.freeze({
    ID: 17,
    FONTS: {
        HEADING: "IBM Plex Mono",
        PARAGRAPH_AND_NAV: "IBM Plex Sans",
    },
    WEIGHTS: {
        HEADING: 600,
        PARAGRAPH: 400,
        NAVIGATION: 400,
        BUTTON: 600
    }
})

export const MerchantAndOpenSans = Object.freeze({
    ID: 18,
    FONTS: {
        HEADING: "Merchant",
        PARAGRAPH_AND_NAV: "Open Sans",
    },
    WEIGHTS: {
        HEADING: 400,
        PARAGRAPH: 400,
        NAVIGATION: 600,
        BUTTON: 600
    }
})

export function getAllFontPresets() {
    return [
        SpaceGroteskAndWorkSans,
        GambettaAndInter,
        AlberSansOnly,
        InterOnly,
        OutfitAndPoppins,
        StixTwoTextAndSwitzer,
        SoraAndWorkSans,
        SatoshiOnly,
        DMSansOnly,
        GeneralSansAndGambetta,
        RoundoAndKarla,
        CaprasimoAndDmMono,
        ClashDisplayAndInter,
        BricolageGroteskAndKarla,
        SpaceMonoAndSpaceGrotesk,
        IbmPlexMonoAndIbmPlexSans,
        MerchantAndOpenSans
    ]
}

